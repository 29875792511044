import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface GetFieldListStatusPayload {
  User?: string | null;
  portfolioId: number
}

export interface Program {
  companyId: number;
  portfolioId: number;
  programId: number;
  name: string;
}

export interface ProjectType {
  projectTypeId: number;
  projectType: string;
}

export interface Manager {
  companyId: number;
  managerId: number;
  name: string;
}

export interface Owner {
  companyId: number;
  ownerId: number;
  name: string;
}

export interface getFieldListStatusResponse {
  programList: Program[];
  projectTypeList: ProjectType[];
  managerList: Manager[];
  ownerList: Owner[];
}

export function getFieldListStatus(params: GetFieldListStatusPayload): Promise<AxiosResponse<getFieldListStatusResponse>> {
  return btoApi
    .get("/api/Project/GetFieldList", { params });
}