import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { ModalError, ModalSuccess, SpinLoading, Table, Typography } from 'shared/components';
import { useTheme } from 'styled-components';
import { getRitualFinancialDraft, GetRitualFinancialResponse, updateDraftFinancial, UpdateDraftFinancialPayload } from 'bto_now/api';
import { getUser } from 'services';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonRef } from 'shared/components/Button'
import { currency, currencyMask, currencyToFloat } from 'utils/masks/currency';
import { buildNavigateString, date, getUrlSearchParams, RitualManagementStatusRitual } from 'utils';
import { useFineshedTimeline } from 'shared/store-zustand/old-timeline/finishedTimeline';

interface Props {
  saveButtonRef: React.RefObject<ButtonRef>
}

function RitualUpdateUpdate({
  saveButtonRef
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId, ritualNumber } = useParams();
  const { getFinancialData } = useFineshedTimeline(); 

  const statusRitual = getUrlSearchParams('statusRitual') as RitualManagementStatusRitual;
  const isNotDraft = statusRitual === 'Active' || statusRitual === 'History';

  const [data, setData] = useState<GetRitualFinancialResponse>();
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState('');

  useEffect(() => {
    getRitualFinancialDraft({ User: getUser(), ProjectId: projectId as string, RitualNumber: Number(ritualNumber) })
      .then(({ data }) => {
        setData(data);
      });
  }, [])

  function calcAccumulatedInvestments(currentIndex: number) {
    const slice = data?.phaseFinancialUpdate.slice(0, currentIndex + 1);
    return slice?.map(v => Number(v.totalInvestments)).reduce((a, c) => a + c, 0);
  }

  function caclTotalInvestments(currentIndex: number): string {
    if (data?.phaseFinancialUpdate && Array.isArray(data?.phaseFinancialUpdate)) {
      const newArray = [...data?.phaseFinancialUpdate];
      const totalCapex = data?.phaseFinancialUpdate[currentIndex].totalCapex;
      const totalOpex = data?.phaseFinancialUpdate[currentIndex].totalOpex;
      const totalInvestments = Number(totalCapex || 0) + Number(totalOpex || 0);
      newArray[currentIndex].totalInvestments = totalInvestments;
      if (totalInvestments !== data?.phaseFinancialUpdate[currentIndex].totalInvestments) {
        setData({ ...data, phaseFinancialUpdate: newArray });
      }
      return currency(totalInvestments);
    }
    return currency('0');
  }

  function calcConsumedBudget(currentIndex: number) {
    const line = data?.phaseFinancialUpdate[currentIndex];
    const totalInvestment = line?.totalCapex! + line?.totalOpex!;
    if (totalInvestment && line?.forecast) return (totalInvestment / line?.forecast) * 100;
    else return 0;
  }

  function updateCapex(value: string, index: number) {
    if (data?.phaseFinancialUpdate && Array.isArray(data?.phaseFinancialUpdate)) {
      const newArray = [...data?.phaseFinancialUpdate];
      newArray[index].totalCapex = currencyToFloat(currencyMask(value));
      setData({ ...data, phaseFinancialUpdate: newArray });
    }
  }

  function updateOpex(value: string, index: number) {
    if (data?.phaseFinancialUpdate && Array.isArray(data?.phaseFinancialUpdate)) {
      const newArray = [...data?.phaseFinancialUpdate];
      newArray[index].totalOpex = currencyToFloat(currencyMask(value));
      setData({ ...data, phaseFinancialUpdate: newArray });
    }
  }

  function totalForecastBudget(): number {
    if (data?.phaseFinancialUpdate && Array.isArray(data?.phaseFinancialUpdate)) {
      const forecast = data?.phaseFinancialUpdate.map(v => v.forecast).reduce((c, a) => c + a, 0);
      return forecast;
    }
    return 0;
  }

  function totalCapexActual(): number {
    if (data?.phaseFinancialUpdate && Array.isArray(data?.phaseFinancialUpdate)) {
      const capex = data?.phaseFinancialUpdate.map(v => v.totalCapex).reduce((c, a) => c + a, 0);
      return capex;
    }
    return 0;
  }

  function totalOpexActual(): number {
    if (data?.phaseFinancialUpdate && Array.isArray(data?.phaseFinancialUpdate)) {
      const opex = data?.phaseFinancialUpdate.map(v => v.totalOpex).reduce((c, a) => c + a, 0);
      return opex;
    }
    return 0;
  }

  function totalInvestmentsActual(): number {
    if (data?.phaseFinancialUpdate && Array.isArray(data?.phaseFinancialUpdate)) {
      const investments = data?.phaseFinancialUpdate.map(v => v.totalInvestments).reduce((c, a) => c + a, 0);
      return investments;
    }
    return 0;
  }

  function calcTotalConsumedBudget() {
    return (totalInvestmentsActual() / totalForecastBudget()) * 100
  }

  function preparePhasesData() {
    return data?.phaseFinancialUpdate.map(phase => ({
      phaseExecutionId: phase.phaseExecutionId,
      totalCapex: phase.totalCapex,
      totalOpex: phase.totalOpex,
      totalInvestments: phase.totalInvestments,
      forecast: phase.forecast,
    }));
  }
  
  function saveButton() {
    saveButtonRef.current?.setIsLoading(true);
  
    const phasesData = preparePhasesData();
  
    const payload: UpdateDraftFinancialPayload = phasesData?.map(phase => ({
      user: getUser() || '',
      projectId: projectId as string,
      financialType: 'R',
      phaseExecutionId: phase.phaseExecutionId,
      totalCapex: phase.totalCapex,
      totalOpex: phase.totalOpex,
    })) ?? [];
  
    updateDraftFinancial(payload)
      .then(({ data }) => {
        if (data.sucesso) setSuccessModal(true);
        else setErrorModal(data.mensagemErro);
      })
      .catch(() => { })
      .finally(() => saveButtonRef.current?.setIsLoading(false));
  }
  

  // function saveButton() {
  //   saveButtonRef.current?.setIsLoading(true);
  //   const payload: UpdateDraftFinancialPayload = [
  //     {
  //       user: getUser() || '',
  //       projectId: projectId as string,
  //       financialType: 'R',
  //       phaseExecutionId: data?.phaseFinancialUpdate[0].phaseExecutionId || 0,
  //       totalCapex: totalCapexActual(),
  //       totalOpex: totalOpexActual(),
  //     }
  //   ]
  //   updateDraftFinancial(payload)
  //     .then(({ data }) => {
  //       if (data.sucesso) setSuccessModal(true);
  //       else setErrorModal(data.mensagemErro);
  //     })
  //     .catch(() => { })
  //     .finally(() => saveButtonRef.current?.setIsLoading(false));
  // }

  useEffect(() => {
    saveButtonRef.current?.addEventListener("click", saveButton);
    if (data) getFinancialData(data);

    return () => {
      saveButtonRef.current?.removeEventListener("click", saveButton);
    }
  }, [saveButtonRef, data])

  function handleOnOkSuccessModal() {
    const ritualName = getUrlSearchParams('ritualName');
    const ritualNumber = getUrlSearchParams('ritualNumber');
    const url = buildNavigateString({
      path: document.location.pathname.replace('financial', 'risks'),
      params: { ritualName, ritualNumber }
    });

    navigate(url);
    setSuccessModal(false);
  }

  return (
    <S.Container>
      <ModalSuccess autoClose={3000} title='Success!' description='Data updated successfully' onOk={handleOnOkSuccessModal} open={successModal} />
      <ModalError autoClose={3000} title='Error!' description={errorModal} onOk={() => setErrorModal('')} open={errorModal.length > 0} />

      <Typography weight='bold' size='medium'>Budget Update</Typography>

      {!data ? (
        <SpinLoading />
      ) : (
        <S.ScrollX>
          <Table style={{ marginTop: theme.spacing.sm, width: '100%' }}>
            <Table.Tr header>
              <Table.Td>Phase</Table.Td>
              <Table.Td>Start Date Real</Table.Td>
              <Table.Td>End Date Real</Table.Td>
              <Table.Td>Forecast Budget</Table.Td>
              <Table.Td>Total Capex Actual</Table.Td>
              <Table.Td>Total Opex Actual</Table.Td>
              <Table.Td>Total Investments Actual</Table.Td>
              <Table.Td>Accumulated Investments</Table.Td>
              <Table.Td>Consumed Budget (%)</Table.Td>
            </Table.Tr>
            {
              data.phaseFinancialUpdate.map((m, index) => (
                <Table.Tr key={index}>
                  <Table.Td>{m.phase}</Table.Td>
                  <Table.Td>{date(m.startDate)}</Table.Td>
                  <Table.Td>{date(m.endDate)}</Table.Td>
                  <Table.Td>{currency(m.forecast)}</Table.Td>
                  <Table.Td><S.TdInput disabled={isNotDraft} value={currencyMask(m.totalCapex)} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateCapex(ev.target.value, index)} /></Table.Td>
                  <Table.Td><S.TdInput disabled={isNotDraft} value={currencyMask(m.totalOpex)} onChange={(ev: React.ChangeEvent<HTMLInputElement>) => updateOpex(ev.target.value, index)} /></Table.Td>
                  <Table.Td>{caclTotalInvestments(index)}</Table.Td>
                  <Table.Td>{currency(calcAccumulatedInvestments(index))}</Table.Td>
                  <Table.Td><Table.ProgressBar disabled value={calcConsumedBudget(index).toFixed(2)} progress={calcConsumedBudget(index)} /></Table.Td>
                </Table.Tr>
              ))
            }
            <Table.Tr style={{ height: theme.spacing.xs }}>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
              <Table.Td style={{ border: 'none', background: 'white', height: 8 }}></Table.Td>
            </Table.Tr>
            <Table.Tr header>
              <Table.Td>Project</Table.Td>
              <Table.Td>Start Date Real</Table.Td>
              <Table.Td>End Date Real</Table.Td>
              <Table.Td>Forecast Budget</Table.Td>
              <Table.Td>Total Capex Actual</Table.Td>
              <Table.Td>Total Opex Actual</Table.Td>
              <Table.Td>Total Investments Actual</Table.Td>
              <Table.Td>Available Investments</Table.Td>
              <Table.Td>Consumed Budget (%)</Table.Td>
            </Table.Tr>
            {
              data.projectFinancialUpdate.map((m, index) => (
                <Table.Tr key={index}>
                  <Table.Td>{m.phase}</Table.Td>
                  <Table.Td>{date(m.startDate)}</Table.Td>
                  <Table.Td>{date(m.endDate)}</Table.Td>
                  <Table.Td>{currency(totalForecastBudget())}</Table.Td>
                  <Table.Td>{currency(totalCapexActual())}</Table.Td>
                  <Table.Td>{currency(totalOpexActual())}</Table.Td>
                  <Table.Td>{currency(totalInvestmentsActual())}</Table.Td>
                  <Table.Td>{currency(totalForecastBudget() - totalInvestmentsActual())}</Table.Td>
                  <Table.Td><Table.ProgressBar disabled value={calcTotalConsumedBudget().toFixed(2)} progress={calcTotalConsumedBudget()} /></Table.Td>
                </Table.Tr>
              ))
            }
          </Table>

        </S.ScrollX>
      )}
    </S.Container>
  )
}

export default RitualUpdateUpdate;