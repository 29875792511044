import { useTranslation } from 'react-i18next';

import { Table } from "shared/components";

import * as S from "./styles";
import { useEffect, useState } from 'react';

interface TableDifferenceCostProps {
  xAxis: string[];
  dataActualState: number[]
  dataFutureState: number[];
}

const TableDifferenceCost = ({ xAxis, dataActualState, dataFutureState }: TableDifferenceCostProps) => {
  const { t } = useTranslation();

  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 850);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 850);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const totalDifferences = dataFutureState.map((fsValue, index) => fsValue - dataActualState[index]);
  const percentDifferences = dataFutureState.map((fsValue, index) => {
    const asValue = dataActualState[index];
    return asValue === 0 ? 0 : ((fsValue - asValue) / asValue) * 100;
  });

  return (
    <S.Container>
      {!isMobileView ? (
        <Table>
          <Table.Thead>
            <Table.Tr header>
              <Table.Td style={{ padding: '4px 12px' }}>{t('Cost Element')}</Table.Td>
              {xAxis.map((year) => (
                <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }} key={year}>{year}</Table.Td>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td style={{ padding: '4px 12px', fontWeight: '700', color: '#344050' }}>{t('Total Difference')}</Table.Td>
              {totalDifferences.map((diff, index) => (
                <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }} key={index}>R$ {diff.toLocaleString('pt-BR', { currency: 'BRL' })}</Table.Td>
              ))}
            </Table.Tr>
            <Table.Tr>
              <Table.Td style={{ padding: '4px 12px', fontWeight: '700', color: '#344050' }}>{t('% Difference')}</Table.Td>
              {percentDifferences.map((diff, index) => (
                <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }} key={index}>{diff.toFixed(2)}%</Table.Td>
              ))}
            </Table.Tr>
          </Table.Tbody>
        </Table>
      ) : (
        <Table>
          <Table.Thead>
            <Table.Tr header>
              <Table.Td style={{ padding: '4px 12px', verticalAlign: 'middle' }}>{t('Years')}</Table.Td>
              <Table.Td style={{ padding: '4px 12px', textAlign: 'center', fontWeight: '700' }}>{t('Total Difference')}</Table.Td>
              <Table.Td style={{ padding: '4px 12px', textAlign: 'center', fontWeight: '700' }}>{t('% Difference')}</Table.Td>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {xAxis.map((year, index) => (
              <Table.Tr key={year}>
                <Table.Td style={{ padding: '4px 12px', verticalAlign: 'middle' }}>
                  {year}
                </Table.Td>
                <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>
                  R$ {totalDifferences[index].toLocaleString('pt-BR', { currency: 'BRL' })}
                </Table.Td>
                <Table.Td style={{ padding: '4px 12px', textAlign: 'center', verticalAlign: 'middle' }}>
                  {percentDifferences[index].toFixed(2)}%
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
    </S.Container>
  )
}

export default TableDifferenceCost;