import { AxiosResponse } from 'axios';
import { create } from 'zustand';
import { CreateFinancialParams, FinancialPayload, FormDataFinancial, saveBudgetProject } from 'bto_now/api/projectFinancial/saveBudgetProject';

interface CreateFinancialStore {
  financialData: FormDataFinancial[];
  createFinancial: (query: FinancialPayload, params: CreateFinancialParams[]) => Promise<AxiosResponse>;
}

export const useCreateFinancial = create<CreateFinancialStore>((set) => ({
  financialData: [],
  createFinancial: async (query: FinancialPayload, params: CreateFinancialParams[]): Promise<AxiosResponse> => {
    try {
      const response = await saveBudgetProject(query, params);

      if (response.status === 200) {
        console.log('Financial created successfully:', response);
        return response;
      } else {
        console.error('Error creating project:', response.statusText);
        return response;
      }
    } catch (error) {
      console.error('Failed to create project:', error);
      throw error; 
    }
  },
}));
