import { AxiosResponse } from "axios";
import { btoApi } from "../client";
import { StatusReportResponse } from "./getStatusReport";

interface ValidateStatusReportPayload {
  User: string;
  ProjectId: number;
  description: string;
  benefits: string;
}

export function validateStatusReport(params: ValidateStatusReportPayload): Promise<AxiosResponse<StatusReportResponse>> {
  return btoApi
    .post("/api/ProjectStatusReport/ValidateStatusReport", params);
}