import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { IoGrid } from "react-icons/io5";
import { Link, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Breadcrumb } from 'shared/components';
import { PageHeader } from "shared/layouts";

import { space } from "services";


const Finance = () => {
    const { t } = useTranslation();

    const [ key, setKey ] = useState('0');

    const routes = [ 
        {title: <IoGrid size={20} style={{margin: 0}} />},
        {title: <Link to="/dashboard/mng">{t('Home')}</Link>},
        {title: <Link to="/dashboard">{t('Portfolio Mng')}</Link>},
        {title: t('Portfolio Financial Mng')}
    ]

    useEffect(() => {

        switch (window.location.href.split('/')[5]) {
            case 'capex': setKey('2');break;
            case 'opex': setKey('3');break;
            default: setKey('1');break;
        }
        
    }, [])

    return (
        <Row>
            <Breadcrumb separator=">" items={routes} />
            <Col span={24} className="route-page">
                <PageHeader
                    title={t("Portfolio Financial Mng")}
                    navList={[
                        { label: t('Total Investments'), value: '/dashboard/financial/' },
                        { label: t('Total Capex Investments'), value: '/dashboard/financial/capex' },
                        { label: t('Total Opex Investments'), value: '/dashboard/financial/opex' },
                    ]}
                />
                <Row gutter={space} style={{marginTop: space[0]}}>
                    <Col span={24}>
                        <Outlet />
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}

export default Finance;