import { btoApi } from 'bto_now/api/client';
import { getGeneralDataSupportRisks } from 'bto_now/api/generalSupport/getGeneralDatSupportRisks';
import { create } from 'zustand';

interface GeneralDataSupportProps {
  impactScale: string[];
  probabilityScale: string[];
  statusMitigation: string[];
  type: string[];
}

interface GetGeneralDataSupport {
  generalDataSupport: GeneralDataSupportProps;
  getGeneralDataSupport: () => void;
}

const initialGeneralDataSupport: GeneralDataSupportProps = {
  impactScale: [],
  probabilityScale: [],
  statusMitigation: [],
  type: []
};

export const useGeneralDataSupport = create<GetGeneralDataSupport>((set) => ({
  generalDataSupport: initialGeneralDataSupport,
  getGeneralDataSupport: async (): Promise<void> => {
    try {
      const { data } = await getGeneralDataSupportRisks();

      if (data) {
        const typeOptions: string[] = data.tipos.find((v) => v.descricao === "TYPE")?.opcoes || [];
        const probabilityScaleOptions: string[] = data.tipos.find((v) => v.descricao === "PROBABILITY SCALE")?.opcoes || [];
        const impactScaleOptions: string[] = data.tipos.find((v) => v.descricao === "IMPACT SCALE")?.opcoes || [];
        const statusMitigationOptions: string[] = data.tipos.find((v) => v.descricao === "STATUS MITIGATION")?.opcoes || [];

        set({
          generalDataSupport: {
            impactScale: impactScaleOptions,
            probabilityScale: probabilityScaleOptions,
            statusMitigation: statusMitigationOptions,
            type: typeOptions,
          }
        });
      }

    } catch (error) {
      console.error('Failed to fetch general data support risks:', error);
    }
  }
}));
