import { btoApi } from 'bto_now/api/client';
import { getGeneralDataSupportIssues } from 'bto_now/api/generalSupport/getGeneralDataSupportIssues';
import { create } from 'zustand';

interface GeneralDataSupportProps {
  impact: string[];
  urgency: string[];
  status: string[];
  type: string[];
}

interface GetGeneralDataSupport {
  generalDataSupport: GeneralDataSupportProps;
  getGeneralDataSupport: () => void;
}

const initialGeneralDataSupport: GeneralDataSupportProps = {
  impact: [],
  urgency: [],
  status: [],
  type: []
};

export const useGeneralDataSupport = create<GetGeneralDataSupport>((set) => ({
  generalDataSupport: initialGeneralDataSupport,
  getGeneralDataSupport: async (): Promise<void> => {
    try {
      const { data } = await getGeneralDataSupportIssues();
      console.log(data)

      if (data) {
        const typeOptions: string[] = data.tipos.find((v) => v.descricao ===    "TYPE")?.opcoes || [];
        const urgencyOptions: string[] = data.tipos.find((v) => v.descricao === "URGENCY")?.opcoes || [];
        const impactOptions: string[] = data.tipos.find((v) => v.descricao === "IMPACT")?.opcoes || [];
        const statusOptions: string[] = data.tipos.find((v) => v.descricao === "STATUS")?.opcoes || [];

        set({
          generalDataSupport: {
            impact: impactOptions,
            urgency: urgencyOptions,
            status: statusOptions,
            type: typeOptions,
          }
        });
      }

    } catch (error) {
      console.error('Failed to fetch general data support issues:', error);
    }
  }
}));
