/**
 * Obtém o caminho da URL atual da página, removendo a barra final (se houver)
 * e qualquer parte da URL após o símbolo de query (?).
 *
 * @returns {string} O caminho limpo da URL, sem barras finais e sem parâmetros de consulta.
 */

export function getURLPathName() {
  return document.location.pathname.replace(/\/$/, '').split('?')[0];
}
