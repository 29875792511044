import styled from 'styled-components';

export const Container = styled.div`
  background: ${p => p.theme.colors.background};
  padding: ${p => p.theme.spacing.sm};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-bottom: 24px;
`;
