import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  border: 1px solid #D0D4E4;
  border-radius: 8px;
  height: 210px;
  width: 250px;

  @media (max-width: 1170px) {
    width: 200px;  
  }

  @media (max-width: 999px) {
    max-width: 500px; 
    width: 100%;
  }

  @media (max-width: 550px) {
    max-width: 480px;
    width: 100%;
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #D0D4E4;
  width: 100%;
`;

export const ContainerPercent = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`