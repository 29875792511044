import {create} from 'zustand';
import { getFieldListStatus, GetFieldListStatusPayload, getFieldListStatusResponse } from 'bto_now/api/project/getFieldList';

interface GetFieldListStatus {
  fieldListStatus: getFieldListStatusResponse;
  getFieldListStatus: (params: GetFieldListStatusPayload) => void;
}

const initialgetFieldListStatusResponse: getFieldListStatusResponse = {
  programList: [],
  projectTypeList: [],
  managerList: [],
  ownerList: []
};

export const useFieldListStatus = create<GetFieldListStatus>((set) => ({
    fieldListStatus: initialgetFieldListStatusResponse,
    getFieldListStatus: async (params: GetFieldListStatusPayload): Promise<void> => {
      try {
        const response = await getFieldListStatus(params)
        
        set({ fieldListStatus: response.data })
      } catch (error) {
        console.error('Failed to fetch field list status:', error)
      }
    }
}));