/**
 * Extrai o ID numérico da URL no formato "/<id>/ritual".
 * 
 * localizaa e captura o ID numérico
 * presente na URL, assumindo que a URL segue o padrão mencionado.
 * 
 * @param {string} url - A URL da qual o ID deve ser extraído.
 * @returns {string | null} O ID extraído se o formato da URL corresponder, ou null se não houver correspondência.
 *
 * @example
 * extractIdPathnameTask('https://example.com/123/ritual');
 * // Retorna: '123'
 *
 * extractIdPathnameTask('https://example.com/abc/ritual');
 * // Retorna: null (pois 'abc' não é um número)
 */

export const extractIdPathnameTask = (url: string): string | null => {
  const regex = /\/(\d+)\/ritual/;
  const match = url.match(regex);
  return match ? match[1] : null;
};