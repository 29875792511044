import { Typography } from "shared/components";
import { useTranslation } from 'react-i18next';
import ChartBar from "shared/components/Charts/ChartBar/ChartBar";
import { dataSapCost } from "../data";
import CardCost from "shared/components/CardCost";
import HeaderActionsDashboard from "shared/components/HeaderActionsDashboard";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import TableDifferenceCost from "shared/components/Table/TableDifferenceCost";

import * as S from "./styles";

const SapCostChart = () => {
  const { xAxis, dataAS, dataFS, SapEC, SapS4,SapS4Services, SapECServices,SapS4LicOne, SapECLicOne, SapECLicTwo,SapS4LicTwo  } = dataSapCost;
  const { t } = useTranslation();

  const contentDocument = useRef<HTMLDivElement>(null);

  const handleDownload = useReactToPrint({
    content: () => contentDocument.current,
  });

  const totalCost = (data: number[]) => {
    const totalCost = data.reduce((acc, item) => acc + item, 0);

    return totalCost;
  }

  const calculatePercentageIncrease = (smallerValue: number, largerValue: number) => {
    const difference = largerValue - smallerValue;
    const percentage = (difference / smallerValue) * 100;
  
    return percentage;
  }

  const calculatePercentageECCofRISE = (ecc: number, rise: number) => {
    const percentage = (ecc / rise) * 100;
    return percentage;
  }

  return (     
    <>
      <HeaderActionsDashboard title={t('Digital Business Case')} handleDownload={handleDownload} />

      <S.Container ref={contentDocument}>
        <Typography weight="bold" size="large" style={{ padding: '20px 0' }}>{t('Integrated Business Case')}</Typography>
      
        <S.InfoContainer>
          <S.CardContainer>
            <CardCost 
              title={t('Total Cost: SAP ECC')} 
              value={totalCost(SapEC)} 
              colorValue='#2A9ACF'
              percent={calculatePercentageECCofRISE(totalCost(SapEC), totalCost(SapS4))} 
              colorPercent='#008F6A' 
              />
            <CardCost 
              title={t('Total Cost: SAP S4H RISE')} 
              value={totalCost(SapS4)} 
              colorValue='#9AADBD'
              percent={calculatePercentageIncrease(totalCost(SapEC), totalCost(SapS4))}
              colorPercent='#ff574d' 
            />
          </S.CardContainer>

          <S.Container>
            <ChartBar 
              title={t('SAP ECC Cost vs SAP S4H Cost')}
              subtext={t('Data')}
              xAxis={xAxis}
              firstBar={SapEC}
              seconBar={SapS4}
            />

            <TableDifferenceCost 
              xAxis={xAxis}
              dataActualState={SapEC}
              dataFutureState={SapS4}
            />
          </S.Container>
        </S.InfoContainer>

        <br />
        <hr style={{ border: '1px solid #ddd' }} />

        

        <Typography weight="bold" size="large" style={{ padding: '20px 0' }}>{t('Licenses Part 1')}</Typography>

        <S.InfoContainer>
        <S.CardContainer>
            <CardCost 
              title={t('Total Cost: SAP ECC')} 
              value={totalCost(SapECLicOne)} 
              colorValue='#2A9ACF'
              percent={calculatePercentageECCofRISE(totalCost(SapECLicOne), totalCost(SapS4LicOne))} 
              colorPercent='#008F6A' 
              />
            <CardCost 
              title={t('Total Cost: SAP S4H RISE')} 
              // value={totalCost(SapS4LicOne)} 
              value={'42.436.982'} 
              colorValue='#9AADBD'
              percent={calculatePercentageIncrease(totalCost(SapECLicOne), totalCost(SapS4LicOne))}
              colorPercent='#ff574d' 
            />
          </S.CardContainer>

          <S.Container>
            <ChartBar 
              title={t('SAP ECC Cost vs SAP S4H Cost')}
              subtext={t('Data')}
              xAxis={xAxis}
              firstBar={SapECLicOne}
              seconBar={SapS4LicOne}
            />

            <TableDifferenceCost 
              xAxis={xAxis}
              dataActualState={SapECLicOne}
              dataFutureState={SapS4LicOne}
            />
          </S.Container>
        </S.InfoContainer>

        <br />
        <hr style={{ border: '1px solid #ddd' }} />

        <Typography weight="bold" size="large" style={{ padding: '20px 0' }}>{t('Licenses Part 2')}</Typography>

        <S.InfoContainer>
        <S.CardContainer>
            <CardCost 
              title={t('Total Cost: SAP ECC')} 
              value={totalCost(SapECLicTwo)} 
              colorValue='#2A9ACF'
              percent={calculatePercentageECCofRISE(totalCost(SapECLicTwo), totalCost(SapS4LicTwo))} 
              colorPercent='#008F6A' 
              />
            <CardCost 
              title={t('Total Cost: SAP S4H RISE')} 
              // value={totalCost(SapS4LicTwo)} 
              value={'36.443.808'} 
              colorValue='#9AADBD'
              percent={calculatePercentageIncrease(totalCost(SapECLicTwo), totalCost(SapS4LicTwo))}
              colorPercent='#ff574d' 
            />
          </S.CardContainer>

          <S.Container>
            <ChartBar 
              title={t('SAP ECC Cost vs SAP S4H Cost')}
              subtext={t('Data')}
              xAxis={xAxis}
              firstBar={SapECLicTwo}
              seconBar={SapS4LicTwo}
            />

            <TableDifferenceCost 
              xAxis={xAxis}
              dataActualState={SapECLicTwo}
              dataFutureState={SapS4LicTwo}
            />
          </S.Container>
        </S.InfoContainer>

        <br />
        <hr style={{ border: '1px solid #ddd' }} />

        <Typography weight="bold" size="large" style={{ padding: '20px 0' }}>{t('Services')}</Typography>

        <S.InfoContainer>
        <S.CardContainer>
            <CardCost 
              title={t('Total Cost: SAP ECC')} 
              value={totalCost(SapECServices)} 
              colorValue='#2A9ACF'
              percent={calculatePercentageECCofRISE(totalCost(SapECServices), totalCost(SapS4Services))} 
              colorPercent='#008F6A' 
              />
            <CardCost 
              title={t('Total Cost: SAP S4H RISE')} 
              value={totalCost(SapS4Services)} 
              colorValue='#9AADBD'
              percent={calculatePercentageIncrease(totalCost(SapECServices), totalCost(SapS4Services))}
              colorPercent='#ff574d' 
            />
          </S.CardContainer>

          <S.Container>
            <ChartBar 
              title={t('SAP ECC Cost vs SAP S4H Cost')}
              subtext={t('Data')}
              xAxis={xAxis}
              firstBar={SapECServices}
              seconBar={SapS4Services}
            />

            <TableDifferenceCost 
              xAxis={xAxis}
              dataActualState={SapECServices}
              dataFutureState={SapS4Services}
            />
          </S.Container>
        </S.InfoContainer>

      </S.Container>
    </>
  )
}

export default SapCostChart;