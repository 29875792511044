import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, Input, ButtonCalendarInput } from "shared/components";

import * as S from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSchemaIssues, schema } from "../..";
import { useGeneralDataSupport } from "shared/store-zustand/issues-project/getGeneralDataSupport";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { Owner } from "bto_now/api/project/getFieldList";

interface ModalCreateIssueProps {
  projectId?: string | undefined;
  newIssueModal: boolean;
  setNewIssueModal: (state: boolean) => void;
  setUpdateTrigger: Dispatch<SetStateAction<boolean>>;
}

const ModalCreateIssue = (props: ModalCreateIssueProps) => {
  const { projectId, newIssueModal, setNewIssueModal, setUpdateTrigger } = props;
  const { generalDataSupport } = useGeneralDataSupport();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const [resetSelect, setResetSelect] = useState(false);
  const [creatingLoading, setCreatingLoading] = useState(false);

  const { register, handleSubmit, reset, clearErrors, formState: { errors } } = useForm<FormSchemaIssues>({
    resolver: yupResolver(schema(t)),
  });

  // const createIssueDatabase = async () => {
  //   try {
  //     const newItem: CreateIssueManagementRequest = {
  //       projectId: Number(projectId),
  //       name: dataIssue.name,
  //       IssueTypeId: convertTypeInTypeId(IssueTypeList, dataIssue.IssueType),
  //       owner: dataIssue.owner,
  //       IssuestatusId: convertStatusInStatusId(IssuestatusList, dataIssue.Issuestatus),
  //       user: getUser(),
  //       description: dataIssue.description,
  //     };
  
  //     console.log(newItem);

  //     const response = await createIssueManagement(newItem);

  //     if (response.status === 200) {
  //       console.log('Issue created successfully:', response.data);
  //       setUpdateTrigger(prev => !prev);
  //     } else {
  //       console.error('Error creating Issue:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Request failed:', error);
  //   }
  // };

  useEffect(() => {
    if (newIssueModal) {
      reset(); 
      clearErrors(); 
      setResetSelect(true); 
    }
  }, [newIssueModal, reset, clearErrors]); 

  const onSubmit = async (data: FormSchemaIssues) => {
    setCreatingLoading(true);

    console.log(data);

    reset();
    clearErrors();
    setResetSelect(true);
  
    setNewIssueModal(false);
  
    setCreatingLoading(false);
  };

  const handleCancelSubmit = () => {
    reset(); 
    clearErrors(); 
    setResetSelect(true); 
    setNewIssueModal(false);
  }

  return (
    <Modal
      title={t("New Issue Mng")}
      open={newIssueModal}
      onCancel={() => setNewIssueModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={handleCancelSubmit} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit(onSubmit)} 
            loading={creatingLoading}
          >
            {t("Create")}
          </Button>
        </div>
      }
    >
      <Input
        {...register('issueName')}
        label={t('Issue Name') + ' *'}
        error={!!errors?.issueName}
      />
      {errors.issueName && <S.ErrorMessage>{errors.issueName.message}</S.ErrorMessage>}
      <Input
        {...register('issueDescription')}
        label={t('Description') + ' *'}
        error={!!errors?.issueDescription}
      />
      {errors.issueDescription && <S.ErrorMessage>{errors.issueDescription.message}</S.ErrorMessage>}
      <Select
        {...register('type')}
        options={generalDataSupport.type.map((type: string) => ({ label: type, value: type }))}
        label={t('Issue Type') + ' *'}
        reset={resetSelect}
        error={!!errors?.type}
      />
      {errors.type && <S.ErrorMessage>{errors.type.message}</S.ErrorMessage>}
      <Select
        {...register('issueStatus')}
        options={generalDataSupport.status.map((status: string) => ({ label: status, value: status }))}
        label={t('Status ') + ' *'}
        reset={resetSelect}
        error={!!errors?.issueStatus}
      />
      {errors.issueStatus && <S.ErrorMessage>{errors.issueStatus.message}</S.ErrorMessage>}
      <Select
        {...register('urgency')}
        options={generalDataSupport.urgency.map((probability: string) => ({ label: probability, value: probability }))}
        label={t('Probability Scale') + ' *'}
        reset={resetSelect}
        error={!!errors?.urgency}
      />
      {errors.urgency && <S.ErrorMessage>{errors.urgency.message}</S.ErrorMessage>}
      <Select
        {...register('impact')}
        options={generalDataSupport.impact.map((impact: string) => ({ label: impact, value: impact }))}
        label={t('Impact ') + ' *'}
        reset={resetSelect}
        error={!!errors?.impact}
      />
      {errors.impact && <S.ErrorMessage>{errors.impact.message}</S.ErrorMessage>}
      <Input
        {...register('historical')}
        label={t('Historical') + ' *'}
        error={!!errors?.historical}
      />
      {errors.historical && <S.ErrorMessage>{errors.historical.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('openDate')}
        placeholder={t('Open Date') + ' *'}
        error={!!errors?.openDate}
      />
      {errors.openDate && <S.ErrorMessage>{errors.openDate.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('forecastDate')}
        placeholder={t('Forecast Date') + ' *'}
        error={!!errors?.forecastDate}
      />
      {errors.forecastDate && <S.ErrorMessage>{errors.forecastDate.message}</S.ErrorMessage>}
      <Select
        {...register('owner')}
        options={fieldListStatus.ownerList.map((owner: Owner) => ({ label: owner.name, value: owner.ownerId.toString() }))}
        label={t('Owner') + ' *'}
        reset={resetSelect}
        error={!!errors?.owner}
      />
      {errors.owner && <S.ErrorMessage>{errors.owner.message}</S.ErrorMessage>}
    </Modal>
  )
}

export default ModalCreateIssue;