import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from "antd";
import { IoGrid } from 'react-icons/io5';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ProjectHeader } from 'shared/layouts';
import { Breadcrumb, PageHeader } from 'shared/components';

import { space } from "services";

const Initiative = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { MAIN, INITIATIVE } = useParams()  

  const routes = [
    { title: <IoGrid size={20} style={{ margin: 0 }} /> },
    { title: <Link to="/dashboard/mng">{t("Home")}</Link> },
    { title: <Link to="/dashboard">{t("Portfolio Mng")}</Link> },
    { title: <Link to={`/dashboard/initiative/${MAIN}`}>{t("Program Mng")}</Link> },
    { title: t('Project Details') }
  ]

  const isHomepage = pathname.startsWith('/dashboard/initiative/') && pathname.endsWith(`${MAIN}/${INITIATIVE}`);
  const isRitualMng = pathname.startsWith('/dashboard/initiative/') && pathname.endsWith('/ritual-mng');

  return (
    <Row>
      <Breadcrumb separator=">" items={routes} />
      <Col span={24} className="route-page">
        <>
          <PageHeader
            title='Project Details'
            menu={[
              { label: t('Homepage'), selected: isHomepage, onClick: () => navigate(`/dashboard/initiative/${MAIN}/${INITIATIVE}`)},
              { label: t('Rituals mng'), selected: isRitualMng, onClick: () => navigate(`/dashboard/initiative/${MAIN}/${INITIATIVE}/ritual-mng`) }
            ]}
          />  
          <ProjectHeader
            style={{ marginTop: 12 }}
            projectId={INITIATIVE}
          />
          <div style={{ marginTop: space[0] }}>
            <Outlet />
          </div>
        </>
      </Col>
    </Row>
  )
}

export default Initiative;