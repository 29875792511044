import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface DeleteScopeRequest {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeId: number;
  name: string;
  description: string;
  user: string;
}

export function deleteProjectScope (params: DeleteScopeRequest): Promise<AxiosResponse<DeleteScopeRequest>> {
  return btoApi.post("api/ProjectScope/Delete", params);
}