import { btoApi } from "../client"
import { AxiosResponse } from "axios";

interface RiskOption {
  descricao: string;
  opcoes: string[];
}

interface GeneralDataSupportRisksResponse {
  entidade: string;
  tipos: RiskOption[];
}

export function getGeneralDataSupportRisks(): Promise<AxiosResponse<GeneralDataSupportRisksResponse>> {
  return btoApi
    .get('/api/GeneralDataSupport/GetGeneralDataSupportRisks');
}