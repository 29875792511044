
/**
 * Extrai a última parte do caminho de uma URL, ignorando quaisquer parâmetros de consulta.
 * Isso é útil para obter o identificador final ou nome do recurso de uma URL.
 *
 * @param {string} [str] - A URL a ser processada.
 * @returns {string} A última parte do caminho da URL, sem a barra inicial.
 */

export function extractFinalSegmentFromUrl(str?: string) {
  if(!str) return '';
  const m = str.split('?')[0].match(/(\/)?[a-z0-9]{1,}$/gm);
  return m ? m[0].replace('/', '') : '';
}