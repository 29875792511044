/**
 * Se o comprimento da string exceder o tamanho especificado, a string é truncada e reticências ("...") são adicionadas ao final.
 * Caso contrário, a string original é retornada.
 * 
 * @param {string} [text] - A string que será truncada. Se não for fornecida, uma string vazia será retornada.
 * @param {number} [size] - O comprimento máximo desejado da string. Se não for fornecido, a string não será truncada.
 * @returns {string} A string truncada com reticências se for maior que o comprimento especificado, ou a string original se não precisar ser truncada.
 * 
 * @example
 * truncateString('Hello, this is a long description!', 20);
 * // Retorna: 'Hello, this is a lo...'
 * 
 * truncateString('Short text', 20);
 * // Retorna: 'Short text'
 */

export function truncateString(text?: string, size?: number) {
  if(!text) return '';
  if(text && size && text.length > size) {
    return text.substring(0, size - 3) + '...';
  }
  else return text;
}