import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";

interface DataCurveS {
  phase: string;
  phaseExecutionId: number;
  situation: string;
  valor: number;
}

interface CurveSProps {
  dataCurveS: DataCurveS[] | undefined;
  loading: boolean
  height?: string;
}

const CurveS = ({ dataCurveS, loading, height = "350px" }: CurveSProps) => {
  const { t } = useTranslation();
  const chartRef = React.useRef<HTMLDivElement>(null);
  console.log(dataCurveS)

  useEffect(() => {
    let myChart: echarts.ECharts | undefined;

    if (dataCurveS && chartRef.current) {
      const phases = [...new Set(dataCurveS.map((curve) => curve.phase.split(" & ")[0]))];

      const planData = dataCurveS
        .filter((curve) => curve.situation === "Plan")
        .map((curve) => curve.valor);

      const realData = dataCurveS
        .filter((curve) => curve.situation === "Real")
        .map((curve) => curve.valor);

      const option = {
        textStyle: { fontFamily: "Poppins" },
        tooltip: { trigger: "axis" },
        grid: {
          left: "20px",
          right: "30px",
          bottom: "0px",
          top: "20px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: phases,
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          axisLabel: { fontSize: ".7em", formatter: (v: number) => `${v}%` },
          min: 0,
          max: 100,
          interval: 20,
        },
        series: [
          {
            name: "Plan",
            type: "line",
            data: planData,
            smooth: true,
            lineStyle: {
              color: "#5470C6",
            },
          },
          {
            name: "Real",
            type: "line",
            data: realData,
            smooth: true,
            lineStyle: {
              color: "#91CC75",
            },
          },
        ],
      };

      if (!myChart) {
        myChart = echarts.init(chartRef.current);
      }

      myChart.setOption(option);

      const handleResize = () => {
        myChart?.resize();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        myChart?.dispose();
      };
    }
  }, [dataCurveS]);

  return (
    <Card style={{ height, width: "100%" }}>
      <Card.Header>
        <h3 style={{ marginBottom: "0", fontSize: "16px" }}>{t("Curve S")}</h3>
      </Card.Header>
      <Card.Body className={styles.body}>
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("Loading...")}</span>
          </Spinner>
        ) : (
          <div className={styles.curveS} ref={chartRef} />
        )}
      </Card.Body>
    </Card>
  );
};

export default CurveS;

