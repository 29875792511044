import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport';
import { Button } from 'shared/components';
import * as S from './styles';
import { useStatusReportStore } from 'shared/store-zustand/get-status-report/getStatusReport';
import { useDescriptionAndBenefitsFormStore } from 'shared/store-zustand/get-status-report/setDescriptionAndBenefits';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { message } from 'antd';
import { useTranslation } from "react-i18next";
import logoBtoNow from 'shared/assets/img/BTONow_logo_logo.png';


interface HeaderActionsDashboardProps {
  title: string;
  handleDownload: () => void;
}

const HeaderActionsDashboard = (props: HeaderActionsDashboardProps) => {
  const { typeStatusReport, setTypeStatusReport } = useTypeStatusReportStore();
  const { validateStatusReport, statusReport } = useStatusReportStore();
  const { description, benefits } = useDescriptionAndBenefitsFormStore();

  const { id } = useParams();
  const { t } = useTranslation();

  const [isValidateDisabled, setIsValidateDisabled] = useState(true);

  const { handleDownload } = props;

  const handleValidate = () => {

    if (!description.trim() || !benefits.trim()) {
      message.error('Both description and benefits must be filled in.');
      return;
    }

    validateStatusReport(Number(id), description, benefits).then((response: any) => {
      setIsValidateDisabled(response?.permissions.validateStatusReport ?? false);
      message.success('Status report published successfully');
      setTypeStatusReport(1);
    });
  };

  const publishDate = statusReport?.publishDate?.substring(0, 10);
  const formattedDate = publishDate?.split('-').reverse().join('.');

  return (
    <S.Container>
      <S.ContainerData>
        <S.Img src={logoBtoNow} alt="" width="100px" height="100px" />
        <S.Title>{t(props.title)} {formattedDate}</S.Title>
      </S.ContainerData>
      <S.containerButtons>
      {typeStatusReport === 0 &&  <Button style={{backgroundColor: '#E10088'}} disabled={!isValidateDisabled} onClick={handleValidate}>Publish</Button>}
        <Button style={{backgroundColor: '#E10088'}} onClick={handleDownload}>{t('Download')}</Button>
      </S.containerButtons>
    </S.Container>
  );
};

export default HeaderActionsDashboard;
