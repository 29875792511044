import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useForm, useFieldArray, SubmitHandler, Controller } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { FaPlus, FaTrash } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from 'antd'; 
import * as yup from "yup";

import { Table, ComponentHeader, Button, Select, ModalSuccess, Input,  ButtonCalendarInput } from "shared/components";

import { currencyMask } from "utils/masks/currency";
import { formatDateBeforeSendToBack } from "utils/dates/formatDateBeforeSendToBack";

import { getUser } from "services";
import { Owner } from "bto_now/api/project/getFieldList";
import { CreateFinancialParams, FinancialPayload, FormDataFinancial } from "bto_now/api/projectFinancial/saveBudgetProject";
import { useCreateFinancial } from "shared/store-zustand/create-project/createFinancial";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

import { NewCreateProjectData } from "..";
import { isProjectComplete } from "../helpers/isProjectComplete";

import * as S from './styles';

interface FormData {
  financial: {
    budgetOwner: string;
    budgetDate: string;
    budgetCapex: number;
    budgetOpex: number;
    totalBudget?: number;
    description: string;
  }[];
}

const schema = (t: TFunction<"translation", undefined>) => yup.object({
  financial: yup.array().of(
    yup.object({
      budgetOwner: yup.string()
        .required(t('This field is required.')),
      budgetDate: yup.string().required(t('This field is required.')),
      budgetCapex: yup.number()
        .typeError(t('Should be a number.'))
        .test(
          'is-required-when-capex-zero',
          t('Capex or Opex is required'),
          function(value) {
            const { budgetOpex } = this.parent;
            return budgetOpex !== 0 || (budgetOpex === 0 && (value ?? 0) > 0);
          }
        ),
      budgetOpex: yup.number()
        .typeError(t('Should be a number.'))
        .test(
          'is-required-when-capex-zero',
          t('Opex or Capex is required'),
          function(value) {
            const { budgetCapex } = this.parent;
            return budgetCapex !== 0 || (budgetCapex === 0 && (value ?? 0) > 0);
          }
        ),
      description: yup.string()
        .min(5, t('Field must have at least 5 characters.'))
        .required(t('This field is required.')),
    })
  ).required()
}).required();

const FinancialProject = () => {
  const { createFinancial } = useCreateFinancial();
  const { dataProject } = useCreateProject();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const { project, setProject, setAreAvaibleButtons } = useOutletContext<{
    project: NewCreateProjectData,
    setProject: React.Dispatch<React.SetStateAction<NewCreateProjectData>>,
    setAreAvaibleButtons: React.Dispatch<React.SetStateAction<boolean>>,
  }>();
  
  const navigate = useNavigate();

  const [qntyFinancial, setQntyFinancial] = useState(
    project.financial.length === 0
      ? [{ financialId: 0 }]
      : project.financial.map((item: FormDataFinancial, index: number) => ({
          financialId: index + 1,
        }))
  );

  const [modalOpen, setModalOpen] = useState(false);

  const { register, handleSubmit, control, setValue, formState: { errors, submitCount }, trigger, watch, clearErrors } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      financial: qntyFinancial.map(() => ({
        budgetOwner: '',
        budgetDate: '',
        totalBudget: 0,
        budgetCapex: 0,
        budgetOpex: 0,
        description: ''
      }))
    },
    mode: 'onBlur'
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'financial'
  });

  useEffect(() => {
    project.financial.map((item, index) => {
      item.budgetCapex && setValue(`financial.${index}.budgetCapex`, item.budgetCapex);
      item.budgetOpex && setValue(`financial.${index}.budgetOpex`, item.budgetOpex);
      item.description && setValue(`financial.${index}.description`, item.description)
    })
  }, []);

  useEffect(() => {
    const subscription = watch((value: any, { name }: any) => {
      if (name?.includes('budgetCapex') || name?.includes('budgetOpex')) {
        const index = parseInt(name.match(/\d+/)?.[0] || '0', 10);
        const capex = value.financial?.[index]?.budgetCapex || 0;
        const opex = value.financial?.[index]?.budgetOpex || 0;

        setValue(`financial.${index}.totalBudget`, capex + opex);

        setProject(prevProject => ({
          ...prevProject,
          financial: prevProject.financial?.map((item, i) =>
            i === index ? { ...item, totalBudget: capex + opex } : item
          ),
        }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  useEffect(() => {
    setAreAvaibleButtons(isProjectComplete(project))
  }, [project])
  
  const CreateFinancialDatabase = async (data: FormData) => {
    try {
      const user = getUser();

      const query: FinancialPayload = { user: user ?? null };

      const newItems: CreateFinancialParams[] = data.financial.map((financial) => ({
        portfolioId: 1,
        programId: dataProject.programId!,
        projectId: dataProject.projectId!,
        financialTypeId: 0,
        financialDate: formatDateBeforeSendToBack(financial.budgetDate),
        financialId: 0,
        description: financial.description,
        capex: financial.budgetCapex,
        opex: financial.budgetOpex,
        ownerId: Number(financial.budgetOwner),
        monitorId: 0
      }));

      const response = await createFinancial(query, newItems);

      if (response.status === 200) setModalOpen(true);

    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    CreateFinancialDatabase(data)
    // navigate('/dashboard/mng')
  };

  const handleNewTableFinancial = async () => {
    const isValidForm = await trigger();
    const newBudget = {
      budgetOwner: '',
      budgetDate: '',
      totalBudget: 0,
      budgetCapex: 0,
      budgetOpex: 0,
      description: ''
    };

    if (isValidForm) {
      setQntyFinancial(prev => {
        const newFinancial = [...prev, { financialId: prev.length + 1 }];
        append(newBudget);
        clearErrors(`financial`);

        return newFinancial;
      });

      setProject((prevProject) => ({
        ...prevProject,
        financial: [...prevProject.financial, newBudget],
      }));
    } else {
      message.warning(t('Please fill out all required fields before adding a new financial entry.'));
    }
  };

  const formChange = (index: number, field: string, value: string | number) => {
    setValue(`financial.${index}.${field}`, value);
    clearErrors(`financial.${index}.${field}`);
  };

  const currencyToFloat = (currency: string) => {
    return parseFloat(currency.replace(/R\$\s?|,/g, '').trim());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;

    setProject(prevProject => ({
      ...prevProject,
      financial: prevProject.financial?.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));

    formChange(index, name, value);
  };

  const handleInputMoneyChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;

    setProject(prevProject => ({
      ...prevProject,
      financial: prevProject.financial?.map((item, i) =>
        i === index ? { ...item, [name]: currencyToFloat(value) } : item
      ),
    }));

    formChange(index, name, currencyToFloat(value));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const { name, value } = e.target;

    setProject(prevProject => ({
      ...prevProject,
      financial: prevProject.financial?.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));

    formChange(index, name, value);
  };

  const handleDeleteFinancial = (index: number) => {
    if (index > 0) {
      remove(index);

      setProject(prevProject => ({
        ...prevProject,
        financial: prevProject.financial?.filter((item, i) =>
          i !== index
        ),
      }));

      return;
    }

    message.warning(t('The first form cannot be deleted.'));
  }

  const handleModalOk = () => {
    setModalOpen(false)
  }

  return (
    <>
      <ComponentHeader title={t('PMO: Create Project Financial')} menu={[{ label: t('Financial'), selected: true }]} />

      <S.ContainerList>
        <Table>
          <Table.Tbody>
            {fields.map((item, index) => (
              <Table.Tr style={{ borderBottom: '1px solid #ccc' }} key={item.id}>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <ButtonCalendarInput
                      {...register(`financial.${index}.budgetDate`)}
                      placeholder={t('Budget Date') + ' *'}
                      name="budgetDate"
                      value={project.financial[index]?.budgetDate}
                      onChange={e => handleInputChange(e, index)}
                      error={submitCount > 0 && !!errors?.financial?.[index]?.budgetDate}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.budgetDate && <S.ErrorMessage>{errors.financial[index].budgetDate.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <Controller
                      name={`financial.${index}.budgetCapex`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('Capex') + ' *'}
                          value={currencyMask(project.financial[index]?.budgetCapex)}
                          name="budgetCapex"
                          onChange={(e) => {
                            field.onChange(currencyToFloat(e.target.value))
                            handleInputMoneyChange(e, index)
                          }}
                          error={submitCount > 0 && !!errors?.financial?.[index]?.budgetCapex}
                          maxWidth
                        />
                      )}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.budgetCapex && <S.ErrorMessage>{errors.financial[index].budgetCapex.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <Controller
                      name={`financial.${index}.budgetOpex`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('Opex') + ' *'}
                          value={currencyMask(project.financial[index]?.budgetOpex)}
                          name="budgetOpex"
                          onChange={(e) => {
                            field.onChange(currencyToFloat(e.target.value))
                            handleInputMoneyChange(e, index)
                          }}
                          error={submitCount > 0 && !!errors?.financial?.[index]?.budgetOpex}
                          maxWidth
                        />
                      )}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.budgetOpex && <S.ErrorMessage>{errors.financial[index].budgetOpex.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <Controller
                      name={`financial.${index}.totalBudget`}
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          label={t('Total Budget') + ' *'}
                          value={currencyMask(project.financial[index]?.totalBudget)}
                          maxWidth
                          readOnly
                        />
                      )}
                    />
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }}>
                  <S.InputContainer>
                    <Select
                      {...register(`financial.${index}.budgetOwner`)}
                      options={fieldListStatus.ownerList.map((option: Owner) => ({ label: option.name, value: option.ownerId.toString() }))}
                      label={t('Budget Owner') + '*'}
                      name="budgetOwner"
                      value={project.financial[index]?.budgetOwner}
                      onChange={e => handleSelectChange(e, index)}
                      error={submitCount > 0 && !!errors?.financial?.[index]?.budgetOwner}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.budgetOwner && <S.ErrorMessage>{errors.financial[index].budgetOwner.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }}>
                  <S.InputContainer>
                    <Input
                      {...register(`financial.${index}.description`)}
                      label={t('Description') + '*'}
                      name="description"
                      value={project.financial[index]?.description}
                      onChange={e => handleInputChange(e, index)}
                      error={submitCount > 0 && !!errors?.financial?.[index]?.description}
                    />
                    {submitCount > 0 && errors?.financial?.[index]?.description && <S.ErrorMessage>{errors.financial[index].description.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }} onClick={() => handleDeleteFinancial(index)}>
                  <S.IconContainer>
                    <FaTrash />
                  </S.IconContainer>
                </Table.Td>
              </Table.Tr>
            ))}

            <Table.Tr onClick={handleNewTableFinancial} style={{ background: 'white', cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Table.Td style={{ width: '30px' }}><FaPlus size={18} /></Table.Td>
              <Table.Td colSpan={10}>{t("New Financial")}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </S.ContainerList>

      <S.ButtonContainer>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </S.ButtonContainer>

      <ModalSuccess
        open={modalOpen}
        onOk={handleModalOk}
        description="Project create with success"
        title="Success"
      />
    </>
  );
};

export default FinancialProject;