import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, Input } from "shared/components";

import { getUser } from "services";
import { Owner } from "bto_now/api/project/getFieldList";
import { GetListScopeResponse } from "bto_now/api/projectScope/getListScope";
import { updateProjectScope, UpdateProjectScopeRequest } from "bto_now/api/projectScope/updateScope";
import { GetListScopeTypeResponse } from "bto_now/api/scopeType/getListScopeType";
import { GetListScopeStatusResponse } from "bto_now/api/scopeStatus/getListScopeStatus";

import { convertTypeIdInType } from "utils/convertType/convertTypeIdInType";
import { convertStatusIdInStatus } from "utils/convertStatus/convertStatusIdInStatus";
import { projectOwnerIdToName } from "utils/convertIdToName/projectOwnerIdToName";
import { convertStatusInStatusId } from "utils/convertStatus/convertStatusInStatusId";

import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

import * as S from './styles';

interface ModalEditScopeProps {
  projectId: number | undefined;
  scopeId: number | null;
  editScopeModal: boolean;
  updateTrigger: boolean;
  scopeListData: GetListScopeResponse[];
  scopeTypeList: GetListScopeTypeResponse[];
  scopeStatusList: GetListScopeStatusResponse[];
  setEditScopeModal: (state: boolean) => void;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedScopes: React.Dispatch<React.SetStateAction<Set<number>>>;
  setSelectedScopeId: React.Dispatch<React.SetStateAction<number | null>>;
}

export interface ScopeDataEdit {
  scopeId: number;
  scopeType: number;
  scopeStatus: string;
  name: string;
  owner: number;
  description: string;
}

const ModalEditScope: React.FC<ModalEditScopeProps> = (props) => {
  const { editScopeModal, setEditScopeModal, setUpdateTrigger, scopeId, updateTrigger, scopeListData, scopeStatusList, scopeTypeList, setSelectedScopes, setSelectedScopeId } = props;
  const { dataProject } = useCreateProject();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const [creatingLoading, setCreatingLoading] = useState(false);
  const [dataScope, setDataScope] = useState<ScopeDataEdit>({
    scopeId: 0,
    name: '',
    scopeType: 0,
    owner: 0,
    scopeStatus: '',
    description: ''
  });
  const [errors, setErrors] = useState({
    scopeStatus: false,
  });

  useEffect(() => {
    if (scopeId !== null) {
      const scopeData = scopeListData.find(scope => scope.scopeId === scopeId);

      if (scopeData) {
        setDataScope({
          scopeId: scopeData.scopeId,
          name: scopeData.name,
          scopeType: scopeData.scopeTypeId,
          owner: scopeData.ownerId,
          scopeStatus: convertStatusIdInStatus(scopeStatusList, scopeData.scopeStatusId),
          description: scopeData.description
        });
      }
    }
  }, [scopeId, updateTrigger, editScopeModal]);

  const handleSelectChange = (name: keyof ScopeDataEdit, value: string) => {
    setDataScope(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const updateScopeDatabase = async () => {
    try {
      const newItem: UpdateProjectScopeRequest = {
        portfolioId: 1,
        projectId: dataProject.projectId!,
        programId: dataProject.programId,
        scopeId: dataScope.scopeId,
        name: dataScope.name,
        scopeTypeId: Number(dataScope.scopeType),
        ownerId: Number(dataScope.owner),
        scopeStatusId: convertStatusInStatusId(scopeStatusList, dataScope.scopeStatus),
        user: getUser(),
        description: dataScope.description,
      };

      const response = await updateProjectScope(newItem);

      if (response?.status === 200) {
        console.log('Scope updated successfully:', response);

        setUpdateTrigger(prev => !prev);
        setSelectedScopes(prevSelected => {
          const newSelected = new Set(prevSelected);
          newSelected.delete(dataScope.scopeId);
          return newSelected;
        });
        setSelectedScopeId(null);
      } else {
        console.error('Error updating scope:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating scope:', error);
    }
  };

  const handleSubmit = () => {
    setCreatingLoading(true);

    const newErrors = {
      scopeStatus: !dataScope.scopeStatus,
    };

    setErrors(newErrors);

    const hasError = Object.values(newErrors).some(error => error);
    if (!hasError) {
      updateScopeDatabase();
      setDataScope({
        scopeId: 0,
        name: '',
        scopeType: 0,
        owner: 0,
        scopeStatus: '',
        description: ''
      });

      setEditScopeModal(false);
    }

    setCreatingLoading(false);
  };

  return (
    <Modal
      title={t(`Edit Scope Mng: Scope ${scopeId}`)}
      open={editScopeModal}
      onCancel={() => setEditScopeModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={() => setEditScopeModal(false)} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit} 
            loading={creatingLoading}
          >
            {t("Save")}
          </Button>
        </div>
      }
    >
      <Input
        label={t('Scope Name') + ' *'}
        name='name'
        value={dataScope.name}
        readOnly
      />
      <Select
        options={scopeTypeList.map(scopesType => ({ label: scopesType.scopeType, value: scopesType.scopeType }))}
        label={t('Scope Type') + ' *'}
        name='scopeType'
        value={convertTypeIdInType(scopeTypeList, dataScope.scopeType)}
        readOnly
      />
      <Select
        options={fieldListStatus.ownerList.map((owner: Owner) => ({ label: owner.name, value: owner.name }))}
        label={t('Owner')}
        name='owner'
        value={projectOwnerIdToName(fieldListStatus.ownerList, dataScope.owner)}
        readOnly
      />
      <Input
        label={t('Description') + ' *'}
        name='description'
        value={dataScope.description}
        readOnly
      />
      <Select
        options={scopeStatusList.map(scopeStatus => ({ label: scopeStatus.scopeStatus, value: scopeStatus.scopeStatus }))}
        label={t('Scope Status') + ' *'}
        name='scopeStatus'
        value={dataScope.scopeStatus}
        onChange={(e) => handleSelectChange('scopeStatus', e.target.value)}
        error={errors.scopeStatus}
      />
      {errors.scopeStatus && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
    </Modal>
  );
}

export default ModalEditScope;