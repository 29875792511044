import { Card, Col, Divider, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { POST_API, STATUS_LG, getToken, getUser } from "../../../../../../../services";
import { useTranslation } from 'react-i18next';

import * as echarts from 'echarts';

const Financial = () => {

    const { INITIATIVE } = useParams()

    const [ blocks, setBlocks ] = useState([]);

    const onload = () => {
        POST_API('/detail.php', { token: getToken(), user: getUser(), initiative: INITIATIVE, period: 'Quarter', size: 50 }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setBlocks(res.data)
            } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
        }).catch(() => null)
    }

    useEffect(() => {

        var chartDom = document.getElementById('curvess');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            xAxis: { type: 'category', data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] },
            yAxis: { type: 'value' },
            grid: { right: '0px', top: '10px', bottom: '20px' },
            series: [ { data: [Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000, Math.random()*1000], type: 'line', smooth: true } ]
        };
          
        myChart.setOption(option)

        onload()

    }, [])

    const { t } = useTranslation();
    return (
        <Row gutter={[8,8]}>
            <Col span={24}>
                <Row gutter={[8,8]}>
                    <Col span={14}>
                        <Row gutter={[8,8]}>
                            <Col span={6}>
                                <Card size="small" className="operation-card" title={t("Status Cost")}>
                                    <div className="operation-kpi">{STATUS_LG[blocks.statusCoast]}</div>
                                </Card>
                            </Col>
                            <Col span={9}>
                                <Card size="small" className="operation-card" title={t("Total Investments Requested")}>
                                    <div className="operation-kpi"><Typography className="operation-kpi-text">R$ 0,00</Typography></div>
                                </Card>
                            </Col>
                            <Col span={9}>
                                <Card size="small" className="operation-card" title={t("Total Investments Realized")}>
                                    <div className="operation-kpi"><Typography className="operation-kpi-text">R$ 0,00</Typography></div>
                                </Card>
                            </Col>
                            <Col span={9}>
                                <Card size="small" className="operation-card" title={t("Devious Investments")}>
                                    <div className="operation-kpi"><Typography className="operation-kpi-text">0,0%</Typography></div>
                                </Card>
                            </Col>
                            <Col span={5}>
                                <Card size="small" className="operation-card" title={t("Custom KPI")}>
                                    <div className="operation-kpi"><Typography className="operation-kpi-text">0,00</Typography></div>
                                </Card>
                            </Col>
                            <Col span={5}>
                                <Card size="small" className="operation-card" title={t("Custom KPI")}>
                                    <div className="operation-kpi"><Typography className="operation-kpi-text">0,00</Typography></div>
                                </Card>
                            </Col>
                            <Col span={5}>
                                <Card size="small" className="operation-card" title={t("Custom KPI")}>
                                    <div className="operation-kpi"><Typography className="operation-kpi-text">0,00</Typography></div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={10}>
                        <Card title={t("Curve S - Financial (Plan X Real)")} size={'small'}>
                            <div id="curvess" style={{height: 170}}></div>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col span={24}><Divider /></Col>
            <Col span={24}>
                <Row gutter={[8,8]} style={{ flexWrap: 'nowrap', overflow: 'hidden' }}>
                    <Col flex={'200px'}>
                        <Typography className="title-operational">{t("PHASE")}</Typography>
                        <Typography className="text-operational">{t("Start Project & Setup PMO Governance")}</Typography>
                        <Typography className="text-operational alt">{t("Kick-off & Explorer")}</Typography>
                        <Typography className="text-operational">{t("Build & Development")}</Typography>
                        <Typography className="text-operational alt">{t("UAT")}</Typography>
                        <Typography className="text-operational">{t("Cutover")}</Typography>
                        <Typography className="text-operational alt last">{t("Go-live & Hypercare")}</Typography>
                    </Col>
                    <Col flex={'auto'}>
                        <Row gutter={[8,8]} style={{ flexWrap: 'nowrap', overflowY: 'auto' }}>
                            <Col flex={'360px'}>
                                <Typography className="title01-operational">{t("Financial Planning")}</Typography>
                                <Row className="subtitle01-operational">
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("Total Capex")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("Total Opex")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'150px'}>{t("Total Investments")}</Col>
                                </Row>
                                <Row className="rowa">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0</Col>
                                </Row>
                                <Row className="rowa">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0</Col>
                                </Row>
                                <Row className="rowa">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0</Col>
                                </Row>
                                <Row className="alt last">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0</Col>
                                </Row>
                            </Col>
                            <Col flex={'1365px'} style={{minWidth: '1365px', width: '1365px', maxWidth: '1365px'}}>
                                <Typography className="title01-operational">{t("Financial Realized")}</Typography>
                                <Row className="subtitle01-operational">
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("Total Capex")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'150px'}>{t("Deviation Capex")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'100px'}>{t("Total Opex")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'150px'}>{t("Deviation Opex")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'160px'}>{t("Total Investments")}</Col>
                                    <Col className="subtitle01-col-operational" flex={'200px'}>{t("Deviation Investments")} (%)</Col>
                                    <Col className="subtitle01-col-operational" flex={'300px'}>{t("Last Update")}</Col>
                                </Row>
                                <Row className="rowa">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'160px'}>0</Col>
                                    <Col className="col-operational" flex={'200px'}>0,0</Col>
                                    <Col className="col-operational" flex={'300px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'160px'}>0</Col>
                                    <Col className="col-operational" flex={'200px'}>0,0</Col>
                                    <Col className="col-operational" flex={'300px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="rowa">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'160px'}>0</Col>
                                    <Col className="col-operational" flex={'200px'}>0,0</Col>
                                    <Col className="col-operational" flex={'300px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="alt">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'160px'}>0</Col>
                                    <Col className="col-operational" flex={'200px'}>0,0</Col>
                                    <Col className="col-operational" flex={'300px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="rowa">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'160px'}>0</Col>
                                    <Col className="col-operational" flex={'200px'}>0,0</Col>
                                    <Col className="col-operational" flex={'300px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                                <Row className="alt last">
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'100px'}>0</Col>
                                    <Col className="col-operational" flex={'150px'}>0,0</Col>
                                    <Col className="col-operational" flex={'160px'}>0</Col>
                                    <Col className="col-operational" flex={'200px'}>0,0</Col>
                                    <Col className="col-operational" flex={'300px'}>00/00/0000 00:00 {t("BY USER")}</Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Financial;