import { Col, Modal, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { POST_API, getToken, getUser } from "../../../../../../../services";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { useTranslation } from "react-i18next";


const RII = () => {
    const { t } = useTranslation();

    const { INITIATIVE } = useParams()

    const [ blocks, setBlocks ] = useState([]);
    const [ select, setSelect ] = useState([]);

    const onload = () => {
        POST_API('/detail.php', { token: getToken(), user: getUser(), initiative: INITIATIVE, period: 'Quarter', size: 50 }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setBlocks(res.data)
            } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
        }).catch(() => null)
    }

    useEffect(() => {
        onload()
    }, [])

    const onSelect = (index) => {
        
        var items = select;

        if (typeof select?.[index] !== 'undefined') {
            items = { ...items, [index]: !items[index] }
        } else {
            items = { ...items, [index]: true };
        }

        setSelect(items)

    }

    return (
        <Row gutter={[8,8]}>
            <Col span={24}>
                <Row>
                    { select?.[0] ? (
                        <>
                            <Col span={24} className="standard-col select" style={{borderLeftColor: '#322690', backgroundColor: `#3226901a`}} onClick={() => onSelect(0)}>
                                <Row className="standard-row">
                                    <Col span={24} className="standard-col-col first">
                                        <IoChevronDown style={{color: '#322690'}} className="standard-col-col-arrow" />
                                        <Typography className="standard-title" style={{color: '#322690'}}>Risks Report</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className="standard-col item" style={{borderLeftColor: '#322690', backgroundColor: `#3226901a`}} >
                                <Row className="standard-row">
                                <Col flex={'300px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Risk name")}</Typography>
                                </Col>
                                <Col flex={'400px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Risk description")}</Typography>
                                </Col>
                                <Col flex={'100px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Type")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Owner")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Status mitigation")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Probability Scale")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Impact Scale")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Historical Monitoring")}</Typography>
                                </Col>
                                <Col flex={'200px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Last Update")}</Typography>
                                </Col>
</Row>
                            </Col>
                        </>
                    ) : (
                        <Col span={24} className="standard-col" style={{borderLeftColor: '#322690', backgroundColor: `#3226901a`, height: '52px'}} onClick={() => onSelect(0)}>
                            <Row className="standard-row">
                                <Col span={24} className="standard-col-col first">
                                    <div style={{display: 'flex'}}>
                                        <IoChevronForward style={{color: '#322690'}} className="standard-col-col-arrow" />
                                        <div>
                                            <Typography className="standard-title" style={{color: "#322690"}}>{t("Risks Report")}</Typography>
                                            <Typography className="standard-subtitle">0 {t("Itens")}</Typography>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) }
                    { select?.[1] ? (
                        <>
                            <Col span={24} className="standard-col select" style={{borderLeftColor: '#4033BC', backgroundColor: `#4033BC1a`}} onClick={() => onSelect(1)}>
                                <Row className="standard-row">
                                    <Col span={24} className="standard-col-col first">
                                        <IoChevronDown style={{color: '#4033BC'}} className="standard-col-col-arrow" />
                                        <Typography className="standard-title" style={{color: '#4033BC'}}>{t("Issues Report")}</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className="standard-col item" style={{borderLeftColor: '#4033BC', backgroundColor: `#4033BC1a`}} >
                                <Row className="standard-row">
                                <Col flex={'400px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Issue description")}</Typography>
                                </Col>
                                <Col flex={'100px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Type")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Owner")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Urgency")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Impact")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Status")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Historical Monitoring")}</Typography>
                                </Col>
                                <Col flex={'200px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Last Update")}</Typography>
                                </Col>
                            </Row>
                            </Col>
                        </>
                    ) : (
                        <Col span={24} className="standard-col" style={{borderLeftColor: '#4033BC', backgroundColor: `#4033BC1a`, height: '52px'}} onClick={() => onSelect(1)}>
                            <Row className="standard-row">
                                <Col span={24} className="standard-col-col first">
                                    <div style={{display: 'flex'}}>
                                        <IoChevronForward style={{color: '#4033BC'}} className="standard-col-col-arrow" />
                                        <div>
                                            <Typography className="standard-title" style={{color: "#4033BC"}}>{t("Issues Report")}</Typography>
                                            <Typography className="standard-subtitle">0 Itens</Typography>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) }
                    { select?.[2] ? (
                        <>
                            <Col span={24} className="standard-col select" style={{borderLeftColor: '#5442E2', backgroundColor: `#5442E21a`}} onClick={() => onSelect(2)}>
                                <Row className="standard-row">
                                    <Col span={24} className="standard-col-col first">
                                        <IoChevronDown style={{color: '#5442E2'}} className="standard-col-col-arrow" />
                                        <Typography className="standard-title" style={{color: '#5442E2'}}>{t("Impacts Report")}</Typography>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} className="standard-col item" style={{borderLeftColor: '#5442E2', backgroundColor: `#5442E21a`}} >
                                <Row className="standard-row">
                                <Col flex={'300px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Impact name")}</Typography>
                                </Col>
                                <Col flex={'400px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Impact description")}</Typography>
                                </Col>
                                <Col flex={'100px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Type")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Related To")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Name Of Issue/Risk")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Owner")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Status")}</Typography>
                                </Col>
                                <Col flex={'150px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Historical Monitoring")}</Typography>
                                </Col>
                                <Col flex={'200px'} className="standard-col-subcol">
                                    <Typography className="standard-data">{t("Last Update")}</Typography>
                                </Col>
                            </Row>
                            </Col>
                        </>
                    ) : (
                        <Col span={24} className="standard-col" style={{borderLeftColor: '#5442E2', backgroundColor: `#5442E21a`, height: '52px'}} onClick={() => onSelect(2)}>
                            <Row className="standard-row">
                                <Col span={24} className="standard-col-col first">
                                    <div style={{display: 'flex'}}>
                                        <IoChevronForward style={{color: '#5442E2'}} className="standard-col-col-arrow" />
                                        <div>
                                            <Typography className="standard-title" style={{color: "#5442E2"}}>{t("Impacts Report")}</Typography>
                                            <Typography className="standard-subtitle">0 {t("Itens")}</Typography>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    ) }
                </Row>
            </Col>
        </Row>
    )

}

export default RII;