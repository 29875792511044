import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal } from 'antd';

import { Button, Select, TextArea, Input } from "shared/components";

import { getUser } from "services";
import { CreateProjectParams } from "bto_now/api/project/createProject";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { useStatusModalStore } from "shared/store-zustand/create-project/getStatusModal";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";
import { useListTimeline } from "shared/store-zustand/create-project/getListTimeline";

import * as S from './styles';
import { Manager, Owner, Program, ProjectType } from "bto_now/api/project/getFieldList";
import { GetTimelinePhasePayload } from "bto_now/api/ProjectTimeline/getListTimeline";

export interface CreateProjectData {
  programName: string;
  projectName: string;
  projectType: string;
  projectManager: string;
  projectOwner: string;
  projectSummary: string;
}

const ModalCreateProject = () => {
  const { fieldListStatus, getFieldListStatus } = useFieldListStatus();
  const { createProject } = useCreateProject();
  const { getTimelinePhases } = useListTimeline();
  const { setStatusModal, statusModal } = useStatusModalStore();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const initialCreateProjectData: CreateProjectData = {
    programName: '',
    projectName: '',
    projectType: '',
    projectManager: '',
    projectOwner: '',
    projectSummary: ''
  };

  const initialCreateGeneralErrors = {
    programName: false,
    projectName: false,
    projectType: false,
    projectManager: false,
    projectOwner: false,
    projectSummary: false,
  }

  const [projectData, setprojectData] = useState<CreateProjectData>(initialCreateProjectData);
  const [errors, setErrors] = useState(initialCreateGeneralErrors);

  const [resetSelect, setResetSelect] = useState(false);
  const [creatingLoading, setCreatingLoading] = useState(false);

  const params = {
    User: getUser(),
    portfolioId: 1
  }

  useEffect(() => {
    try {
      getFieldListStatus(params)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setprojectData(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const handleSelectChange = (name: keyof CreateProjectData, value: string) => {
    setprojectData(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const createprojectDatabase = async () => {
    try {
      const newItem: CreateProjectParams = {
        portfolioId: 1,
        programId: Number(projectData.programName),
        name: projectData.projectName,
        summary: projectData.projectSummary,
        projectTypeId: Number(projectData.projectType),
        managerId: Number(projectData.projectManager),
        ownerId: Number(projectData.projectOwner),
        user: getUser()
      };

      createProject(newItem)

    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const handleSubmit = async () => {
    setCreatingLoading(true);
  
    const newErrors = {
      programName: !projectData.programName,
      projectName: !projectData.projectName,
      projectType: !projectData.projectType,
      projectManager: !projectData.projectManager,
      projectOwner: !projectData.projectOwner,
      projectSummary: !projectData.projectSummary,
    };

    setErrors(newErrors);
  
    const hasError = Object.values(newErrors).some(error => error);
    if (!hasError) {
      await createprojectDatabase();

      const queriesTimeline: GetTimelinePhasePayload = {
        user: getUser(),
        portfolioId: 1,
        programId: 1,
        projectId: 25
      };
    
      getTimelinePhases(queriesTimeline);
  
      setprojectData(initialCreateProjectData); 
  
      setResetSelect(true);
      setStatusModal(false);
    }
  
    setCreatingLoading(false);
  };

  const handleCancelSubmit = () => {
    navigate('/dashboard/mng')
    setStatusModal(false);
  }

  return (
    <Modal
      title={t("New Project Mng")}
      open={statusModal}
      onCancel={handleCancelSubmit}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={handleCancelSubmit} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit} 
            loading={creatingLoading ? true : undefined} 
          >
            {t("Create")}
          </Button>
        </div>
      }
    >
      <Select
        options={fieldListStatus.programList.map((option: Program) => ({ label: option.name, value: option.programId.toString() }))}
        label={t('Program Name') + ' *'}
        name='programName'
        value={projectData.programName}
        onChange={(e) => handleSelectChange('programName', e.target.value)}
        error={errors.programName}
        reset={resetSelect}
      />
      {errors.programName && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Input
        label={t('Project Name') + ' *'}
        name='projectName'
        value={projectData.projectName}
        onChange={handleInputChange}
        error={errors.projectName}
      />
      {errors.projectName && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={fieldListStatus.projectTypeList.map((option: ProjectType) => ({ label: option.projectType, value: option.projectTypeId.toString()}))}
        label={t('Project Type') + ' *'}
        name='projectType'
        value={projectData.projectType}
        onChange={(e) => handleSelectChange('projectType', e.target.value)}
        error={errors.projectType}
        reset={resetSelect}
      />
      {errors.projectType && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={fieldListStatus.managerList.map((option: Manager) => ({ label: option.name, value: option.managerId.toString() }))}
        label={t('Project Manager') + ' *'}
        name='projectManager'
        value={projectData.projectManager}
        onChange={(e) => handleSelectChange('projectManager', e.target.value)}
        error={errors.projectManager}
        reset={resetSelect}
      />
      {errors.projectType && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={fieldListStatus.ownerList.map((option: Owner) => ({ label: option.name, value: option.ownerId.toString() }))}
        label={t('Project Owner') + ' *'}
        name='projectOwner'
        value={projectData.projectOwner}
        onChange={(e) => handleSelectChange('projectOwner', e.target.value)}
        error={errors.projectOwner}
        reset={resetSelect}
      />
      {errors.projectOwner && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <TextArea
        containerStyle={{ height: 100, justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'start' }}
        label={t('Summary') + ' * '}
        name="projectSummary"
        value={projectData.projectSummary}
        onChange={handleInputChange}
        error={errors.projectSummary}
      />
      {errors.projectSummary && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
    </Modal>
  )
}

export default ModalCreateProject;