import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { message } from 'antd'; 

import { Select, SpinLoading, Table, ModalSuccess, CheckBox, ComponentHeader, ButtonsActionsPage, Input, Button } from "shared/components";
import ModalCreateScope from "./components/ModalCreateScope";
import ModalEditScope from "./components/ModalEditScope";

import { getUser } from "services";
import { Owner } from "bto_now/api/project/getFieldList";
import { getListScopeType, GetListScopeTypeResponse } from "bto_now/api/scopeType/getListScopeType";
import { getListScopeStatus, GetListScopeStatusResponse } from "bto_now/api/scopeStatus/getListScopeStatus";

import { debounce } from "utils";
import { convertTypeIdInType } from "utils/convertType/convertTypeIdInType";
import { convertStatusIdInStatus } from "utils/convertStatus/convertStatusIdInStatus";
import { projectOwnerIdToName } from "utils/convertIdToName/projectOwnerIdToName";

import { deleteProjectScope, DeleteScopeRequest } from "bto_now/api/projectScope/deleteScope";
import { getListProjectScope, GetListScopeResponse, GetListScopPayload } from "bto_now/api/projectScope/getListScope";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";

import { NewCreateProjectData } from "..";
import { isProjectComplete } from "../helpers/isProjectComplete";

import * as S from './styles';

export interface ScopeData {
  scopeType: string;
  scopeStatus: string;
  name: string;
  owner: string;
  description: string;
}

const ScopeProject = () => {
  const { dataProject } = useCreateProject();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const initialScopeData: ScopeData = {
    scopeType: '',
    scopeStatus: '',
    name: '',
    owner: '',
    description: '',
  };

  const { project, setAreAvaibleButtons } = useOutletContext<{
    project: NewCreateProjectData,
    setAreAvaibleButtons: React.Dispatch<React.SetStateAction<boolean>>,
  }>();

  const [filters, setFilters] = useState<ScopeData>(initialScopeData);
  const [scopeListData, setScopeListData] = useState<GetListScopeResponse[]>([]);
  const [filteredScopeList, setFilteredScopeList] = useState<GetListScopeResponse[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState('');
  const [newScopeModal, setNewScopeModal] = useState(false);
  const [editScopeModal, setEditScopeModal] = useState(false);

  const [selectedScopes, setSelectedScopes] = useState<Set<number>>(new Set());
  const [selectedScopeId, setSelectedScopeId] = useState<number | null>(null);

  const [scopeTypeList, setScopeTypeList] = useState<GetListScopeTypeResponse[]>([]);
  const [scopeStatusList, setScopeStatusList] = useState<GetListScopeStatusResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
  
      const queries: GetListScopPayload = {
        user: getUser(),
        portfolioId: 1,
        programId: dataProject.programId,
        projectId: dataProject.projectId!
      }
  
      try {
        const scopeData = await getListProjectScope(queries); 
        setScopeListData(scopeData.data);
        
        const { data: scopeTypeData } = await getListScopeType({ User: getUser() });
        setScopeTypeList(scopeTypeData);

        const { data: scopeStatusData } = await getListScopeStatus({ User: getUser() });
        setScopeStatusList(scopeStatusData);
  
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    debounce(fetchData, 500)();
  }, [updateTrigger]);

  useEffect(() => {
    const filterScopes = () => {
      const filtered = scopeListData.filter(scope => {
        const typeMatch = scope.scopeTypeId.toString() === filters.scopeType || filters.scopeType === '';
        const statusMatch = scope.scopeStatusId.toString() === filters.scopeStatus || filters.scopeStatus === '';
        const nameMatch = scope.name.toLowerCase().includes(filters.name.toLowerCase());
        const ownerMatch = scope.owner === filters.owner || filters.owner === '';
        
        return typeMatch && statusMatch && nameMatch && ownerMatch;
      });

      setFilteredScopeList(filtered);
    };

    filterScopes();
  }, [filters, scopeListData]);

  useEffect(() => {
    setAreAvaibleButtons(isProjectComplete(project))
  }, [project])

  const handleCheck = (id: number) => {
    setSelectedScopes(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
        setSelectedScopeId(null);
      } else {
        newSelected.add(id);
        setSelectedScopeId(id);
      }
      return newSelected;
    });
  };

  const deleteScopeDatabase = async () => {
    try {
      const selectedIds = Array.from(selectedScopes);
      for (const idSelect of selectedIds) {
        const params: DeleteScopeRequest = {
          portfolioId: 1,
          projectId: dataProject.projectId!,
          programId: dataProject.programId!,
          scopeId: idSelect,
          user: getUser(),
          name: '',
          description: '',
        };

        const response = await deleteProjectScope(params)

        if (response.status === 200) {
          console.log('Scope deleted successfully:', response);
        } else {
          console.error('Error deleting project:', response.statusText);
        }
      }

      setModalOpen(true);
      setModalDescription(`Deleted ${selectedIds.length} scopes successfully.`);

    } catch (error) {
      console.error('Error deleting scopes:', error);
    }
  }

  const handleDelete = () => {
    deleteScopeDatabase();

    setUpdateTrigger(prev => !prev);
    setSelectedScopes(new Set());
    setSelectedScopeId(null);
  };

  const handleModalOk = () => {
    setModalOpen(false);
  };

  const handleOpenModalEdit = () => {
    if (selectedScopes.size === 1) {
      const selectedId = Array.from(selectedScopes)[0];
      setSelectedScopeId(selectedId);
      setEditScopeModal(true);
    }
  };

  const handleOpenModalCreate = () => {
    setNewScopeModal(true);
  };

  const handleNextPage = () => {
    if (scopeListData.length > 0) {
      return navigate('/dashboard/mng/pmo/new-create-project/financial')
    }

    message.warning(t('To proceed to the financial phase, it is necessary to register at least one scope..'));
  }

  const isChecked = (id: number) => selectedScopes.has(id);

  const isDisabledButtonDelete = selectedScopes.size === 0;
  const isDisabledButtonEdit = selectedScopes.size !== 1;
  const isDisabledButtonNew = selectedScopes.size > 0;

  return (
    <>
      <ComponentHeader title={t('PMO: Create Project Scope')} menu={[{ label: t('Scope'), selected: true }]} />

      <S.ContainerForms>
        <S.FormRow>
          <div style={{ display: 'flex', gap: '0.75rem', flex: 1, flexWrap: 'wrap' }}>
            <S.InputContainer>
              <Input
                label={t('Scope Name')}
                name='scopeName'
                value={filters.name}
                onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                style={{ fontSize: '12px' }}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={scopeTypeList.map(type => ({ label: type.scopeType, value: type.scopeTypeId.toString() }))}
                label={t('Scope Type')}
                name='scopeType'
                value={filters.scopeType}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, scopeType: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={fieldListStatus.ownerList.map((owner: Owner) => ({ label: owner.name, value: owner.ownerId.toString() }))}
                label={t('Owner')}
                name='owner'
                value={filters.owner}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, scopeStatus: e.target.value })}
              />
            </S.InputContainer>
            <S.InputContainer>
              <Select
                options={scopeStatusList.map(status => ({ label: status.scopeStatus, value: status.scopeStatusId.toString() }))}
                label={t('Scope Status')}
                name='scopeStatus'
                value={filters.scopeStatus}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setFilters({ ...filters, scopeStatus: e.target.value })}
              />
            </S.InputContainer>
          </div>
          <S.ButtonContainerRow>
            <ButtonsActionsPage
              isDisabledButtonDelete={isDisabledButtonDelete}
              isDisabledButtonEdit={isDisabledButtonEdit}
              isDisabledButtonNew={isDisabledButtonNew}
              handlePlus={handleOpenModalCreate}
              handleEdit={handleOpenModalEdit}
              handleTrash={handleDelete}
            />
          </S.ButtonContainerRow>
        </S.FormRow>
      </S.ContainerForms>

      <S.ContainerList>
        {
          isLoading ? (
            <SpinLoading />
          ) : (
            <S.TableContainer>
              <Table style={{ marginTop: 16 }}>
                <Table.Thead>
                  <Table.Tr header>
                    <Table.Td style={{ padding: '4px 12px' }}></Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>Id</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>Scope Name</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>Scope Type</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>Scope Owner</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>Description</Table.Td>
                    <Table.Td style={{ padding: '4px 12px' }}>Scope Status</Table.Td>
                  </Table.Tr>
                </Table.Thead>

                <Table.Tbody>
                  {
                    filteredScopeList.map((scope) => (
                      <Table.Tr key={scope.scopeId} style={{ borderBottom: '1px solid #ccc' }}>
                        <Table.Td style={{ width: 35 }}><CheckBox onChange={() => handleCheck(scope.scopeId)} checked={isChecked(scope.scopeId)} size="medium" /></Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{scope.scopeId}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{scope.name}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{convertTypeIdInType(scopeTypeList, scope.scopeTypeId)}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{projectOwnerIdToName(fieldListStatus.ownerList ,scope.ownerId)}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{scope.description}</Table.Td>
                        <Table.Td style={{ padding: '8px 12px' }}>{convertStatusIdInStatus(scopeStatusList, scope.scopeStatusId)}</Table.Td>
                      </Table.Tr>
                    ))
                  }
                </Table.Tbody>
              </Table>
            </S.TableContainer>
          )
        }
        <S.ButtonContainerRow>
          <Button onClick={handleNextPage}>Save</Button>
        </S.ButtonContainerRow>
      </S.ContainerList>

      <ModalSuccess
        open={modalOpen}
        onOk={handleModalOk}
        description={modalDescription}
        title="Success"
      />

      <ModalCreateScope 
        scopeStatusList={scopeStatusList}
        scopeTypeList={scopeTypeList}
        newScopeModal={newScopeModal}
        setNewScopeModal={setNewScopeModal}
        setUpdateTrigger={setUpdateTrigger}
      />

      <ModalEditScope 
        projectId={dataProject.projectId}
        scopeId={selectedScopeId}
        scopeListData={scopeListData}
        editScopeModal={editScopeModal}
        updateTrigger={updateTrigger}
        setEditScopeModal={setEditScopeModal}
        setUpdateTrigger={setUpdateTrigger}
        scopeTypeList={scopeTypeList}
        scopeStatusList={scopeStatusList}
        setSelectedScopes={setSelectedScopes}
        setSelectedScopeId={setSelectedScopeId}
      />
    </>
  );
};

export default ScopeProject;





