import { AxiosResponse } from "axios";
import { btoApi } from "../client";
import { StatusReportResponse } from "./getStatusReport";

interface NewStatusReportPayload {
  User: string;
  ProjectId: number;
  description: string;
  benefits: string;
}

export function newStatusReport(params: NewStatusReportPayload): Promise<AxiosResponse<StatusReportResponse>> {
  return btoApi
    .post("/api/ProjectStatusReport/NewStatusReport", params);
}