import { FormDataTimeline } from "bto_now/api/ProjectTimeline/saveTimeline";
import { NewCreateProjectData } from "..";
import { FormDataFinancial } from "bto_now/api/projectFinancial/saveBudgetProject";

export const isProjectComplete = (project: NewCreateProjectData): boolean => {
  const {
    startDate,
    endDate,
    sponsors,
    businessLeader,
    itLeader,
    projectKeyUser,
    businessArea,
    goLivePlanDate,
    projectProviders,
    businessBenefits,
    technologyBenefits,
    processInvolved,
    platformInvolved,
    businessDrivers,
  } = project.additionalDetails;

  if (
    !startDate ||
    !endDate || 
    sponsors.length === 0 || 
    businessLeader.length === 0 || 
    itLeader.length === 0 || 
    projectKeyUser.length === 0 || 
    businessArea.length === 0 || 
    !goLivePlanDate || 
    projectProviders.length === 0 || 
    !businessBenefits || 
    !technologyBenefits || 
    processInvolved.length === 0 || 
    platformInvolved.length === 0 || 
    !businessDrivers
  ) {
    return false;
  }
  console.log('additional details')

  const allTimelineFilled = project.timeline.some((phase: FormDataTimeline) => 
    phase.phaseName &&
    phase.planStartDate &&
    phase.planEndDate &&
    phase.phaseOwner &&
    phase.phaseMilestone
  );

  if (!allTimelineFilled) {
    return false;
  }
  console.log('timeline')

  if (project.scopes === undefined) return false;

  console.log('scopes')

  const allFinancialFilled = project.financial.some((finance: FormDataFinancial) => 
    finance.budgetOwner &&
    finance.budgetDate &&
    finance.totalBudget > 0 && 
    finance.budgetCapex >= 0 && 
    finance.budgetOpex >= 0 &&
    finance.description
  );

  return allFinancialFilled;
};

