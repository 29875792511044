import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface GetTimelinePhasePayload {
  user?: string | null;
  portfolioId: number;
  programId: number;
  projectId: number;
}

export interface TimelinePhaseData {
  portfolioId: number;
  programId: number;
  projectId: number;
  phaseId: number;
  phase: string;
  startDate: string | null;
  endDate: string | null;
  ownerId: number;
  owner: string;
}

export function getListTimeline(params: GetTimelinePhasePayload): Promise<AxiosResponse<TimelinePhaseData[]>> {
  return btoApi
    .get(`api/ProjectTimeline/GetList?User=${params.user}&PortfolioId=${params.portfolioId}&ProgramId=${params.programId}&ProjectId=${params.projectId}`);
}