import { Typography } from "shared/components";
import CurveS from "shared/components/CurveS";

import { useTranslation } from "react-i18next";
import Investment from "../Investment";
import InvestmentList from "../InvestmentList";
import Issues from "../Issues";
import ProjectInformation from "../ProjectInformation";
import Risks from "../Risks";
import Scope from "../Scope";
import Tasks from "../Tasks";
import TrackingMonitor from "../TrackingMonitor/index";
import * as S from './styles';
import { StatusReportResponse } from "bto_now/api/statusReport/getStatusReport";



type CardDetailsProps = {
  detailsCards: StatusReportResponse | null;
  loading: boolean
};

const CardDetails = ({ detailsCards, loading }: CardDetailsProps) => {
  const { t } = useTranslation();

  const componentsCards = [
    {
      id: 1,
      title: 'Risks',
      component: <Risks itemsRisks={detailsCards} loading={loading} />,
    },
    {
      id: 2,
      title: 'Issues',
      component: <Issues itemsIssues={detailsCards} loading={loading} />
    },
    {
      id: 3,
      title: 'Tasks',
      component: <Tasks itemsTasks={detailsCards} loading={loading} />
    },
    {
      id: 4,
      title: 'Investment (Plan vs Real)',
      component: <Investment itemsInvestment={detailsCards} loading={loading} />
    },
  ]

  return (
    <>
      <S.Container>
        {componentsCards.map((itemCard) => (
          <S.Card key={itemCard.id}>
            <S.TitleCard>
              <Typography size='medium' weight="bold" style={{ color: '#344050' }}>{t(itemCard.title)}</Typography>
            </S.TitleCard>
            {itemCard.component}
          </S.Card>
        ))}
        <InvestmentList itemInvestment={detailsCards} loading={loading} />
        <S.CardCurveS>
          <CurveS dataCurveS={detailsCards?.detalhesDoProjeto.otherDetailsInfo.newCurveS} loading={false} height='330px' />
        </S.CardCurveS>
        <S.CardTracking>
          <S.TitleCardWithBorder>
            <Typography size='medium' weight="bold">{t('Tracking Monitor')}</Typography>
          </S.TitleCardWithBorder>
          <TrackingMonitor />
        </S.CardTracking>
        <S.Card>
          <S.TitleCard>
            <Typography size='medium' weight="bold">{t('Scope')}</Typography>
          </S.TitleCard>
          <Scope itemsScope={detailsCards} loading={loading} />
        </S.Card>
        <S.Card >
          <S.TitleCardWithBorder>
            <Typography size='medium' weight="bold">{t('Project Information')}</Typography>
          </S.TitleCardWithBorder>
          <ProjectInformation informations={detailsCards} loading={loading} />
        </S.Card>
      </S.Container>
      <S.Container>
      </S.Container>
    </>
  )
}

export default CardDetails;
