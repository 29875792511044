import React from 'react';
import * as echarts from 'echarts/core';

import ReactECharts, { EChartsOption } from 'echarts-for-react';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, LegendComponent]);

interface DataFormatterObject {
  [key: string]: number[];
}

interface DataMap {
  firstBar: { [key: string]: { name: string; value: number }[] };
  seconBar: { [key: string]: { name: string; value: number }[] };
}

interface ChartBarProps {
  title: string;
  subtext: string;
  firstBar: number[];
  seconBar: number[];
  xAxis: string[];
}

const ChartBar: React.FC<ChartBarProps> = ({ title, subtext, firstBar, seconBar, xAxis }) => {
  let dataMap: DataMap = {
    firstBar: {},
    seconBar: {},
  };

  const dataFormatter = (obj: DataFormatterObject) => {
    return Object.keys(obj).reduce((acc, val) => {
      return {
        ...acc,
        [val]: obj[val].map((value, index) => ({
          name: xAxis[index],
          value
        }))
      };
    }, {} as { [key: string]: { name: string; value: number }[] });
  };

  dataMap.firstBar = dataFormatter({ data: firstBar });
  dataMap.seconBar = dataFormatter({ data: seconBar });

  const formatCurrency = (value: number) => {
    return `R$ ${value.toLocaleString('pt-BR')}`;
  };

  const formatColumnY = (value: number) => {
    if (Math.abs(value) >= 1e6) {
      return (value / 1e6).toFixed(value % 1e6 === 0 ? 0 : 1) + 'M'; 
    } else if (Math.abs(value) >= 1e3) {
      return (value / 1e3).toFixed(value % 1e3 === 0 ? 0 : 1) + 'K';
    } else {
      return value.toString();
    }
  }

  const tooltipFormatter = (params: any) => {
    let tooltipText = `${params[0].name}<br />`;
    params.forEach((item: any) => {
      tooltipText += `
        <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
        ${item.seriesName}: ${formatCurrency(item.value)}<br />
      `;
    });
    return tooltipText;
  };

  const getOption = (): EChartsOption => ({
    title: {
      text: title,
      subtext: subtext,
      top: 20,
      left: 'center',
      offset: [0, 10],
      textStyle: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        colot: '#000',
      },
      subtextStyle: {
        fontFamily: 'Poppins, sans-serif', 
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: tooltipFormatter,
      textStyle: {
        fontFamily: 'Poppins, sans-serif', 
      },
    },
    legend: {
      data: [
        { name: 'Actual State' }, 
        { name: 'Future State' }
      ],
      textStyle: {
        rich: {
          title: {
            color: '#C4CBD4',
            fontFamily: 'Poppins, sans-serif',
            align: 'center', 
          },
        },
      },
      top: 'top',
      right: 20,
      itemGap: 32,
    },
    grid: {
      top: 70,
      bottom: 30,
    },
    xAxis: [
      {
        type: 'category',
        axisLabel: { 
          interval: 0,
          fontFamily: 'Poppins, sans-serif', 
        },
        data: xAxis,
        splitLine: { show: false },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: (value: number) => formatColumnY(value),
          fontFamily: 'Poppins, sans-serif', 
        },
      },
    ],
    series: [
      {
        name: 'Actual State',
        type: 'bar',
        data: dataMap.firstBar['data'].map(item => item.value),
        itemStyle: {
          color: '#007EB5'
        }
      },
      {
        name: 'Future State',
        type: 'bar',
        data: dataMap.seconBar['data'].map(item => item.value),
        itemStyle: {
          color: '#9AADBD'
        }
      },
    ],
  });

  return <ReactECharts option={getOption()} style={{ height: '332px', width: '100%' }} />;
};

export default ChartBar;

