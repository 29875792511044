import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface UpdateProjectScopeRequest {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeId: number;
  scopeTypeId: number;
  scopeStatusId: number;
  name: string;
  description: string;
  ownerId: number;
  user: string;
}

export function updateProjectScope (params: UpdateProjectScopeRequest): Promise<AxiosResponse<UpdateProjectScopeRequest>> {
  return btoApi.post("api/ProjectScope/Update", params);
}