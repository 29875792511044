/**
 * Formata um número como uma string representando um valor monetário, com sufixos para milhões e milhares.
 * 
 * A função converte um número para uma representação mais compacta com sufixos 'k' (mil) ou 'm' (milhão) e formata o número com separadores de milhar.
 * Se o valor tiver exatamente três zeros após o ponto decimal, esses zeros são removidos.
 * 
 * @param {number} value - O valor numérico a ser formatado. Deve ser um número positivo.
 * @returns {string} O valor formatado como uma string, com sufixos apropriados e formatação decimal.
 * 
 * @example
 * moneyMask(1234567); 
 * // Retorna: '1.235m'
 * 
 * moneyMask(12345);
 * // Retorna: '12.345k'
 * 
 * moneyMask(123456);
 * // Retorna: '123.456k'
 * 
 * moneyMask(123000);
 * // Retorna: '123'
 */

export function moneyMask(value: number) {
  // Sufixo baseado no valor do número
  let suffix = '';
  if (value >= 1000000) {
    value = value / 1000000;
    suffix = 'm';
  } else if (value >= 1000) {
    value = value / 1000;
    suffix = 'k';
  }

  // Formatando o número com separadores de milhar
  let formattedValue = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  }).format(value);

  // Substituindo vírgulas por pontos
  formattedValue = formattedValue.replace(/,/g, '.');

  // Removendo zeros finais se todos forem zeros após o ponto decimal
  if (formattedValue.endsWith('.000')) {
    formattedValue = formattedValue.slice(0, -4);
  }

  return formattedValue + suffix;
}
