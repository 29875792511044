import {create} from 'zustand';

interface StatusReportState {
    typeStatusReport: number;
    getTypeStatusReport: (status:number) => void;
    setTypeStatusReport: (status:number) => void;
}

export const useTypeStatusReportStore = create<StatusReportState>((set) => ({
    typeStatusReport: 1,
    setTypeStatusReport: (status: number) => {
        set({typeStatusReport: status})
    },
    getTypeStatusReport: (status) => {
        // Implementation for getTypeStatusReport
        console.log(`Status report for status: ${status}`);
    }
}));