import { Col, Input, Row, Typography } from "antd";
import { IoGrid } from "react-icons/io5";
import * as echarts from 'echarts';
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import KPIComponent from "shared/components/KPI";
import GraphComponent from "shared/components/Graph";
import { getUser, space } from "../../../../services";
import { MdAttachMoney, MdTimelapse } from "react-icons/md";

import dados from './BusinessDrivers/Business.json';
import { getBusinessDriverCanva, GetBusinessDriverCanvaResponse } from 'bto_now/api';
// import { useTheme } from "styled-components";
import { date, getUrlSearchParams } from "utils";
import { SpinLoading, Breadcrumb } from 'shared/components';
import { useTranslation } from "react-i18next";
import Semaphore from "shared/components/SemaphoreVariant";

const BusinessCanva = () => {
  const { t } = useTranslation();

  // const theme = useTheme();
  const { BUSINESS: bs } = useParams<{ BUSINESS: string }>();
  const BUSINESS = Number(bs) || 0; // TODO: depois de tirar o mocado remover essa linha "import dados from './Business/Business.json';"

  const [data, setData] = useState<GetBusinessDriverCanvaResponse>();

  useEffect(() => {
    getBusinessDriverCanva({ User: getUser(), BusinessDriverId: BUSINESS })
      .then(({ data }) => setData(data))
  }, [BUSINESS]);


  const routes = [
    {title: <IoGrid size={20} style={{margin: 0}} />},
    {title: <Link to="/dashboard/mng">{t("Home")}</Link>},
    {title: <Link to="/dashboard">{t("Portfolio Mng")}</Link>},
    {title: <Link to="/dashboard/business/0">{t("Portfolio Business Driver")}</Link>},
    {title: t('Canva')}
  ];

  const loadCurveFinancial = () => {

    var chartDom = document.getElementById('curveFinancial');
    if (!chartDom) return;

    var myChart = echarts.init(chartDom);
    var option;

    option = {
      textStyle: { fontFamily: 'Poppins' }, tooltip: { trigger: 'axis' },
      grid: { left: '20px', right: '10px', bottom: '0px', top: '20px', containLabel: true },
      xAxis: { type: 'category', data: dados[BUSINESS].curveFinancialX },
      yAxis: { type: 'value', axisLabel: { fontSize: '.7em', formatter: (v: any) => `$ ${(v).toLocaleString('pt-BR')}` } },
      series: [
        { name: t("Plan"), data: dados[BUSINESS].curveFinancialPlan, type: 'line', smooth: true },
        { name: t("Real"), data: dados[BUSINESS].curveFinancialReal, type: 'line', smooth: true }
      ]
    };

    option && myChart.setOption(option);

  }

  const loadCurveOperational = () => {

    var chartDom = document.getElementById('curveOperational');
    if (!chartDom) return;

    var myChart = echarts.init(chartDom);
    var option;

    option = {
      textStyle: { fontFamily: 'Poppins' }, tooltip: { trigger: 'axis' },
      grid: { left: '20px', right: '10px', bottom: '0px', top: '20px', containLabel: true },
      xAxis: { type: 'category', data: dados[BUSINESS].curveOperationalX },
      yAxis: { type: 'value', axisLabel: { fontSize: '.7em', formatter: (v: any) => `${v}%` } },
      series: [
        { name: t("Plan"), data: dados[BUSINESS].curveOperationalPlan, type: 'line', smooth: true },
        { name: t("Real"), data: dados[BUSINESS].curveOperationalReal, type: 'line', smooth: true }
      ]      
    };

    option && myChart.setOption(option);

  }

  useEffect(() => {
    loadCurveFinancial();
    loadCurveOperational();
  }, [data]);

  return (
    <Row>
        <Breadcrumb separator=">" items={routes} />
      <Col span={24} className="route-page">
        {!data ? (
          <SpinLoading />
        ) : (
          <>
            <div className="graph-card">
              <Row gutter={[12, 12]} align={'middle'} style={{ flexWrap: 'nowrap' }}>
                <Col flex={'40%'}><Typography className="route-page-title">{t(getUrlSearchParams('bd'))}</Typography></Col>
                <Col flex={'20%'}><Input className="canva-input" readOnly value={data.businessLeader} /><Typography className="canva-label">{t("Business Leader")}</Typography></Col>
                <Col flex={'20%'}><Input className="canva-input" readOnly value={data.itLeader} /><Typography className="canva-label">{t("IT Leader")}</Typography></Col>
                <Col flex={'10%'}><Input className="canva-input" readOnly value={date(data.startDate)} /><Typography className="canva-label">{t("Start Date")}</Typography></Col>
                <Col flex={'10%'}><Input className="canva-input" readOnly value={date(data.endDate)} /><Typography className="canva-label">{t("End Date")}</Typography></Col>
              </Row>
            </div>
            <Row gutter={[12, 12]} style={{ marginTop: space[0] }}>

              <Col flex={'25%'}>
                  <KPIComponent value={data.businessBenefits?.length} title={t("Business Benefits")} type="group" size="card">
                      <div style={{ height: 400, overflowY: 'auto' }} className="canva-table">
                          <Row className="canva-row head">
                              <Col flex={'auto'} className="canva-col-head">
                                  <Typography className="canva-data">{t("Name")}</Typography>
                              </Col>
                          </Row>
                          {data.businessBenefits?.map((v, i) => (
                              <Row className={`canva-row ${(i) % 2!== 0? 'alt' : ''}`} key={i}>
                                  <Col flex={'auto'} className="canva-col-head">
                                  <Typography className="canva-data">{v.name}</Typography>
                                  </Col>
                              </Row>
                          ))}
                      </div>
                  </KPIComponent>
              </Col>

              <Col flex={'25%'}>
                <KPIComponent value={data.projectList?.length} title={t("Project List")} type="list" size="card">
                  <div style={{ height: 400, overflowY: 'auto' }} className="canva-table">
                    <Row className="canva-row head">
                      <Col flex={'50px'} className="canva-col-head">
                        <Typography className="canva-data">{t("ID")}</Typography>
                      </Col>
                      <Col flex={'auto'} className="canva-col-head">
                        <Typography className="canva-data">{t("Name")}</Typography>
                      </Col>
                      <Col flex={'30px'} className="canva-col-head">
                        <Typography className="canva-data"><MdAttachMoney size={16} /></Typography>
                      </Col>
                      <Col flex={'30px'} className="canva-col-head">
                        <Typography className="canva-data"><MdTimelapse size={16} /></Typography>
                      </Col>
                    </Row>
                    {data.projectList?.map((v, i) => (
                      <Row className={`canva-row ${(i + i) % 2 !== 0 ? 'alt' : ''}`} key={i}>
                        <Col flex={'50px'} className="canva-col-head">
                          <Typography className="canva-data">{t(v.projectId)}</Typography>
                        </Col>
                        <Col flex={'auto'} className="canva-col-head">
                          <Typography className="canva-data left">{t(v.projectName)}</Typography>
                        </Col>
                        <Col flex={'30px'} className="canva-col-head">
                          <Semaphore value={v.statusTime}/>
                        </Col>
                        <Col flex={'30px'} className="canva-col-head">
                          <Semaphore value={v.statusCost}/>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </KPIComponent>
              </Col>
              <Col flex={'25%'}>
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <KPIComponent value={data.mainAreas?.length} title={t("Main Areas")} type="chat" size="card">
                      <div style={{ height: 180, overflowY: 'auto' }} className="canva-table">
                        <Row className="canva-row head">
                          <Col flex={'auto'} className="canva-col-head">
                            <Typography className="canva-data">{t("Name")}</Typography>
                          </Col>
                        </Row>
                        {data.mainAreas?.map((v, i) => (
                          <Row className={`canva-row ${(i) % 2!== 0? 'alt' : ''}`} key={1}>
                            <Col flex={'auto'} className="canva-col-head">
                              <Typography className="canva-data left">{t(v.name)}</Typography>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </KPIComponent>
                  </Col>
                  <Col span={24}>
                   <KPIComponent value={data.stakeHolders?.length} title={t("Stakeholders")} type="star" size="card">
                      <div style={{ height: 118, overflowY: 'auto' }} className="canva-table">
                        <Row className="canva-row head">
                          <Col flex={'auto'} className="canva-col-head">
                            <Typography className="canva-data">{t("Name")}</Typography>
                          </Col>
                        </Row>
                        {data.stakeHolders?.map((v, i) => (
                          <Row className={`canva-row ${(i) % 2!== 0? 'alt' : ''}`} key={1}>
                            <Col flex={'auto'} className="canva-col-head">
                              <Typography className="canva-data left">{t(v.name)}</Typography>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </KPIComponent>
                  </Col>
                </Row>
              </Col>
              <Col flex={'25%'}>
                <Row gutter={[12, 12]}>
                  <Col span={24}>
                    <KPIComponent value={data.mainRisk?.length} title={t("Main Risks")} type="warn" size="card">
                      <div style={{ height: 180, overflowY: 'auto' }} className="canva-table">
                        <Row className="canva-row head">
                          <Col flex={'auto'} className="canva-col-head">
                            <Typography className="canva-data">{t("Name")}</Typography>
                          </Col>
                        </Row>
                        {data.mainRisk?.map((v, i) => (
                          <Row className={`canva-row ${(i) % 2!== 0? 'alt' : ''}`} key={1}>
                            <Col flex={'auto'} className="canva-col-head">
                              <Typography className="canva-data left">{t(v.name)}</Typography>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </KPIComponent>
                  </Col>
                  <Col span={24}>
                    <KPIComponent value={data.mainProviders?.length} title={t("Main Providers")} type="star" size="card">
                      <div style={{ height: 118, overflowY: 'auto' }} className="canva-table">
                        <Row className="canva-row head">
                          <Col flex={'auto'} className="canva-col-head">
                            <Typography className="canva-data">{t("Name")}</Typography>
                          </Col>
                        </Row>
                        {data.mainProviders?.map((v, i) => (
                          <Row className={`canva-row ${(i) % 2!== 0? 'alt' : ''}`} key={1}>
                            <Col flex={'auto'} className="canva-col-head">
                              <Typography className="canva-data left">{t(v.name)}</Typography>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </KPIComponent>
                  </Col>
                </Row>
              </Col>

              <Col flex={'50%'}>
                <KPIComponent title={t("Financial Structure")} value={<Semaphore size={'md'} value={dados[BUSINESS].statusCost} />} type="money" size="card">
                
                  <Row gutter={[12, 12]}>
                    <Col span={16}>
                      <GraphComponent title={t("S Curve - Financial")}>
                        <div style={{ height: 110 }} id="curveFinancial" />
                      </GraphComponent>
                    </Col>
                    <Col span={8}>
                      <KPIComponent value={(dados[BUSINESS].totalBudgetPlan).toLocaleString('pt-BR')} title={t("Plan")} type="money" />
                      <KPIComponent value={(dados[BUSINESS].totalBudgetReal).toLocaleString('pt-BR')} title={t("Real")} type="money" />
                    </Col>
                  </Row>
                </KPIComponent>
              </Col>
              <Col flex={'50%'}>
                <KPIComponent value={<Semaphore size={'md'} value={dados[BUSINESS].statusTime} />} title={t("Operational Structure")} type="time" size="card">
                  <Row gutter={[12, 12]}>
                    <Col span={16}>
                      <GraphComponent title={t("S Curve - Operational")}>
                        <div style={{ height: 110 }} id="curveOperational" />
                      </GraphComponent>
                    </Col>
                    <Col span={8}>
                      <KPIComponent value={dados[BUSINESS].timelinePlan} title={t("Plan")} type="percent" />
                      <KPIComponent value={dados[BUSINESS].timelineReal} title={t("Real")} type="percent" />
                    </Col>
                  </Row>
                </KPIComponent>
              </Col>

            </Row>
          </>
        )}
      </Col>
    </Row>
  )

}

export default BusinessCanva;