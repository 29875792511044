import { btoApi } from "../client"
import { AxiosResponse } from "axios";

interface IssueOption {
  descricao: string;
  opcoes: string[];
}

interface GeneralDataSupportIssuesResponse {
  entidade: string;
  tipos: IssueOption[];
}


export function getGeneralDataSupportIssues(): Promise<AxiosResponse<GeneralDataSupportIssuesResponse>> {
  return btoApi
    .get('/api/GeneralDataSupport/GetGeneralDataSupportIssues');
}