import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';

import KPIComponent from "shared/components/KPI";
import GraphComponent from "shared/components/Graph";

import { PALETE, getUser, space } from "services";
import { getDashFinancial } from 'bto_now/api/index';

const TotalInvestments = () => {
    const { t } = useTranslation();
    const [ blocks, setBlocks ] = useState([]);

    const onload = () => {
        getDashFinancial({ User: getUser(), OpcaoCapexOpex: 'Opcao'})
            .then(({ data }) => {
                setBlocks(data)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        onload()
    }, [])

    const loadGraphBar01 = () => {
        if (!blocks.totalInvestmentsRealized) return;
    
        var chartDom = document.getElementById('graphBar01');
        var myChart = echarts.init(chartDom);
        var option;
    
        option = {
            textStyle: { fontFamily: 'Poppins, sans-serif' },
            color: [PALETE.C01, PALETE.C03],
            responsive: true,
            dataZoom: [{ type: 'inside' }],
            legend: {
                center: 'center',
                itemWidth: 14,
                bottom: '-5px',
                width: '100%'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'shadow' },
                valueFormatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}`
            },
            grid: {
                left: '5px',
                right: '5px',
                bottom: '24px',
                top: '20px',
                containLabel: true
            },
            xAxis: [{
                type: 'category',
                data: blocks.totalInvestmentsRealized.map(item => item.programme),
                axisTick: { alignWithLabel: true }
            }],
            yAxis: [{
                type: 'value',
                axisLabel: {
                    formatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}`
                }
            }],
            series: [
                {
                    name: 'Project Budget',
                    barWidth: 60,
                    type: 'bar',
                    data: blocks.totalInvestmentsRealized.map(item => item.projectBudjet),
                    label: {
                        formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`,
                        show: true,
                        position: 'top',
                        backgroundColor: '#393557',
                        color: '#FFF',
                        borderRadius: 3,
                        padding: 3
                    },
                    itemStyle: { borderRadius: [8, 8, 0, 0] },
                    markLine: {
                        data: blocks.totalInvestmentsRealized.map(item => ({
                            yAxis: item.projectBudjet,
                            label: {
                                show: 'true',
                                fontSize: '9px',
                                position: 'insideStartTop',
                                formatter: `Budget ${item.programme}`
                            },
                            lineStyle: {
                                color: '#E77E9F'
                            },
                            symbol: 'none'
                        })),
                        
                    }
                },
                {
                    name: 'Budget Consumed',
                    barWidth: 60,
                    type: 'bar',
                    data: blocks.totalInvestmentsRealized.map(item => item.budgetConsumed),
                    label: {
                        formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`,
                        show: true,
                        position: 'top',
                        backgroundColor: '#4B4672',
                        color: '#FFF',
                        borderRadius: 3,
                        padding: 3
                    },
                    itemStyle: { borderRadius: [8, 8, 0, 0] }
                }
            ]
        };
    
        option && myChart.setOption(option);
    };
    
    const loadGraphBar02 = () => {
        if (!blocks.totalRealizedByMonths) return;
    
        var chartDom = document.getElementById('graphBar02');
        var myChart = echarts.init(chartDom);
        var option;
    
        option = {
            textStyle: { fontFamily: 'Poppins, sans-serif' },
            color: [PALETE.C01],
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'shadow' },
                valueFormatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}`
            },
            grid: { 
                left: '5px', 
                right: '5px', 
                bottom: '10px', 
                top: '20px', 
                containLabel: true 
            },
            xAxis: [{
                type: 'category',
                data: blocks.totalRealizedByMonths.map(item => item.monthYear),
                axisTick: { alignWithLabel: true }
            }],
            yAxis: [{
                type: 'value',
                axisLabel: { 
                    formatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}`
                }
            }],
            dataZoom: [{ type: 'inside' }],
            series: [{
                name: 'Total Realized by Months',
                barWidth: 60,
                type: 'bar',
                data: blocks.totalRealizedByMonths.map(item => item.totalRealized),
                label: {
                    formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`,
                    show: true,
                    position: 'top',
                    backgroundColor: '#4B4672',
                    color: '#FFF',
                    borderRadius: 3,
                    padding: 3
                },
                itemStyle: { borderRadius: [10, 10, 0, 0] }
            }]
        };
    
        option && myChart.setOption(option);
    };   

    // const loadGraphBar03 = () => {

    //     var chartDom = document.getElementById('graphBar03');
    //     var myChart = echarts.init(chartDom);
    //     var option;

    //     option = {
    //         color: [ PALETE.C01 ], dataZoom: [ { type: 'inside' } ],
    //         tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }, valueFormatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}` },
    //         grid: { left: '5px', right: '5px', bottom: '10px', top: '20px', containLabel: true },
    //         xAxis: [ { type: 'category', data: blocks?.GRAPH03?.eixoX, axisTick: { alignWithLabel: true } } ],
    //         yAxis: [ { type: 'value', axisLabel: { formatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}` } } ],
    //         series: [
    //             { 
    //                 name: 'Total Realized By Phases', barWidth: 40, type: 'bar', 
    //                 data: blocks?.GRAPH03?.data,
    //                 label: { formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`, show: true, position: 'top', backgroundColor: '#4B4672', color: '#FFF', borderRadius: 3, padding: 3 }, itemStyle: { borderRadius: [10,10,0,0] }
    //             }
    //         ]
    //     };

    //     option && myChart.setOption(option);

    // }

    useEffect(() => {
        loadGraphBar01()
        loadGraphBar02()
        // loadGraphBar03()
    }, [blocks])


    return (
        <Row gutter={space}>
            <Col span={4}>
                <KPIComponent title={t("Total Budget")} value={(blocks?.totalInvestiments?.totalBudget)?.toLocaleString('pt-BR')} type="money" />
            </Col>
            <Col span={4}>
                <KPIComponent title={t("Forecast")} value={(blocks?.totalInvestiments?.forecast)?.toLocaleString('pt-BR')} type="money" />
            </Col>
            <Col span={4}>
                <KPIComponent title={t("Consumed")} value={(blocks?.totalInvestiments?.consumed)?.toLocaleString('pt-BR')} type="money" />
            </Col>
            <Col span={4}>
                <KPIComponent title={t("Available")} value={(blocks?.totalInvestiments?.available)?.toLocaleString('pt-BR')} type="money" />
            </Col>
            <Col span={4}>
                <KPIComponent title={t("Extra Budget")} value={(blocks?.totalInvestiments?.extraBudget)?.toLocaleString('pt-BR')} type="money" />
            </Col>
            <Col span={4}>
                <KPIComponent title={t("Total Investments")} value={(blocks?.totalInvestiments?.totalInvestments)?.toLocaleString('pt-BR')} type="money" />
            </Col>
            <Col span={24}>
                <GraphComponent title={t('Total Investments Realized')}>
                    <div id="graphBar01" style={{height: 200}}/>
                </GraphComponent>
            </Col>
            <Col span={24}>
                <GraphComponent title={t('Total Realized By Phases')}>
                    <div id="graphBar02" style={{height: 200}}/>
                </GraphComponent>
            </Col>
            {/* <Col span={24}>
                <GraphComponent title="Total Realized By Phases">
                    <div id="graphBar03" style={{height: 200}}/>
                </GraphComponent>
            </Col> */}
        </Row>
    )

}

export default TotalInvestments;
