import GraphComponent from "shared/components/Graph";
import KPIComponent from "shared/components/KPI";
import { useTranslation } from 'react-i18next';
import MockData from '../Mock/MockData';
import GraphBar from "shared/components/GraphBar";
import { moneyMask } from "utils/masks/moneyMask";
import * as S from './styles';


const TotalInvestmentsCapexTest = () => {
    const { t } = useTranslation();
    const { TotalInvestmentsCapexData, TotalInvestmentsCapexMonthData } = MockData;

    return (
        <S.Row gutter={12}>
            <S.Col span={4}>
                <KPIComponent title={t("Total Budget")} value={'R$' + moneyMask(TotalInvestmentsCapexData.totalBudget)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Forecast")} value={'R$' + moneyMask(TotalInvestmentsCapexData.forecast)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Consumed")} value={'R$' + moneyMask(TotalInvestmentsCapexData.consumed)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Available")} value={'R$' + moneyMask(TotalInvestmentsCapexData.available)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Extra Budget")} value={'R$' + moneyMask(TotalInvestmentsCapexData.extraBudget)} type="money" />
            </S.Col>
            <S.Col span={4}>
                <KPIComponent title={t("Total Investments")} value={'R$' +  moneyMask(TotalInvestmentsCapexData.totalInvestiment)} type="money" />
            </S.Col>
            <S.Col span={24} style={{ height: 252, marginTop: 12}}>
                <GraphComponent title={t('Total Investments Realized')}>
                    <GraphBar graphType={'GRAPH01'} dataAxis={TotalInvestmentsCapexData.dataAxis} dataValue={TotalInvestmentsCapexData.dataValue} />
                </GraphComponent>
            </S.Col>
            <S.Col span={24}>
                <GraphComponent title={t('Total Realized By Phases')}>
                    <GraphBar graphType={'GRAPH01'} dataAxis={TotalInvestmentsCapexMonthData.dataAxis} dataValue={TotalInvestmentsCapexMonthData.dataValue} />
                </GraphComponent>
            </S.Col>
        </S.Row>
    )
}
export default TotalInvestmentsCapexTest;
