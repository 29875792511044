import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface GetInitiativeAllDetailsPayload {
  User: string;
  ProjectId: string;
}

interface FinancialDetail {
  type: string;
  total: number;
  totalCapex: number;
  totalOpex: number;
  runninCosts: number;
  committedBudget: number;
}

interface Risk {
  description: string;
  type: string;
  statusMitigation: string;
  lastUpdate: string;
}

interface Issue {
  description: string;
  type: string;
  statusMitigation: string;
  lastUpdate: string;
}

interface Impact {
  // Caso tenha mais informações para impactos, adicione aqui
}

interface CurveS {
  situation: string;
  startDate: string;
  endDate: string;
  valorStart: number;
  valorEnd: number;
}

interface NewCurveS {
  situation: string;
  phaseExecutionId: number;
  phase: string;
  valor: number;
}

interface OtherDetailsInfo {
  detFinancial: FinancialDetail[];
  risks: Risk[];
  issues: Issue[];
  impacts: Impact[];
  curveS: CurveS[];
  newCurveS: NewCurveS[];
}

export interface GetInitiativeAllDetailsResponse {
  companyId: number;
  projectGroup: string;
  initiativeType: string;
  mainInitiative: string;
  initiativeName: string;
  summary: string;
  initiativeId: string;
  sponsor: string;
  businessLeader: string;
  itPartnerLeader: string;
  itBusinessPatner: string;
  keyUser: string;
  businessArea: string;
  businessAreaId: number;
  businessDriver: string;
  businessBenefits: string;
  techonologyBenefits: string;
  plataformEnvolved: string;
  businessProcessEnvolved: string;
  programme: string;
  programmeId: number;
  businessDriverId: number;
  projectId: string;
  name: string;
  typeId: number;
  type: string;
  startDate: string;
  startPhaseBuild: string | null;
  percentCompleteBuild: number;
  startPhaseUat: string | null;
  percentCompleteUat: number;
  startPhaseCutover: string | null;
  percentCompleteCutover: number;
  goLiveDate: string;
  endDate: string;
  actualPhase: number;
  actualPhaseDescription: string;
  progressPlaned: number;
  progressRealized: number;
  issuesQuantity: number;
  statusTime: number;
  statusCoast: number;
  kanbanId: number;
  kanban: string;
  statusRisk: number;
  statusIssue: number;
  statusScope: number;
  providers: string;
  totalRequested: number;
  totalInvestiment: number;
  totalInvestimentCapex: number;
  totalInvestimentOpex: number;
  commitedBudget: number;
  percentCommitedPlanned: number;
  totalRunningCoast: number;
  lastRitualDate: string;
  lastRitualNumber: number;
  cpi: number;
  spi: number;
  extraBudget: number;
  forecast: number;
  consumed: number;
  available: number;
  financialProgress: number;
  operacionalProgress: number;
  operationalProgress: number;
  toWin: boolean;
  projectManager: string;
  actualStatus: number;
  actualStatusDescription: string;
  user: string | null;
  otherDetailsInfo: OtherDetailsInfo;
  timeline: any[]; 
  financial: {
    projectFinancial: any[]; 
    projectFinancialGroup: any[]; 
  };
  projectWorkDays: number;
  daysLeftToGoLive: number;
  lastStatusReport: string | null;
  statusGeral: number;
  digitalTransformation: number;
}

export function getInitiativeAllDetails(query: GetInitiativeAllDetailsPayload): Promise<AxiosResponse<GetInitiativeAllDetailsResponse>> {
  return btoApi
  .get(`/api/Initiative/GetAllDetail?User=${query.User}&ProjectId=${query.ProjectId}`, );
}