import { create } from 'zustand';
import { createProject, CreateProjectParams } from 'bto_now/api/project/createProject';

const initialProject: CreateProjectParams = {
  portfolioId: 0,
  programId: 0,
  projectId: 0,
  name: "",
  summary: "",
  projectTypeId: 1,
  managerId: 1,
  ownerId: 1,
  user: ""
};

interface CreateProjectStore {
  dataProject: CreateProjectParams;
  createProject: (body: CreateProjectParams) => void;
  clearDataProject: () => void;
}

export const useCreateProject = create<CreateProjectStore>((set) => ({
  dataProject: initialProject,
  createProject: async (body: CreateProjectParams): Promise<void> => {
    try {
      const response = await createProject(body);
      console.log(response)

      set({ dataProject: response.data.project });

      if (response.status === 200) {
        console.log('Project created successfully:', response.data.project);
      } else {
        console.error('Error creating project:', response.statusText);
      }
    } catch (error) {
      console.error('Failed to create project:', error);
    }
  },
  clearDataProject: () => set({ dataProject: initialProject })
}));
