import { create } from 'zustand';
import { getUser } from "services";
import { getStatusReport, StatusReportResponse } from "bto_now/api/statusReport/getStatusReport";
import { newStatusReport } from "bto_now/api/statusReport/newStatusReport";
import { validateStatusReport } from "bto_now/api/statusReport/validateStatusReport";

interface StatusReportState {
  statusReport: StatusReportResponse | null;
  getStatusReport: (ProjectId: number, status: number) => Promise<StatusReportResponse | void>;
  validateStatusReport: (ProjectId: number, description: string, benefits: string) => Promise<StatusReportResponse | void>;
}

export const useStatusReportStore = create<StatusReportState>((set) => ({
  statusReport: null, 
  getStatusReport: async (ProjectId: number, status: number): Promise<StatusReportResponse | void> => {
    try {
      if(status === 1){
        const response = await getStatusReport(ProjectId, status);
        return response.data;
      }
      
      if(status === 0) {
        const response = await newStatusReport({ User: getUser(), ProjectId: ProjectId, description: '' , benefits: ''});

        set({ statusReport: response.data });
        return response.data;
      }

    } catch (error) {
      console.error('Failed to fetch status report:', error)
      return; 
    }
  },
  validateStatusReport: async (ProjectId: number,description: string, benefits: string): Promise<StatusReportResponse | void> => {
    try {
      const response = await validateStatusReport({ User: getUser(), ProjectId: ProjectId, description, benefits});

      set({ statusReport: response.data });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch status report:', error)
      return; 
    }
  },
}));



