export const dataSapCost = {
  xAxis: ["2025", "2026", "2027", "2028", "2029", "2030", "2031"],
  dataAS: [288680, 180000, 660000, 440000, 330000, 520000, 600000],
  dataFS: [300000, 412380, 980000, 462420, 589560, 882410, 625610],
  SapS4: [-1060000, 7032014, 7463360, 7921491, 8407882, 8925104, 9475331],
  SapEC: [2688000, 1849874, 2028066, 2304816, 2532891, 2783800, 3060736],
  SapS4Services: [4500000, 151200, 169500, 190000, 212500, 238000, 267000],
  SapECServices: [1150000, 168000, 188160, 210730, 236100, 264351, 296000 ],
  SapS4LicOne: [-1060000, 6880814, 7293860, 7731491, 8195382, 8687104, 9208331],
  SapECLicOne: [1538000, 1681874, 1839906, 2094086, 2296791, 2519449, 2764736 ],
  SapS4LicTwo: [-1774000, 6124000, 6491610, 6881106, 7293973, 7731611, 8195508],
  SapECLicTwo: [1538000, 1681874, 1839906, 2094086, 2296791, 2519449, 2764736],
};

export const sapECCData = {
  "SAP ECC: Support": [688000, 729874, 773666, 820086, 869291, 921449, 976736],
  "SAP ECC: Extension Support": [0, 0, 0, 80000, 90000, 100000, 110000],
  "SAP ECC: Infra": [
    850000, 952000, 1066240, 1194000, 1337500, 1498000, 1678000,
  ],
  "SAP ECC: AMS Support": [
    150000, 168000, 188160, 210730, 236100, 264351, 296000,
  ],
  "SAP ECC: Project Evolutives": [1000000, 0, 0, 0, 0, 0, 0],
};

export const sapS4HData = {
  "SAP RISE: S4H": [
    4302000, 6124000, 6491610, 6881106, 7293973, 7731611, 8195508,
  ],
  "SAP RISE: Integration": [0, 0, 0, 0, 0, 0, 0],
  "SAP RISE: Signavio": [
    714000, 756814, 802250, 850385, 901409, 955493, 1012823,
  ],
  "SAP RISE: Datashpere": [0, 0, 0, 0, 0, 0, 0],
  "SAP S4H: Funding SAP": [-2076000, 0, 0, 0, 0, 0, 0],
  "SAP S4H: Funding Hyperscale": [-4000000, 0, 0, 0, 0, 0, 0],
  "SAP S4H: Funding Others": [-4500000, 0, 0, 0, 0, 0, 0],
  "SAP S4H: Core Services Brownfield": [4500000, 0, 0, 0, 0, 0, 0],
  "SAP S4H: Support AMS": [0, 151200, 169500, 190000, 212500, 238000, 267000],
  "SAP S4H: Projects": [0, 0, 0, 0, 0, 0, 0],
};
