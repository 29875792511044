import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { IoGrid } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as echarts from 'echarts';

import { ComponentHeader } from "shared/layouts";
import { Breadcrumb } from 'shared/components';
import GraphComponent from "shared/components/Graph";
import KPIComponent from "shared/components/KPI";

import { PALETE, getUser, space } from "services";
import { getDashOperacionalByProgramme } from 'bto_now/api/index'

const MainInitiativeOperational = () => {

    const { MAIN } = useParams()

    const [ blocks, setBlocks ] = useState([]);

    const routes = [ 
        {title: <IoGrid size={20} style={{margin: 0}} />},
        {title: <Link to="/dashboard/mng">Home</Link>},
        {title: <Link to="/dashboard">Portfolio Mng</Link>},
        {title: <Link to={`/dashboard/initiative/${MAIN}`}>Program Mng</Link>},
        {title: 'Program Operational Mng'}
    ]

    const onload = () => {
        getDashOperacionalByProgramme({ User: getUser(), ProgrammeId: Number(MAIN)})
            .then(({ data }) => {
                setBlocks(data)
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        onload()
    }, [])

    const loadGraphBar01 = () => {
        if (!blocks.programmeFinishedOngoing) return;
    
        var chartDom = document.getElementById('graphBar01');
        var myChart = echarts.init(chartDom);
        var option;
    
        option = {
            textStyle: { fontFamily: 'Poppins' },
            color: [PALETE.C03, PALETE.C01],
            dataZoom: [{ type: 'inside' }],
            legend: { center: 'center', itemWidth: 14, bottom: '-5px', width: '100%' },
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
            grid: { left: '5px', right: '5px', bottom: '30px', top: '20px', containLabel: true },
            xAxis: [{ type: 'category', data: blocks.programmeFinishedOngoing.map(item => item.programme), axisTick: { alignWithLabel: true } }],
            yAxis: [{ type: 'value' }],
            series: [
                { 
                    name: 'On-Going', 
                    barWidth: 50, 
                    type: 'bar', 
                    stack: 'total', 
                    data: blocks.programmeFinishedOngoing.map(item => item.onGoing), 
                    label: { show: true, position: 'inside' }, 
                    itemStyle: { borderRadius: [8, 8, 0, 0] } 
                },
                { 
                    name: 'Finished', 
                    barWidth: 50, 
                    type: 'bar', 
                    stack: 'total', 
                    data: blocks.programmeFinishedOngoing.map(item => item.finished), 
                    label: { show: true, position: 'inside' }, 
                    itemStyle: { borderRadius: [8, 8, 0, 0] } 
                },
            ]
        };
    
        option && myChart.setOption(option);
    };
    
    const loadGraphPizza01 = () => {
        if (!blocks.programmePercentual) return;
    
        var chartDom = document.getElementById('graphPizza01');
        var myChart = echarts.init(chartDom);
        var option;
    
        option = {
            textStyle: { fontFamily: 'Poppins' },
            color: [PALETE.C01, PALETE.C02, PALETE.C03],
            legend: { orient: 'horizontal', center: 'center', itemWidth: 14, width: '100%', bottom: 0 },
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
            grid: { left: '5px', right: '5px', bottom: '24px', top: '20px', containLabel: true },
            series: [
                {
                    name: 'Percentual',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: { show: false, position: 'center' },
                    emphasis: { 
                        label: { 
                            show: true, 
                            fontSize: 20, 
                            fontWeight: 'bold', 
                            formatter: (v) => `${v.value}%` 
                        } 
                    },
                    labelLine: { show: false },
                    itemStyle: { borderRadius: 8, borderColor: '#fff', borderWidth: 2 },
                    data: blocks.programmePercentual.map(item => ({
                        name: item.programme,
                        value: item.percentTotal
                    }))
                },
            ]
        };
    
        option && myChart.setOption(option);
    };
    
    const loadGraphBar02 = () => {
        if (!blocks.endDateByMonth) return;
    
        var chartDom = document.getElementById('graphBar02');
        var myChart = echarts.init(chartDom);
        var option;
    
        option = {
            textStyle: { fontFamily: 'Poppins' },
            color: [PALETE.C01],
            dataZoom: [{ type: 'inside' }],
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
            grid: { left: '5px', right: '5px', bottom: '10px', top: '26px', containLabel: true },
            xAxis: [{ type: 'category', data: blocks.endDateByMonth.map(item => item.monthYear), axisTick: { alignWithLabel: true } }],
            yAxis: [{ type: 'value' }],
            series: [
                { 
                    name: 'End Date', 
                    barWidth: 60, 
                    type: 'bar', 
                    data: blocks.endDateByMonth.map(item => item.quantity), 
                    label: { 
                        show: true, 
                        position: 'top', 
                        backgroundColor: '#4B4672', 
                        color: '#FFF', 
                        borderRadius: 3, 
                        padding: 3 
                    }, 
                    itemStyle: { borderRadius: [8, 8, 0, 0] } 
                },
            ]
        };
    
        option && myChart.setOption(option);
    };
    
    const loadGraphBar03 = () => {
        if (!blocks.processTimeBased) return;
    
        var chartDom = document.getElementById('graphBar03');
        var myChart = echarts.init(chartDom);
        var option;
    
        option = {
            color: [PALETE.C01],
            dataZoom: [{ type: 'inside' }],
            tooltip: { 
                trigger: 'axis', 
                axisPointer: { type: 'shadow' }, 
                valueFormatter: (v) => `${(v).toLocaleString('pt-BR')}%` 
            },
            grid: { left: '5px', right: '5px', bottom: '0px', top: '20px', containLabel: true },
            xAxis: [{ 
                type: 'category', 
                axisLabel: { rotate: 30, fontSize: 10, width: 60, overflow: 'truncate' }, 
                data: blocks.processTimeBased.map(item => item.project), 
                axisTick: { alignWithLabel: true } 
            }],
            yAxis: [{ 
                type: 'value', 
                axisLabel: { formatter: (v) => `${(v).toLocaleString('pt-BR')}%` } 
            }],
            series: [
                { 
                    name: 'Time Based', 
                    barWidth: 40, 
                    type: 'bar', 
                    data: blocks.processTimeBased.map(item => item.percentTotal),
                    label: { 
                        formatter: (v) => `${(v.value).toLocaleString('pt-BR')}%`, 
                        show: true, 
                        position: 'top', 
                        backgroundColor: '#4B4672', 
                        color: '#FFF', 
                        borderRadius: 3, 
                        padding: 3 
                    },
                    itemStyle: { borderRadius: [8, 8, 0, 0] },
                    markLine: { 
                        data: [
                            { 
                                yAxis: 100, 
                                symbol: 'none', 
                                lineStyle: { color: '#E77E9F' }, 
                                label: { 
                                    show: true, 
                                    fontSize: '9px', 
                                    position: 'insideStartTop', 
                                    formatter: () => '(+100%)' 
                                } 
                            },
                            { 
                                yAxis: 75, 
                                symbol: 'none', 
                                lineStyle: { color: '#E77E9F' }, 
                                label: { 
                                    show: true, 
                                    fontSize: '9px', 
                                    position: 'insideStartTop', 
                                    formatter: () => '(+75%)' 
                                } 
                            },
                        ] 
                    }
                },
            ]
        };
    
        option && myChart.setOption(option);
    };
    

    useEffect(() => {
        loadGraphBar01()
        loadGraphPizza01()
        loadGraphBar02()
        loadGraphBar03()
    }, [blocks])

    const { t } = useTranslation();

    const menuList = [
        { label: ('Home'), value: 'home' },

    ];


    return (
        <Row>
            <Breadcrumb separator=">" items={routes} />
            <Col span={24} className="route-page">
            <ComponentHeader 
                    title={t('Program Operational Mng')}
                    menuList={menuList}
                   
                />
                {/* <HeaderComponent>
                    <Row gutter={[4,4]} align={'middle'} style={{flexWrap: ''}}>
                        <Col span={24}>
                            <Row align={'middle'} justify={'space-between'}>
                                <Col> <Typography className="route-page-title">Program Operational Mng</Typography> </Col>
                            </Row>
                        </Col>
                    </Row>
                </HeaderComponent> */}
                <Row gutter={space} style={{marginTop: space[0]}} align={'top'}>
                    <Col span={4}>
                        <KPIComponent title={t('Total Projects')} value={blocks?.totalInitiatives?.total} type="project" size="lg" />
                    </Col>
                    <Col span={16}>
                        <Row gutter={space}>
                            <Col span={6}>
                                <KPIComponent title={t("Project To Start")} value={blocks?.totalInitiatives?.previousYear} type="project" />
                            </Col>
                            <Col span={6}>
                                <KPIComponent title={t("Project Plan & Kick-off")} value={blocks?.totalInitiatives?.projectPlanKickOff} type="project" />
                            </Col>
                            <Col span={6}>
                                <KPIComponent title={t("Project Build & Development")} value={blocks?.totalInitiatives?.buildDevelopment} type="project" />
                            </Col>
                            <Col span={6}>
                                <KPIComponent title={t("Project Cutover & Learning")} value={blocks?.totalInitiatives?.cutover} type="project" />
                            </Col>
                            <Col span={6}>
                                <KPIComponent title={t("Project In Progress")} value={blocks?.totalInitiatives?.onGoing} type="project" />
                            </Col>
                            <Col span={6}>
                                <KPIComponent title={t("Project Discovery & Explorer")} value={blocks?.totalInitiatives?.discoveryExplorer} type="project" />
                            </Col>
                            <Col span={6}>
                                <KPIComponent title={t("Project UAT")} value={blocks?.totalInitiatives?.uatLearning} type="project" />
                            </Col>
                            <Col span={6}>
                                <KPIComponent title={t("Project Go-Live & Hypercare")} value={blocks?.totalInitiatives?.hipercare} type="project" />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={4}>
                        <KPIComponent title={t("Total Projects (Finished Y 2024)")} value={blocks?.totalInitiatives?.finished} type="project" size="lg" />
                    </Col>
                    <Col span={18}>
                        <GraphComponent title={t("On-Going & Finished Projects By Program")}>
                            <div id="graphBar01" style={{height: 200}} />
                        </GraphComponent>
                    </Col>
                    <Col span={6}>
                        <GraphComponent title={t("On-Going & Finished Projects By Program")}>
                            <div id="graphPizza01" style={{height: 200}} />
                        </GraphComponent>
                    </Col>
                    <Col span={24}>
                        <GraphComponent title={t("End Date By Months")}>
                            <div id="graphBar02" style={{height: 200}} />
                        </GraphComponent>
                    </Col>
                    <Col span={24}>
                        <GraphComponent title={t("Process Time Based")}>
                            <div id="graphBar03" style={{height: 200}} />
                        </GraphComponent>
                    </Col>
                </Row>

            </Col>
        </Row>
    )

}

export default MainInitiativeOperational;