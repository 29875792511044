import React, { useCallback, useEffect } from 'react';
import * as echarts from 'echarts';
import { PALETE } from 'services';

interface GraphBarProps {
  eixoX: string[] | undefined;
  data01: number[] | undefined;
  data02: number[] | undefined;
  height?: number;
}

const GraphBarOperational: React.FC<GraphBarProps> = ({ eixoX, data01, data02, height = 500 }) => {

  const loadGraphBar = useCallback(() => {
    const chartDom = document.getElementById('graphBar01');
    if (!chartDom) return;
    
    const myChart = echarts.init(chartDom);

    const option = {
      textStyle: { fontFamily: 'Poppins' },
      color: [PALETE.C03, PALETE.C01],
      legend: {
        center: 'center',
        itemWidth: 14,
        bottom: '-5px',
        width: '100%'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' }
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '30px',
        top: '20px',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: eixoX,
          axisTick: { alignWithLabel: false },
          axisLabel: {
            fontSize: 10,
            interval: 0,
            rotate: 0,
            width: 100,
            overflow: 'truncate'
          },
          barCategoryGap: '10%',
        }
      ],
      yAxis: [
        { 
          type: 'value',
          axisTick: false,
          axisLine: false,
        }
      ],
      series: [
        {
          name: 'On-Going',
          type: 'bar',
          data: data01,
          label: {
            show: true,
            position: 'inside',
            formatter: (params: any) => {
              return params.value !== 0 ? params.value : '';
            },
          },
          itemStyle: { 
            borderRadius: [0, 0, 0, 0],
            color: PALETE.C04,
          },
        },
        {
          name: 'Finished',
          type: 'bar',
          data: data02,
          label: {
            show: true,
            position: 'inside',
            formatter: (params: any) => {
              return params.value !== 0 ? params.value : '';
            },
          },
          itemStyle: { 
            borderRadius: [0, 0, 0, 0],
            color: PALETE.C03,
          },
        }
      ],
      barGap: '10%',
    };

    myChart.setOption(option);

    const handleResize = () => {
      myChart.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [eixoX, data01, data02]);

  useEffect(() => {
    loadGraphBar();
  }, [eixoX, data01, data02, loadGraphBar]);

  return <div id="graphBar01" style={{ height: height, width: '100%' }} />;
};

export default GraphBarOperational;
