import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export interface GetProgrammePayload {
  User: string;
  ProgrammeId: number;
}

export interface ProgrammeGeneralInfo {
  programmeId: number
  programme: string
  businessArea: string
  businessDriver: string
  businessBenefits: string
  techonologyBenefits: string
  sponsor: string
  itPartnerLeader: string
  resourceEnvolved: string
  providerEnvolved: string
}

export interface Initia {
  companyId: number
  projectGroup: string
  initiativeType: string
  mainInitiative: string
  initiativeName: string
  summary: string
  initiativeId: string
  sponsor: string
  businessLeader: string
  itPartnerLeader: string
  itBusinessPatner: string
  keyUser: string
  businessArea: string
  businessAreaId: number
  businessDriver: string
  businessBenefits: string
  techonologyBenefits: string
  plataformEnvolved: string
  businessProcessEnvolved: string
  programme: string
  programmeId: number
  businessDriverId: number
  projectId: string
  name: string
  typeId: number
  type: string
  startDate: string
  startPhaseBuild: any
  percentCompleteBuild: number
  startPhaseUat: any
  percentCompleteUat: number
  startPhaseCutover: any
  percentCompleteCutover: number
  goLiveDate?: string
  endDate: string
  actualPhase: number
  actualPhaseDescription: string
  progressPlaned: number
  progressRealized: number
  issuesQuantity: number
  statusTime: number
  statusCoast: number
  kanbanId: number
  kanban: string
  statusRisk: number
  statusIssue: number
  statusScope: number
  providers: string
  totalRequested: number
  totalInvestiment: number
  totalInvestimentCapex: number
  totalInvestimentOpex: number
  commitedBudget: number
  percentCommitedPlanned: number
  totalRunningCoast: number
  lastRitualDate: string
  lastRitualNumber: number
  cpi: any
  spi: any
  extraBudget: number
  forecast: number
  consumed: number
  available: number
  financialProgress: number
  operacionalProgress: number
  operationalProgress: number
  toWin: boolean
  projectManager: string
  actualStatus: number
  actualStatusDescription: string
  user: any
  otherDetailsInfo: OtherDetailsInfo
  timeline: any[]
  financial: Financial
  projectWorkDays: number
  daysLeftToGoLive: number
  lastStatusReport?: string
  statusGeral: number
  digitalTransformation: number
}

export interface OtherDetailsInfo {
  detFinancial: any[]
  risks: any[]
  issues: any[]
  impacts: any[]
  curveS: any[]
  newCurveS: any[]
}

export interface Financial {
  projectFinancial: any[]
  projectFinancialGroup: any[]
}

export interface ProgrammeTotals {
  totalInvestiment: number
  totalInvestimentCapex: number
  totalInvestimentOpex: number
  totalProjects: number
  capexBudget: number
  opexBudget: number
}

export interface GetProgrammeResponse {
  programmeGeneralInfo: ProgrammeGeneralInfo
  initiatives: Initia[]
  programmeTotals: ProgrammeTotals
}

export function getProgramme(params: GetProgrammePayload): Promise<AxiosResponse<GetProgrammeResponse>> {
  if (!params ||!params.User)
    return Promise.reject({ error: 'Parametros não foram fornecidos' });

  return btoApi
   .get('/GetProgramme', { params });
}