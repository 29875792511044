import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { IoGrid } from 'react-icons/io5';

import {
  Breadcrumb,
  PageContainer,
  PageHeader
} from 'shared/components';
import { ProjectHeader } from 'shared/layouts';
import { useStatusReportStore } from 'shared/store-zustand/get-status-report/getStatusReport';
import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport';
import CardDetails from './Components/CardDetails';
import { StatusReportResponse } from 'bto_now/api/statusReport/getStatusReport';

const ProjectDetails = () => {
  const { t } = useTranslation();
  const { id, MAIN } = useParams();

  const [details, setDetails] = useState<StatusReportResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const { getStatusReport } = useStatusReportStore();
  const { typeStatusReport } = useTypeStatusReportStore();

  useEffect(() => {
    getStatusReport(Number(2), typeStatusReport)
      .then((data: StatusReportResponse | void) => {
        if (data) {
          setDetails(data);
        }
        setLoading(true);
      })
      .catch((error: string) => {
        console.error("Error fetching data:", error);
        setLoading(true);
      });
  }, [id, typeStatusReport, getStatusReport, setLoading]);


  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/">Portfolio Mng</Link> },
        { title: <Link to={`/dashboard/initiative/${MAIN}`}>Program Mng</Link> },
        { title: 'Project Details' }
      ]} />
      <PageContainer>
        <PageHeader
          title={t("Project Details")}
          menu={[
            { label: t('Homepage'), selected: true },
          ]}
        />
        <ProjectHeader style={{ marginBottom: '16px' }} projectId={'2'} />

        <CardDetails detailsCards={details} loading={loading} />

      </PageContainer>
    </>
  )
}

export default ProjectDetails;