import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface CreateProjectParams {
  portfolioId: number;
  programId: number;
  projectId?: number;  
  name: string;
  summary: string;
  projectTypeId: number;
  managerId: number;
  ownerId: number;
  user: string;
}

export interface CreateProjectResponse {
  project: CreateProjectParams;
}

export function createProject(params: CreateProjectParams): Promise<AxiosResponse<CreateProjectResponse>> {
  return btoApi
    .post("/api/Project/Create", params );
}