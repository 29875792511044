import { useEffect, useState } from "react";
import {Col, Form, Input, Progress, Row, Typography } from "antd";
import { LuClipboardList, LuClipboardSignature, LuMonitor, LuTarget, LuUsers, LuClock } from "react-icons/lu";
import { CSSProperties } from "styled-components";
import { useParams } from "react-router-dom";
import { Timeline } from 'primereact/timeline';
import { FaFlagCheckered } from "react-icons/fa6";
import { IoCheckmark, IoReloadOutline } from "react-icons/io5";
import { RiPresentationFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

import {
  Row as CustomRow,
  Typography as CustomTypography,
  Input as CustomInput,
} from "shared/components";

import Semaphore from "shared/components/SemaphoreVariant";
import CurveS from "shared/components/CurveS";
import GraphComponent from "shared/components/Graph";

import { dateMask } from "utils";
import { formatDateGetDatabase } from "utils/dates/formatDateGetDatabase";

import { getUser } from "services";
import { getAllPhases } from 'bto_now/api';
import { getInitiativeAllDetails } from "bto_now/api/initiatives/getInitiativeAllDetails";

import * as S from './styles';

const Homepage = () => {
  const { t } = useTranslation();
  const { INITIATIVE } = useParams();

  const inputContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 250,
    marginRight: 24,
    marginBottom: 12,
  };

  const [blocks, setBlocks] = useState<any>([]);
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<any>([]);

  const onload = async () => {
    try {
      const projectData = await getInitiativeAllDetails({User: getUser(), ProjectId: INITIATIVE!});
  
      if (projectData.data) {
        setBlocks(projectData.data); 
  
        const phasesResponse = await getAllPhases();
        const data = phasesResponse.data;
  
        const icons = [
          <LuClock size={30} />,
          <LuClipboardSignature size={30} />,
          <RiPresentationFill size={30} />,
          <LuMonitor size={30} />,
          <LuUsers size={30} />,
          <LuClipboardList size={30} />,
          <LuTarget size={30} />,
          <FaFlagCheckered size={30} />
        ];
  
        const events = data.map((d, i) => ({
          status: t(d.name),
          date: '',
          type: projectData.data.actualPhase >= i ? 'success' : 'waiting',  
          icon: icons[i]
        }));
  
        setEvents(events);  
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    onload();
  }, [loading]); 
  

  // const loadGraph01 = () => {

  //   var chartDom = document.getElementById('curve');
  //   var myChart = echarts.init(chartDom);
  //   var option;

  //   option = {
  //     textStyle: { fontFamily: 'Poppins' }, tooltip: { trigger: 'axis' },
  //     grid: { left: '20px', right: '10px', bottom: '0px', top: '20px', containLabel: true },
  //     xAxis: { type: 'category', boundaryGap: false },
  //     yAxis: { type: 'value', axisLabel: { fontSize: '.7em', formatter: (v: any) => `${v}%` } },
  //     series: blocks?.HOMEPAGE?.CURVES
  //   };

  //   option && myChart.setOption(option);
  // }

  // useEffect(() => {
  //   loadGraph01()
  // }, [blocks])


  const customizedMarker = (item: any) => {
    if (item.type === 'success') {
      return (
        <div className="item-timeline-card"><div className="item-timeline"><IoCheckmark size={30} color="#FFF" /></div></div>
      )
    } else {
      return (
        <div className="item-timeline-card"><div className="item-timeline waiting"><IoReloadOutline size={30} color="#FFF" /></div></div>
      )
    }
  };

  const customizedContent = (item: any) => {
    return (
      <div className="item-timeline-text">
        {item.icon}
        <Typography className="item-timeline-status">{t(item.status)}</Typography>
        <Typography className="item-timeline-date">{t(item.date)}</Typography>
      </div>
    );
  };

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <S.Section style={{ margin: 0 }}>
          <CustomRow style={{ justifyContent: 'space-between', marginBottom: 12 }}>
            <CustomTypography weight='bold' size='medium'>General Information</CustomTypography>
          </CustomRow>
          <CustomRow style={{ flexWrap: 'wrap' }}>
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Portfolio"
              name='portfolio'
              value={blocks?.portfolio}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Program Name"
              name='programme'
              value={blocks?.programme}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Business Drivers"
              name='businessDriver'
              value={blocks?.businessDriver}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Project Type"
              name='type'
              value={blocks?.type}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Project Dependencies"
              name='dependences'
              value={blocks?.dependences}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Project Goals"
              name='projectGoals'
              value={blocks?.projectGoals}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Actual Phase"
              name='actualPhaseDescription'
              value={blocks?.actualPhaseDescription}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Project Work Days"
              name='projectWorkDays'
              value={blocks?.projectWorkDays}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Days Left to Go-Live"
              name='daysLeftToGoLive'
              value={blocks?.daysLeftToGoLive}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Last Ritual Update"
              name='lastRitualDate_format'
              value={formatDateGetDatabase(blocks?.lastRitualDate)}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Last Status Report"
              name='lastStatusReport'
              value={formatDateGetDatabase(blocks?.lastStatusReport)}
              // value={blocks?.lastStatusReport}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Platform Involved"
              name='plataformEnvolved'
              value={blocks?.plataformEnvolved}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Business Process Involved"
              name='businessProcessEnvolved'
              value={blocks?.businessProcessEnvolved}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Business Area Involved"
              name='businessArea'
              value={blocks?.businessArea}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Business Benefits"
              name='businessBenefits'
              value={blocks?.businessBenefits}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Technology Benefits"
              name='techonologyBenefits'
              value={blocks?.techonologyBenefits}
              readOnly
            />
            <CustomInput
              containerStyle={inputContainerStyle}
              label="Digital Transformation"
              name='digitalTransformation'
              value={blocks?.digitalTransformation === 1 ? "YES" : "NO"}
              readOnly
            />
          </CustomRow>
        </S.Section >
      </Col>
      <Col span={12}>
        <GraphComponent title={t("Risks")}>
          <S.TableContainer className="canva-table">
            <S.Table>
              <S.TableHeader>
                <S.TableRow>
                  <S.TableHeaderCell>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Description")}
                    </Typography>
                  </S.TableHeaderCell>
                  <S.TableHeaderCell>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Type")}
                    </Typography>
                  </S.TableHeaderCell>
                  <S.TableHeaderCell>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Status Mitigation")}
                    </Typography>
                  </S.TableHeaderCell>
                  <S.TableHeaderCell noBorderRight>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Last Update")}
                    </Typography>
                  </S.TableHeaderCell>
                </S.TableRow>
              </S.TableHeader>
              <S.tbody>
                {[blocks?.otherDetailsInfo?.risks?.[0], blocks?.otherDetailsInfo?.risks?.[1], blocks?.otherDetailsInfo?.risks?.[2]].map((v, i) => (
                  <S.TableRow key={i} alt={i % 2 !== 0}>
                    <S.TableCell>
                      <Typography
                        style={{ 
                          maxWidth: '270px', 
                          whiteSpace: 'nowrap', 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis' 
                        }}
                        className="canva-data"
                        title={v?.description ? t(v.description) : '-'}
                      >
                        {v?.description ? t(v.description) : '-'}
                      </Typography>
                    </S.TableCell>
                    <S.TableCell>
                      <Typography className="canva-data">{v?.type || '-'}</Typography>
                    </S.TableCell>
                    <S.TableCell>
                      <Typography className="canva-data">{v?.statusMitigation || '-'}</Typography>
                    </S.TableCell>
                    <S.TableCell noBorderRight>
                      <Typography className="canva-data">{dateMask(v?.lastUpdate) || '-'}</Typography>
                    </S.TableCell>
                  </S.TableRow>
                ))}
              </S.tbody>
            </S.Table>
          </S.TableContainer>
        </GraphComponent>
      </Col>
      <Col span={12}>
        <GraphComponent title={t("Issues List")}>
          <S.TableContainer className="canva-table">
            <S.Table>
              <S.TableHeader>
                <S.TableRow>
                  <S.TableHeaderCell>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Description")}
                    </Typography>
                  </S.TableHeaderCell>
                  <S.TableHeaderCell>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Type")}
                    </Typography>
                  </S.TableHeaderCell>
                  <S.TableHeaderCell>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Status Mitigation")}
                    </Typography>
                  </S.TableHeaderCell>
                  <S.TableHeaderCell noBorderRight>
                    <Typography style={{ fontWeight: 'normal' }} className="canva-data">
                      {t("Last Update")}
                    </Typography>
                  </S.TableHeaderCell>
                </S.TableRow>
              </S.TableHeader>
              <S.tbody>
                {[blocks?.otherDetailsInfo?.issues?.[0], blocks?.otherDetailsInfo?.issues?.[1], blocks?.otherDetailsInfo?.issues?.[2]].map((v, i) => (
                  <S.TableRow key={i} alt={i % 2 !== 0}>
                    <S.TableCell>
                    <Typography
                        style={{ 
                          maxWidth: '270px', 
                          whiteSpace: 'nowrap', 
                          overflow: 'hidden', 
                          textOverflow: 'ellipsis' 
                        }}
                        className="canva-data"
                        title={v?.description ? t(v.description) : '-'}
                      >
                        {v?.description ? t(v.description) : '-'}
                      </Typography>
                    </S.TableCell>
                    <S.TableCell>
                      <Typography className="canva-data">{v?.type || '-'}</Typography>
                    </S.TableCell>
                    <S.TableCell>
                      <Typography className="canva-data">{v?.statusMitigation || '-'}</Typography>
                    </S.TableCell>
                    <S.TableCell noBorderRight>
                      <Typography className="canva-data">{dateMask(v?.lastUpdate) || '-'}</Typography>
                    </S.TableCell>
                  </S.TableRow>
                ))}
              </S.tbody>
            </S.Table>
          </S.TableContainer>
        </GraphComponent>
      </Col>
      {/* <Col span={8}>
        <GraphComponent title={t("Impacts List")}>
          <div style={{ overflowY: 'auto', borderRadius: '5px 5px 5px 5px', marginTop: 8 }} className="canva-table">
            <Row className="canva-row head">
              <Col flex={'80px'} className="canva-col-head" style={{ minWidth: 170 }}><Typography className="canva-data">{t("Description")}</Typography></Col>
              <Col flex={'70px'} className="canva-col-head"><Typography className="canva-data">{t("Type")}</Typography></Col>
              <Col flex={'130px'} className="canva-col-head"><Typography className="canva-data">{t("Status Mitigation")}</Typography></Col>
              <Col flex={'95px'} className="canva-col-head"><Typography className="canva-data">{t("Last Update")}</Typography></Col>
            </Row>
            {[blocks?.otherDetailsInfo?.impacts?.[0], blocks?.otherDetailsInfo?.impacts?.[1], blocks?.otherDetailsInfo?.impacts?.[2]].map((v, i) => (
              <Row className={`canva-row ${(i) % 2 !== 0 ? 'alt' : ''}`} key={i}>
                <Col flex={'80px'} className="canva-col-head" style={{ minWidth: 170 }}><Typography className="canva-data">{t(v?.description)}</Typography></Col>
                <Col flex={'70px'} className="canva-col-head"><Typography className="canva-data">{v?.type}</Typography></Col>
                <Col flex={'130px'} className="canva-col-head"><Typography className="canva-data">{v?.statusMitigation}</Typography></Col>
                <Col flex={'95px'} className="canva-col-head"><Typography className="canva-data">{v?.lastUpdate}</Typography></Col>
              </Row>
            ))}
          </div>
        </GraphComponent>
      </Col> */}
      <Col span={6}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <GraphComponent title={t("Project Status - Operational")}>
              <Row justify={'space-between'} align={'middle'} style={{ marginTop: 8 }}>
                <Col>{t("Timeline")}</Col>
                <Semaphore value={blocks.statusTime} />
              </Row>
              <Row justify={'space-between'} align={'middle'}> 
                <Col>{t("Risks")}</Col>
                <Semaphore value={blocks.statusRisk} />
              </Row>
              <Row justify={'space-between'} align={'middle'}>
                <Col>{t("Issues")}</Col>
                <Semaphore value={blocks.statusIssue} />
              </Row>
              <Row justify={'space-between'} align={'middle'}>
                <Col>SPI</Col>
                <Col>{blocks?.spi ? (blocks?.spi)?.toLocaleString('pt-BR') : '0,0'}</Col>
              </Row>
            </GraphComponent>
          </Col>
          <Col span={24}>
            <GraphComponent title={t("Project Status - Financial")}>
              <Row justify={'space-between'} align={'middle'} style={{ marginTop: 8 }}>
                <Col>{t("Investments")}</Col>
                <Semaphore value={blocks.statusCoast} />
              </Row>
              <Row justify={'space-between'} align={'middle'}>
                <Col>{t("CPI")}</Col>
                <Col>{blocks?.cpi ? (blocks?.cpi)?.toLocaleString('pt-BR') : '0,0'}</Col>
              </Row>
            </GraphComponent>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <CurveS 
          dataCurveS={blocks?.otherDetailsInfo?.newCurveS}
          loading={loading}
          height="235px" 
        />
        {/* <GraphComponent title={t("Curve S - Operational (Plan vs Real)")}>
          <div style={{ height: 192 }} id="curve" />
        </GraphComponent> */}
      </Col>
      <Col span={5}>
        <GraphComponent title={t("Investment Requested")}>
          <div style={{ marginTop: 10 }}></div>
          <Form.Item className="initiative-form-control">
            <Input size="small" style={{ textAlign: 'right' }} addonBefore={<div style={{ width: '50px' }}>TOTAL</div>} value={'$ ' + (blocks?.otherDetailsInfo?.detFinancial?.[1]?.total)?.toLocaleString('pt-BR')} disabled className="initiative-input financial" />
          </Form.Item>
          <Form.Item className="initiative-form-control">
            <Input size="small" style={{ textAlign: 'right' }} addonBefore={<div style={{ width: '50px' }}>CAPEX</div>} value={'$ ' + (blocks?.otherDetailsInfo?.detFinancial?.[1]?.totalCapex)?.toLocaleString('pt-BR')} disabled className="initiative-input financial" />
          </Form.Item>
          <Form.Item className="initiative-form-control">
            <Input size="small" style={{ textAlign: 'right' }} addonBefore={<div style={{ width: '50px' }}>OPEX</div>} value={'$ ' + (blocks?.otherDetailsInfo?.detFinancial?.[1]?.totalOpex)?.toLocaleString('pt-BR')} disabled className="initiative-input financial" />
          </Form.Item>
          <Form.Item className="initiative-form-control" label="Running Costs Plan">
            <Input size="small" style={{ textAlign: 'right' }} addonBefore="$" disabled className="initiative-input financial" value={(blocks?.otherDetailsInfo?.detFinancial?.[1]?.runninCosts)?.toLocaleString('pt-BR')} />
          </Form.Item>
          <div style={{ marginBottom: 14 }}></div>
        </GraphComponent>
      </Col>
      <Col span={5}>
        <GraphComponent title={t("Investment Realized")}>
          <div style={{ marginTop: 10 }}></div>
          <Form layout="vertical">
            <Form.Item className="initiative-form-control">
              <Input size="small" style={{ textAlign: 'right' }} addonBefore={<div style={{ width: '50px' }}>TOTAL</div>} value={'$ ' + (blocks?.otherDetailsInfo?.detFinancial?.[0]?.total)?.toLocaleString('pt-BR')} disabled className="initiative-input financial" />
            </Form.Item>
            <Form.Item className="initiative-form-control">
              <Input size="small" style={{ textAlign: 'right' }} addonBefore={<div style={{ width: '50px' }}>CAPEX</div>} value={'$ ' + (blocks?.otherDetailsInfo?.detFinancial?.[0]?.totalCapex)?.toLocaleString('pt-BR')} disabled className="initiative-input financial" />
            </Form.Item>
            <Form.Item className="initiative-form-control">
              <Input size="small" style={{ textAlign: 'right' }} addonBefore={<div style={{ width: '50px' }}>OPEX</div>} value={'$ ' + (blocks?.otherDetailsInfo?.detFinancial?.[0]?.totalOpex)?.toLocaleString('pt-BR')} disabled className="initiative-input financial" />
            </Form.Item>
            <Form.Item className="initiative-form-control" label="Committed Budget:" style={{ height: 66 }}>
              <Typography style={{ marginTop: 4 }} className="initiative-progress">{blocks?.otherDetailsInfo?.detFinancial?.[0]?.committedBudget + '%'}</Typography>
              <Progress style={{ marginTop: 4 }} size={['100%', 22]} percent={blocks?.otherDetailsInfo?.detFinancial?.[0]?.committedBudget} showInfo={false} />
            </Form.Item>
          </Form>
          <div style={{ marginBottom: 14 }}></div>
        </GraphComponent>
      </Col>

      <Col span={24}>
        <GraphComponent title={t("Project Tracking Monitor")}>
          <Timeline value={events} marker={customizedMarker} content={customizedContent} layout="horizontal" align="top" />
        </GraphComponent>
      </Col>

    </Row>

  )
}

export default Homepage;