import { AxiosResponse } from "axios";
import { btoApi } from "../client";
import { getUser } from "services";

interface FinancialDetail {
  type: string;
  total: number;
  totalCapex: number;
  totalOpex: number;
  runninCosts: number;
  committedBudget: number;
}

interface CurveS {
  situation: string;
  startDate: string;
  endDate: string;
  valorStart: number;
  valorEnd: number;
}

interface NewCurveS {
  phase: string;
  phaseExecutionId: number;
  situation: string;
  valor: number;
}

interface OtherDetailsInfo {
  detFinancial: FinancialDetail[];
  risks: any[];
  issues: any[];
  impacts: any[];
  curveS: CurveS[];
  newCurveS: NewCurveS[];
}

export interface ProjectDetails {
  companyId: number;
  projectGroup: string;
  initiativeType: string;
  mainInitiative: string;
  initiativeName: string;
  summary: string;
  initiativeId: string;
  sponsor: string;
  businessLeader: string;
  itPartnerLeader: string;
  itBusinessPatner: string;
  keyUser: string;
  businessArea: string;
  businessAreaId: number;
  businessDriver: string;
  businessBenefits: string;
  techonologyBenefits: string;
  plataformEnvolved: string;
  businessProcessEnvolved: string;
  programme: string;
  programmeId: number;
  businessDriverId: number;
  projectId: string;
  name: string;
  typeId: number;
  type: string;
  startDate: string;
  startPhaseBuild: string | null;
  percentCompleteBuild: number;
  startPhaseUat: string | null;
  percentCompleteUat: number;
  startPhaseCutover: string | null;
  percentCompleteCutover: number;
  goLiveDate: string;
  endDate: string;
  actualPhase: number;
  actualPhaseDescription: string;
  progressPlaned: number;
  progressRealized: number;
  issuesQuantity: number;
  statusTime: number;
  statusCoast: number;
  kanbanId: number;
  kanban: string;
  statusRisk: number;
  statusIssue: number;
  statusScope: number;
  providers: string;
  totalRequested: number;
  totalInvestiment: number;
  totalInvestimentCapex: number;
  totalInvestimentOpex: number;
  commitedBudget: number;
  percentCommitedPlanned: number;
  totalRunningCoast: number;
  lastRitualDate: string;
  cpi: number | null;
  spi: number | null;
  extraBudget: number;
  forecast: number;
  consumed: number;
  available: number;
  financialProgress: number;
  operacionalProgress: number;
  operationalProgress: number;
  toWin: boolean;
  projectManager: string;
  actualStatus: number;
  actualStatusDescription: string;
  user: string | null;
  otherDetailsInfo: OtherDetailsInfo;
  timeline: any[];
  financial: {
    projectFinancial: any[];
    projectFinancialGroup: any[];
  };
}

interface Permissions {
  newStatusReport: boolean;
  saveStatusReport: boolean;
  validateStatusReport: boolean;
}

export interface StatusReportResponse {
  detalhesDoProjeto: ProjectDetails;
  scopeManagementList: any[];
  projectRisks: any[];
  taskManagementList: any[];
  projectInformation: {
    scope: number;
    schedule: number;
    coast: number;
  };
  description: string;
  benefits: string;
  permissions: Permissions;
  publishDate: string;
}

export function getStatusReport(ProjectId: number, status: number): Promise<AxiosResponse<StatusReportResponse>> {
  return btoApi
    .get(`/api/DashBoard/GetStatusReport?User=${getUser()}&ProjectId=${ProjectId}&Status=${status}`);
}