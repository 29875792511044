import { useTranslation } from "react-i18next";

import CustoComponent from './CustoComponent';
import { StatusReportResponse } from "bto_now/api/statusReport/getStatusReport";

import * as S from './styles';

type InvestmentListProps = {
  itemInvestment: StatusReportResponse | null;
  loading: boolean;
}

const InvestmentList = ({ itemInvestment, loading }: InvestmentListProps) => {
  const { t } = useTranslation();

  const statsData = [
    {
      title: t('Investment'),
      value: itemInvestment?.detalhesDoProjeto.totalInvestiment,
      decimal: true,
      suffix: '',
      prefix: 'R$',
      valueClassName: 'text-warning',
      badgeBg: 'warning',
      badgeText: '-0.23%',
      loading: loading,
    },
    {
      title: t('Investment'),
      value: itemInvestment?.detalhesDoProjeto.totalInvestiment,
      decimal: true,
      suffix: '',
      prefix: 'R$',
      valueClassName: 'text-info',
      badgeBg: 'info',
      badgeText: '0.0%',
      loading: loading,
    },
  ];

  return (
    <>
      <S.Container>
        {statsData.map((stat, index) => (
          <CustoComponent dadosCusto={stat} key={index} />
        ))}
      </S.Container >
    </>
  )
}

export default InvestmentList;
