import { useTranslation } from 'react-i18next';
import { Table, Typography } from "shared/components";
import * as S from "./styles";
import { useEffect, useState } from 'react';

interface TableCostStructureProps {
  title: string;
  xAxis: string[];
  data: { [key: string]: number[] }; // Mapeamento de dados dinâmicos
}

const TableCostStructure = ({ title, xAxis, data }: TableCostStructureProps) => {
  const { t } = useTranslation();
  const [isMobileView, setIsMobileView] = useState<boolean>(window.innerWidth <= 850);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 850);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <S.Container>
      <Typography weight="bold" size="large" style={{ padding: '20px 0' }}>{title}</Typography>
      {!isMobileView ? (
        <Table>
          <Table.Thead>
            <Table.Tr header>
              <Table.Td style={{ padding: '4px 12px', width: '250px' }}>{t('Element')}</Table.Td>
              {xAxis.map((year) => (
                <Table.Td
                  style={{ padding: '4px 12px', textAlign: 'left', verticalAlign: 'middle' }} 
                  key={year}
                >
                  {year}
                </Table.Td>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {Object.entries(data).map(([key, values]) => (
              <Table.Tr key={key}>
                <Table.Td style={{ padding: '4px 12px', fontWeight: '700', color: '#344050', width: '250px' }}>
                  {t(key)} 
                </Table.Td>
                {values.map((value, index) => (
                  <Table.Td
                    key={index}
                    style={{ padding: '4px 12px', textAlign: 'left', verticalAlign: 'middle' }} 
                  >
                    R$ {value} 
                  </Table.Td>
                ))}
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <Table>
          {/* Mobile view logic pode ser ajustado aqui conforme necessário */}
        </Table>
      )}
    </S.Container>
  );
};

export default TableCostStructure;

