import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface TimelinePayload {
  user?: string | null;
}

export interface FormDataTimeline {
  phaseName?: string;
  planStartDate: string;
  planEndDate: string;
  phasePlanDays?: number;
  phaseOwner: string;
  phaseMilestone?: string;
}

export interface CreateTimelineParams {
  portfolioId: number;
  programId: number;
  projectId: number;  
  startDate: string;
  endDate: string;
  owner: number;
}

export function saveBudgetProject(query: TimelinePayload, params: CreateTimelineParams[]): Promise<AxiosResponse<CreateTimelineParams>> {
  return btoApi
    .post(`/api/ProjectTimeline/Save?User=${query.user}`, params );
}