import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface DashOperacionalByProgrammePayload {
  User: string;
  ProgrammeId: number;
}

export interface TotalInitiatives {
  total: number;
  onGoing: number;
  finished: number;
  previousYear: number;
  actualYear: number;
  projectPlanKickOff: number;
  discoveryExplorer: number;
  buildDevelopment: number;
  uatLearning: number;
  cutover: number;
  hipercare: number;
}

export interface ProgrammeFinishedOngoing {
  programme: string;
  finished: number;
  onGoing: number;
}

export interface ProgrammePercentual {
  programme: string;
  percentTotal: number;
}

export interface EndDateByMonth {
  monthYear: string;
  quantity: number;
}

export interface ProcessTimeBased {
  project: string;
  percentTotal: number;
}

export interface TotalPhases {
  total: number;
  estudo: number;
  planejamento: number;
  execucao: number;
  homologacao: number;
  implantacao: number;
  encerramento: number;
}

export interface DashOperacionalByProgrammeResponse {
  totalInitiatives: TotalInitiatives;
  programmeFinishedOngoing: ProgrammeFinishedOngoing[];
  programmePercentual: ProgrammePercentual[];
  endDateByMonth: EndDateByMonth[];
  processTimeBased: ProcessTimeBased[];
  totalPhases: TotalPhases;
}

export function getDashOperacionalByProgramme(query: DashOperacionalByProgrammePayload): Promise<AxiosResponse<DashOperacionalByProgrammeResponse>> {
  return btoApi
  .get(`/api/Portfolio/GetDashOperacionalByProgramme?User=${query.User}&ProgrammeId=${query.ProgrammeId}`, );
}