import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { IoGrid } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as echarts from 'echarts';

import { ComponentHeader } from "shared/layouts";
import { Breadcrumb } from 'shared/components';
import KPIComponent from "shared/components/KPI";
import GraphComponent from "shared/components/Graph";

import { PALETE, getUser, space } from "services";
import { getDashFinancialByProgramme } from "bto_now/api/portfolio/getDashFinancialByProgramme";


const MainInitiativeFinancial = () => {
    const { t } = useTranslation();
    const { MAIN } = useParams();

    const [ blocks, setBlocks ] = useState({});

    const onload = () => {
        getDashFinancialByProgramme({ User: getUser(), ProgrammeId: Number(MAIN) }).then(({ data }) => {
            console.log(data)

            setBlocks(data)
        }).catch(error => console.log(error))
    }

    useEffect(() => {
        onload()
    }, []);

    console.log(blocks)
    
    const loadGraphBar01 = () => {
        if (!blocks.totalInvestmentsRealized) return;

        var chartDom = document.getElementById('graphBar01');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            color: [ PALETE.C01, PALETE.C03 ],
            legend: {
                center: 'center',
                itemWidth: 14,
                bottom: '-5px',
                width: '100%'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'shadow' },
                valueFormatter: (v) => `$ ${(v).toLocaleString('pt-BR')}`
            },
            grid: {
                left: '5px',
                right: '5px',
                bottom: '30px',
                top: '30px',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: blocks?.totalInvestmentsRealized.map(item => item.programme),
                    axisTick: { alignWithLabel: true }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: { formatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}` }
                }
            ],
            series: [
                {
                    name: 'Project Budget',
                    barWidth: 40,
                    type: 'bar',
                    data: blocks?.totalInvestmentsRealized.map(item => item.projectBudjet),
                    label: {
                        formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`,
                        show: true,
                        position: 'top',
                        backgroundColor: '#4B4672',
                        color: '#FFF',
                        borderRadius: 3,
                        padding: 3
                    },
                    itemStyle: { borderRadius: [10,10,0,0] }
                },
                {
                    name: 'Budget Consumed',
                    barWidth: 40,
                    type: 'bar',
                    data: blocks?.totalInvestmentsRealized.map(item => item.budgetConsumed),
                    label: {
                        formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`,
                        show: true,
                        position: 'top',
                        backgroundColor: '#4B4672',
                        color: '#FFF',
                        borderRadius: 3,
                        padding: 3
                    },
                    itemStyle: { borderRadius: [10,10,0,0] }
                }
            ]
        };

        option && myChart.setOption(option);

    }

    const loadGraphBar02 = () => {
        if (!blocks.totalRealizedByMonths) return;

        var chartDom = document.getElementById('graphBar02');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            color: [ PALETE.C01 ],
            dataZoom: [ { type: 'inside' } ],
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'shadow' },
                valueFormatter: (v) => `$ ${(v).toLocaleString('pt-BR')}`
            },
            grid: {
                left: '5px',
                right: '5px',
                bottom: '10px',
                top: '20px',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: blocks?.totalRealizedByMonths.map(item => item.monthYear),
                    axisTick: { alignWithLabel: true }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: { formatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}` }
                }
            ],
            series: [
                {
                    name: 'Total Realized By Month',
                    barWidth: 40,
                    type: 'bar',
                    data: blocks?.totalRealizedByMonths.map(item => item.totalRealized),
                    label: {
                        formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`,
                        show: true,
                        position: 'top',
                        backgroundColor: '#4B4672',
                        color: '#FFF',
                        borderRadius: 3,
                        padding: 3
                    },
                    itemStyle: { borderRadius: [10,10,0,0] }
                }
            ]
        };

        option && myChart.setOption(option);

    }

    // const loadGraphBar03 = () => {

    //     var chartDom = document.getElementById('graphBar03');
    //     var myChart = echarts.init(chartDom);
    //     var option;

    //     option = {
    //         color: [ PALETE.C01 ], dataZoom: [ { type: 'inside' } ],
    //         tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }, valueFormatter: (v) => `$ ${(v).toLocaleString('pt-BR')}` },
    //         grid: { left: '5px', right: '5px', bottom: '10px', top: '20px', containLabel: true },
    //         xAxis: [ { type: 'category', data: blocks?.GRAPH03?.eixoX, axisTick: { alignWithLabel: true } } ],
    //         yAxis: [ { type: 'value', axisLabel: { formatter: (v) => `$ ${(v)?.toLocaleString('pt-BR')}` } } ],
    //         series: [
    //             { 
    //                 name: 'Total Realized By Phases', barWidth: 40, type: 'bar', 
    //                 data: blocks?.GRAPH03?.data,
    //                 label: { formatter: (v) => `$ ${(v.value)?.toLocaleString('pt-BR')}`, show: true, position: 'top', backgroundColor: '#4B4672', color: '#FFF', borderRadius: 3, padding: 3 }, itemStyle: { borderRadius: [10,10,0,0] }
    //             }
    //         ]
    //     };

    //     option && myChart.setOption(option);

    // }

    useEffect(() => {
        loadGraphBar01()
        loadGraphBar02()
        // loadGraphBar03()
    }, [blocks])

    const routes = [ 
        {title: <IoGrid size={20} style={{margin: 0}} />},
        {title: <Link to="/dashboard/mng">Home</Link>},
        {title: <Link to="/dashboard">Portfolio Mng</Link>},
        {title: <Link to={`/dashboard/initiative/${MAIN}`}>Program Mng</Link>},
        {title: 'Program Financial Mng'}
    ]   

    
    const menuList = [
        { label: ('Home'), value: 'home' },

    ];

    return (
        <Row>
            <Breadcrumb separator=">" items={routes} />
            <Col span={24} className="route-page">
            <ComponentHeader 
                    title={('Program Financial Mng')}
                    menuList={menuList}
                   
                />
                {/* <HeaderComponent>
                    <Row gutter={[4,4]} align={'middle'} style={{flexWrap: ''}}>
                        <Col span={24}>
                            <Row align={'middle'} justify={'space-between'}>
                                <Col> <Typography className="route-page-title">Program Financial Mng</Typography> </Col>
                            </Row>
                        </Col>
                    </Row>
                </HeaderComponent> */}
                <Row gutter={space} style={{marginTop: space[0]}} align={'top'}>

                    <Col span={4}>
                        <KPIComponent title={t("Total Budget")} type="money" value={(blocks?.totalInvestiments?.totalBudget)?.toLocaleString('pt-BR')} />
                    </Col>
                    <Col span={4}>
                        <KPIComponent title={t("Forecast")} type="money" value={(blocks?.totalInvestiments?.forecast)?.toLocaleString('pt-BR')} />
                    </Col>
                    <Col span={4}>
                        <KPIComponent title={t("Consumed")} type="money" value={(blocks?.totalInvestiments?.consumed)?.toLocaleString('pt-BR')} />
                    </Col>
                    <Col span={4}>
                        <KPIComponent title={t("Available")} type="money" value={(blocks?.totalInvestiments?.available)?.toLocaleString('pt-BR')} />
                    </Col>
                    <Col span={4}>
                        <KPIComponent title={t("Extra Budget")} type="money" value={(blocks?.totalInvestiments?.extraBudget)?.toLocaleString('pt-BR')} />
                    </Col>
                    <Col span={4}>
                        <KPIComponent title={t("Total Investments")} type="money" value={(blocks?.totalInvestiments?.totalInvestments)?.toLocaleString('pt-BR')} />
                    </Col>

                    <Col span={24}>
                        <GraphComponent title={t("Total Investments Realized")}>
                            <div id="graphBar01" style={{height: 200}}/>
                        </GraphComponent>
                    </Col>

                    <Col span={24}>
                        <GraphComponent title={t("Total Realized By Phases")}>
                            <div id="graphBar02" style={{height: 200}}/>
                        </GraphComponent>
                    </Col>

                    {/* <Col span={24}>
                        <GraphComponent title="Total Realized By Phases">
                            <div id="graphBar03" style={{height: 200}}/>
                        </GraphComponent>
                    </Col> */}

                </Row>
            </Col>
        </Row>
    )

}

export default MainInitiativeFinancial;