import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "antd";
import { IoGrid } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import { Link, Outlet } from "react-router-dom";
import { getToken, getUser, POST_API, space } from "services";
import General from "./Business/General";
import BusinessDetail from "./Business/BusinessDetail";

// import dados from './Business.json';
import { PageHeader } from 'shared/layouts';
import { Breadcrumb, Button } from 'shared/components';
import { getBusinessDriverGroup } from "bto_now/api";

interface BusinessDriverGroupResponse {
    businessDriverId: number;
    businessDriver: string;
}

function Business() {
    const { BUSINESS } = useParams<{ BUSINESS: string }>();
    const navigate = useNavigate();

    const [id, setId] = useState(0);
    const [businessDriver, setBusinessDriver] = useState<Array<BusinessDriverGroupResponse>>();

    const routes = [
        { title: <IoGrid size={20} style={{ margin: 0 }} /> },
        { title: <Link to="/dashboard/mng">Home</Link> },
        { title: <Link to="/dashboard">Portfolio Mng</Link> },
        { title: 'Portfolio Business Drivers' }
    ];

    useEffect(() => {
        getBusinessDriverGroup({ User: getUser() })
            .then(({ data }) => {
                setBusinessDriver(data)
            })
            .catch(error => console.log(error))
    }, [])

    // useEffect(() => {
    //     POST_API('/businessGroup.php', { token: getToken(), user: getUser() }).then(rs => rs.json()).then(res => {
    //         if (res.return) {
    //             setBusinessDriver(res.data);
    //         } else {
    //             Modal.warning({ title: 'Atenção!', content: res.msg });
    //         }
    //     }).catch(() => null)
    // }, [])

    useEffect(() => {
        setId(Number(BUSINESS));
    }, [BUSINESS]);

    function handleRedirectCanvaOrKanban() {
        if (id === 0) {
            navigate('kanban');
        } else {
            navigate('canva');
        }
    }

    return (
        <Row>
            <Breadcrumb separator=">" items={routes} />
            <Col span={24} className="route-page">
                <PageHeader
                    title="Portfolio Business Drivers"
                    topRight={<Button onClick={handleRedirectCanvaOrKanban}>{id === 0 ? 'Kanban' : 'Canva'}</Button>}                    navList={Array.isArray(businessDriver) ? [
                        { label: 'General', value: '/dashboard/business/0' },
                        ...businessDriver.map(v => ({ label: v.businessDriver, value: `/dashboard/business/${v.businessDriverId}` }))
                    ] : []}
                />
                <Row gutter={[12, 12]} style={{ marginTop: space[0] }}>
                    <Col span={24}>
                        {id === 0
                            ? <General />
                            : <BusinessDetail />
                        }
                        <Outlet />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Business;
