import { useTranslation } from "react-i18next";
import { dataSapCost, sapECCData, sapS4HData } from "../data";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import HeaderActionsDashboard from "shared/components/HeaderActionsDashboard";

import * as S from "./styles";
import TableCostStructure from "shared/components/Table/TableCostStructure";

const SapCostTable = () => {
  const { xAxis, dataAS, dataFS } = dataSapCost;
  const { t } = useTranslation();

  const contentDocument = useRef<HTMLDivElement>(null);

  const handleDownload = useReactToPrint({
    content: () => contentDocument.current,
  });
  
  return (
    <>
      <HeaderActionsDashboard title={t('Digital Business Case')} handleDownload={handleDownload} />

      <S.Container ref={contentDocument}>
        <TableCostStructure
          title="SAP S4H Rise View"
          xAxis={xAxis}
          data={sapS4HData}
        />

        <TableCostStructure
          title="SAP ECC View"
          xAxis={xAxis}
          data={sapECCData}
        />
      </S.Container>
    </>
  )
}

export default SapCostTable;