import { Card, Spinner } from 'react-bootstrap';
import { Typography } from "shared/components";
import Semaphore from "shared/components/SemaphoreVariant";
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { StatusReportResponse } from 'bto_now/api/statusReport/getStatusReport';

type ProjectInformationProps = {
  informations: StatusReportResponse | null;
  loading: boolean;
};

const ProjectInformation = ({ informations, loading }: ProjectInformationProps) => {
  const { t } = useTranslation();

  const dadosMock = [
    { id: 1, value: informations?.detalhesDoProjeto.statusScope, title: 'Scope' },
    { id: 2, value: informations?.detalhesDoProjeto.statusRisk, title: 'Risks' },
    { id: 3, value: informations?.detalhesDoProjeto.statusIssue, title: 'Issues' },
    { id: 4, value: informations?.detalhesDoProjeto.statusTime, title: 'Timeline' },
    { id: 5, value: informations?.detalhesDoProjeto.statusCoast, title: 'Financial' }
  ];

  if (!informations && loading) {
    return (
      <Card style={{ height: '400px' }}>
        <Card.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      {dadosMock.map((item) => (
        <S.ItemContainer key={item.id}>
          <Semaphore value={item.value || 0} />
          <Typography style={{ paddingLeft: '5px', fontSize: '13px', fontWeight: '500' }}>{t(item.title)}</Typography>
        </S.ItemContainer>
      ))}
      <S.ItemContainer>
        <Typography style={{ fontSize: '13px', fontWeight: '500' }}>{informations?.detalhesDoProjeto.spi?.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
        <Typography style={{ paddingLeft: '5px', fontSize: '13px', fontWeight: '500' }}>SPI</Typography>
      </S.ItemContainer>
      <S.ItemContainer>
        <Typography style={{ fontSize: '13px', fontWeight: '500' }}>{informations?.detalhesDoProjeto.cpi?.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</Typography>
        <Typography style={{ paddingLeft: '5px', fontSize: '13px', fontWeight: '500' }}>CPI</Typography>
      </S.ItemContainer>
    </>
  )
}

export default ProjectInformation;
