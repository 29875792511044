import {create} from 'zustand';
import { getListTimeline, GetTimelinePhasePayload, TimelinePhaseData } from 'bto_now/api/ProjectTimeline/getListTimeline';

interface GetTimelinePhaseData {
  timelinePhases: TimelinePhaseData[];
  getTimelinePhases: (queries: GetTimelinePhasePayload) => void;
}

export const useListTimeline = create<GetTimelinePhaseData>((set) => ({
    timelinePhases: [],
    getTimelinePhases: async (params: GetTimelinePhasePayload): Promise<void> => {
      try {
        const response = await getListTimeline(params);
        console.log(response);

        set(() => ({
          timelinePhases: response.data
        }));
      } catch (error) {
        console.error('Failed to fetch field list timeline:', error)
        throw error
      }
    }
}));