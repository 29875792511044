import Typography from "../Typography";
import { FaCaretUp } from "react-icons/fa";
import { MdOutlinePercent } from "react-icons/md";
import * as S from "./styles";

interface CostComponentProps {
  title: string;
  value: number | string;
  colorValue: string;
  percent?: number
  colorPercent: string
}

const CardCost = ({ title, value, colorValue, percent, colorPercent }: CostComponentProps) => {
  const formatCurrency = (value: number) => {
    return `R$ ${value.toLocaleString('pt-BR')}`;
  };

  console.log(percent)

  return (
    <S.Container>
      <Typography weight="bold" style={{ padding: '10px' }}>{title}</Typography>

      <S.Divider />

      <S.TitleContainer>
        <Typography weight="bold" size="extraLarge" style={{ color: colorValue }}>{typeof value === 'number' ? formatCurrency(value) : value}</Typography>
        
        <S.ContainerPercent>
          <Typography weight="bold" size="large" style={{ color: colorPercent }}>{percent?.toFixed(1)}</Typography>
          <S.ContainerIcons>
            <FaCaretUp style={{ margin: '-4px 0', color: colorPercent }} />
            <MdOutlinePercent style={{ margin: '0', color: colorPercent }} />
          </S.ContainerIcons>
        </S.ContainerPercent>

      </S.TitleContainer>
      
    </S.Container>
  )
}

export default CardCost;