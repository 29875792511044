import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface CreateProjectScopeRequest {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeTypeId: number;
  scopeStatusId: number;
  name: string;
  description: string;
  ownerId: number;
  user: string;
}

export function createProjectScope (params: CreateProjectScopeRequest): Promise<AxiosResponse<CreateProjectScopeRequest>> {
  return btoApi.post("api/ProjectScope/Create", params);
}