import React, { useEffect } from 'react';
import * as echarts from 'echarts';
import { moneyMask } from 'utils/masks/moneyMask';

interface GraphBarProps {
  dataAxis: string[];
  dataValue: number[];
  height?: number;
  graphType: 'GRAPH01' | 'GRAPH02'; // Define os tipos de gráfico
}

const GraphBar: React.FC<GraphBarProps> = ({ dataAxis, dataValue, height = 200, graphType }) => {
  useEffect(() => {
    const chartDom = document.getElementById('graphBar01');
    const myChart = echarts.init(chartDom);

    // Opções para GRAPH01
    const optionGraph01 = {
      textStyle: { fontFamily: 'Poppins, sans-serif' },
      grid: { left: '5px', right: '5px', bottom: '10px', top: '20px', containLabel: true },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        valueFormatter: (v: any) => `$ ${moneyMask(v)}`
      },
      xAxis: {
        data: dataAxis,
        axisLabel: { inside: true, color: '#fff' },
        axisTick: { show: false },
        axisLine: { show: false },
        z: 10
      },
      yAxis: {
        axisLine: { show: true },
        axisTick: { show: true },
        axisLabel: { color: '#999' }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 80,
        },
        {
          type: 'slider',
          backgroundColor: 'none',
          showDataShadow: false,
          borderColor: 'none',
          handleStyle: {
            color: '#5143f0',
          },
          moveHandleStyle: {
            color: '#988efe',
          },
        }
      ],
      series: [
        {
          type: 'bar',
          showBackground: true,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#8074ec' },
              { offset: 0.5, color: '#5342ec' },
              { offset: 1, color: '#5342ec' }
            ])
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#5342ec' },
                { offset: 0.5, color: '#5342ec' },
                { offset: 1, color: '#8074ec' }
              ])
            }
          },
          data: dataValue
        }
      ]
    };

    // Opções para GRAPH02
    const optionGraph02 = {
      
    };

    // Seleciona as opções de acordo com o tipo de gráfico
    const option = graphType === 'GRAPH01' ? optionGraph01 : optionGraph02;

    // Configura e renderiza o gráfico
    myChart.setOption(option);
    myChart.resize();
  }, [dataAxis, dataValue, graphType]);

  return (
    <div id="graphBar01" style={{ height }} />
  );
};

export default GraphBar;
