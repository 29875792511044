import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface DashFinancialByProgrammePayload {
  User: string;
  ProgrammeId: number;
}

export interface TotalInvestiments {
  totalBudget: number;
  forecast: number;
  consumed: number;
  available: number;
  extraBudget: number;
  totalInvestments: number;
}

export interface TotalInvestmentsRealized {
  programme: string;
  projectBudjet: number;
  budgetConsumed: number;
}

export interface TotalRealizedByMonth {
  monthYear: string;
  totalRealized: number;
}

export interface TotalRealizedByPhase {
  phase: string;
  totalRealized: number;
}

export interface DashFinancialByProgrammeResponse {
  totalInvestiments: TotalInvestiments;
  totalInvestmentsRealized: TotalInvestmentsRealized[];
  totalRealizedByMonths: TotalRealizedByMonth[];
  totalRealizedByPhase: TotalRealizedByPhase[];
}

export function getDashFinancialByProgramme(query: DashFinancialByProgrammePayload): Promise<AxiosResponse<DashFinancialByProgrammeResponse>> {
  return btoApi
    .get(`/api/Portfolio/GetDashFinancialByProgramme?User=${query.User}&ProgrammeId=${query.ProgrammeId}`, );
}