import React, { useState, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, Input } from "shared/components";
import { ScopeData } from "../../index";

import { getUser } from "services";
import { GetListScopeTypeResponse } from "bto_now/api/scopeType/getListScopeType";
import { GetListScopeStatusResponse } from "bto_now/api/scopeStatus/getListScopeStatus";
import { CreateProjectScopeRequest } from "bto_now/api/projectScope/createScope";
import { Owner } from "bto_now/api/project/getFieldList";

import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { useCreateScope } from "shared/store-zustand/scope-project/createScope";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

import { useOutletContext } from "react-router-dom";
import { NewCreateProjectData } from "../../..";

import * as S from './styles';

interface ModalCreateScopeProps {
  scopeTypeList: GetListScopeTypeResponse[];
  scopeStatusList: GetListScopeStatusResponse[];
  newScopeModal: boolean;
  setNewScopeModal: (state: boolean) => void;
  setUpdateTrigger: Dispatch<SetStateAction<boolean>>;
}

const ModalCreateScope = (props: ModalCreateScopeProps) => {
  const { newScopeModal, setNewScopeModal, setUpdateTrigger, scopeStatusList, scopeTypeList } = props;
  const { fieldListStatus } = useFieldListStatus();
  const { createScope } = useCreateScope();
  const { dataProject } = useCreateProject();
  const { t } = useTranslation();

  const { setProject } = useOutletContext<{
    setProject: React.Dispatch<React.SetStateAction<NewCreateProjectData>>,
  }>();

  const initialScopeData: ScopeData = {
    name: '',
    scopeType: '',
    owner: '',
    scopeStatus: '',
    description: '',
  };

  const inicialScopeErrors = {
    name: false,
    scopeType: false,
    owner: false,
    scopeStatus: false,
    description: false,
  }

  const [dataScope, setDataScope] = useState<ScopeData>(initialScopeData);
  const [errors, setErrors] = useState(inicialScopeErrors);

  const [resetSelect, setResetSelect] = useState(false);
  const [creatingLoading, setCreatingLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDataScope(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const handleSelectChange = (name: keyof ScopeData, value: string) => {
    setDataScope(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: false }));
  };

  const createScopeDatabase = async () => {
    try {
      const newItem: CreateProjectScopeRequest = {
        portfolioId: 1,
        projectId: dataProject.projectId!,
        programId: dataProject.programId,
        name: dataScope.name,
        scopeTypeId: Number(dataScope.scopeType),
        ownerId: Number(dataScope.owner),
        scopeStatusId: Number(dataScope.scopeStatus),
        user: getUser(),
        description: dataScope.description
      };

      await createScope(newItem);

      setProject(prevProject => ({
        ...prevProject,
        scopes: [
          ...(prevProject.scopes ?? []), 
          {
            name: dataScope.name,
            scopeType: dataScope.scopeType,
            owner: dataScope.owner,
            scopeStatus: dataScope.scopeStatus,
            description: dataScope.description,
          }
        ]
      }))
    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const handleSubmit = async () => {
    setCreatingLoading(true);
  
    const newErrors = {
      name: !dataScope.name,
      scopeType: !dataScope.scopeType,
      owner: !dataScope.owner,
      scopeStatus: !dataScope.scopeStatus,
      description: !dataScope.description
    };
  
    setErrors(newErrors);
  
    const hasError = Object.values(newErrors).some(error => error);
    if (!hasError) {
      await createScopeDatabase();
  
      setDataScope(initialScopeData); 

      setDataScope({
        name: '',
        scopeType: '',
        owner: '',
        scopeStatus: '',
        description: ''
      });
  
      setResetSelect(true);
      setNewScopeModal(false);
      setUpdateTrigger((prev) => !prev)
    }
  
    setCreatingLoading(false);
  };

  const handleCancelSubmit = () => {
    setDataScope(initialScopeData);
    setErrors(inicialScopeErrors)

    setNewScopeModal(false)
  }

  return (
    <Modal
      title={t("New Scope Mng")}
      open={newScopeModal}
      onCancel={() => setNewScopeModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={handleCancelSubmit} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit} 
            loading={creatingLoading}
          >
            {t("Create")}
          </Button>
        </div>
      }
    >
      <Input
        label={t('Scope Name') + ' *'}
        name='name'
        value={dataScope.name}
        onChange={handleInputChange}
        error={errors.name}
      />
      {errors.name && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={scopeTypeList.map(scope => ({ label: scope.scopeType, value: scope.scopeTypeId.toString() }))}
        label={t('Scope Type') + ' *'}
        name='scopeType'
        value={dataScope.scopeType}
        onChange={(e) => handleSelectChange('scopeType', e.target.value)}
        error={errors.scopeType}
        reset={resetSelect}
      />
      {errors.scopeType && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={fieldListStatus.ownerList.map((owner: Owner) => ({ label: owner.name, value: owner.ownerId.toString() }))}
        label={t('Owner')}
        name='owner'
        value={dataScope.owner}
        onChange={(e) => handleSelectChange('owner', e.target.value)}
      />
      {errors.owner && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Input
        label={t('Description') + ' *'}
        name='description'
        value={dataScope.description}
        onChange={handleInputChange}
        error={errors.description}
      />
      {errors.description && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
      <Select
        options={scopeStatusList.map(scope => ({ label: scope.scopeStatus, value: scope.scopeStatusId.toString() }))}
        label={t('Scope Status') + ' *'}
        name='scopeStatus'
        value={dataScope.scopeStatus}
        onChange={(e) => handleSelectChange('scopeStatus', e.target.value)}
        error={errors.scopeStatus}
        reset={resetSelect}
      />
      {errors.scopeStatus && <S.ErrorMessage>{t('This field is required')}</S.ErrorMessage>}
    </Modal>
  )
}

export default ModalCreateScope;