import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetDashFinancialPayload {
  User: string | null;
  OpcaoCapexOpex: 'Opcao' | 'Capex' | 'Opex';
}

export interface TotalInvestiments {
  totalBudget: number;
  forecast: number;
  consumed: number;
  available: number;
  extraBudget: number;
  totalInvestments: number;
}

export interface TotalInvestmentsRealized {
  programme: string;
  projectBudjet: number;
  budgetConsumed: number;
}

export interface TotalRealizedByMonth {
  monthYear: string;
  totalRealized: number;
}

export interface TotalRealizedByPhase {
  phase: string;
  totalRealized: number;
}

export interface GetDashFinancialResponse {
  totalInvestiments: TotalInvestiments;
  totalInvestmentsRealized: TotalInvestmentsRealized[];
  totalRealizedByMonths: TotalRealizedByMonth[];
  totalRealizedByPhase: TotalRealizedByPhase[];
}

export function getDashFinancial(query: GetDashFinancialPayload): Promise<AxiosResponse<GetDashFinancialResponse>> {
  return btoApi
    .get(`/api/Portfolio/GetDashFinancial?user=${query.User}&OpcaoCapexOpex=${query.OpcaoCapexOpex}`, );
}