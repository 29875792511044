import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useOutletContext } from "react-router-dom";
import { TFunction } from "i18next";
import { message } from "antd";
import { FaPlus, FaTrash } from "react-icons/fa";
import * as yup from "yup";

import { Select, Table, ComponentHeader, Button, Input, ButtonCalendarInput } from "shared/components";

import { getUser } from "services";
import { Owner } from "bto_now/api/project/getFieldList";
import { CreateTimelineParams, FormDataTimeline, TimelinePayload } from "bto_now/api/ProjectTimeline/saveTimeline";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { useCreateTimeline } from "shared/store-zustand/create-project/createTimeline";
import { useCreateProject } from "shared/store-zustand/create-project/createProject";

import { formatDateBeforeSendToBack } from "utils/dates/formatDateBeforeSendToBack";
import { areDatesSequential } from "utils/dates/areDatesSequential";
import { calculateDateDiffInDays } from 'utils/dates/calculateDateDiffInDays';

import { NewCreateProjectData } from "..";
import { isProjectComplete } from "../helpers/isProjectComplete";

import * as S from './styles';

export interface FormData {
  phases: {
    phaseName: string;
    planStartDate: string;
    planEndDate: string;
    phaseOwner: string;
    phaseMilestone: string;
  }[];
}

const schema = (t: TFunction<"translation", undefined>) => yup.object({
  phases: yup.array().of(
    yup.object({
      phaseName: yup.string()
        .required(t('This field is required.')),
      planStartDate: yup.string()
        .required(t('This field is required.')),
      planEndDate: yup.string()
        .required(t('This field is required.')),
      phaseOwner: yup.string()
        .required(t('This field is required.')),
      phaseMilestone: yup.string()
        .required(t('This field is required.')),  
    })
  ).required()
}).required();

const milestone = [
  { id: 1, label: 'Option 1' },
  { id: 2, label: 'Option 2' },
  { id: 3, label: 'Option 3' }
]

const TimelineProject = () => {
  const { dataProject } = useCreateProject();
  const { createTimeline, getTimelineData } = useCreateTimeline();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const { project, setProject, setAreAvaibleButtons } = useOutletContext<{
    project: NewCreateProjectData,
    setProject: React.Dispatch<React.SetStateAction<NewCreateProjectData>>,
    setAreAvaibleButtons: React.Dispatch<React.SetStateAction<boolean>>,
  }>();

  const navigate = useNavigate();

  const [qntyTimeline, setQntyTimeline] = useState(
    project.timeline.length === 0
      ? [{ timelineId: 0 }]
      : project.timeline.map((item: FormDataTimeline, index: number) => ({
          timelineId: index + 1,
        }))
  );

  const { register, handleSubmit, control, setValue, formState: { errors, isDirty, submitCount }, clearErrors, trigger, watch } = useForm<FormData>({
    resolver: yupResolver(schema(t)),
    defaultValues: {
      phases: qntyTimeline.map(() => ({
        phaseName: '',
        planStartDate: '',
        planEndDate: '',
        phasePlanDays: 0,
        phaseOwner: '',
        phaseMilestone: '',
      }))
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phases',
  });

  useEffect(() => {
    project.timeline.map((item, index) => {
      item.phaseName && setValue(`phases.${index}.phaseName`, item.phaseName)
    })
  }, []);

  useEffect(() => {
    setAreAvaibleButtons(isProjectComplete(project))
  }, [project])

  const CreateTimelineDatabase = async (data: FormData) => {
    try {
      const user = getUser();
      console.log(data)
      const query: TimelinePayload = { user: user ?? null };

      const newItems: CreateTimelineParams[] = data.phases.map((timeline) => ({
        portfolioId: 1,
        programId: dataProject.programId!,  
        projectId: dataProject.projectId!,  
        startDate: formatDateBeforeSendToBack(timeline.planStartDate),
        endDate: formatDateBeforeSendToBack(timeline.planEndDate),
        owner: Number(timeline.phaseOwner),
      }));

      const datesAreSequential = areDatesSequential(data.phases, 'planStartDate', 'planEndDate')
      
      if (!datesAreSequential) return message.warning(t('Dates must to be sequential'));
  
      const response = await createTimeline(query, newItems);

      if (response.status === 200) {
        getTimelineData(data.phases)
      }
  
    } catch (error) {
      console.error('Request failed:', error);
    }
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    CreateTimelineDatabase(data)
    navigate('/dashboard/mng/pmo/new-create-project/scope')
  };

  const handleInputStartDateChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;

    setProject(prevProject => ({
      ...prevProject,
      timeline: prevProject.timeline?.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }))

    setValue(`phases.${index}.planStartDate`, value);

    calculatePhaseDays(value, project.timeline[index].planEndDate, index)

    if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      clearErrors(`phases.${index}.planStartDate`);
    }
  };

  const calculatePhaseDays = (startDate: string, endDate: string, index: number) => {
    const dateStart = startDate;
    const dateEnd =  endDate;
    const days = calculateDateDiffInDays(dateStart, dateEnd);

    setProject(prevProject => ({
      ...prevProject,
      timeline: prevProject.timeline?.map((item, i) =>
        i === index ? { ...item, phasePlanDays: days } : item
      ),
    }))
  }

  const handleInputEndDateChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    
    setProject(prevProject => ({
      ...prevProject,
      timeline: prevProject.timeline?.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));

    setValue(`phases.${index}.planEndDate`, value);
    calculatePhaseDays(project.timeline[index].planStartDate, value, index)

    if (/^\d{2}\.\d{2}\.\d{4}$/.test(value)) {
      clearErrors(`phases.${index}.planEndDate`);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target
    
    setProject(prevProject => ({
      ...prevProject,
      timeline: prevProject.timeline?.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));

    setValue(`phases.${index}.phaseName`, value);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    const { name, value } = e.target;

    setProject(prevProject => ({
      ...prevProject,
      timeline: prevProject.timeline?.map((item, i) =>
        i === index ? { ...item, [name]: value } : item
      ),
    }));

    name === "phaseOwner" ? setValue(`phases.${index}.phaseOwner`, value) : setValue(`phases.${index}.phaseMilestone`, value)
  };

  const handleNewTableTimeline = async () => {
    const isValidForm = await trigger();
    const newPhase = {
      phaseName: '',
      planStartDate: '',
      planEndDate: '',
      phasePlanDays: 0,
      phaseOwner: '',
      phaseMilestone: '',
    };

    if (isValidForm) {
      setQntyTimeline((prev: any) => {
        const newTimeline = [...prev, { timelineId: prev.length + 1 }];
        append(newPhase);
        clearErrors(`phases`);

        return newTimeline;
      });

      setProject((prevProject) => ({
        ...prevProject,
        timeline: [...prevProject.timeline, newPhase],
      }));
    } else {
      // Show warning message instead of errors
      message.warning(t('Please fill out all required fields before adding a new phase entry.'));
    }
  };

  const handleDeleteTimeline = (index: number) => {
    if (index > 0) {
      remove(index);

      setProject(prevProject => ({
        ...prevProject,
        timeline: prevProject.timeline?.filter((item, i) =>
          i !== index
        ),
      }));

      return;
    }

    message.warning(t('The first form cannot be deleted.'));
  }

  console.log(watch(`phases.0.phaseName`))

  return (
    <>
      <ComponentHeader title={t('PMO: Create Project Timeline')} menu={[{ label: t('Timeline'), selected: true }]} />

      <S.ContainerList>
        <Table>
          <Table.Tbody>
            {fields.map((item, index) => (
              <Table.Tr style={{ borderBottom: '1px solid #ccc' }} key={item.id}>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <Input
                      {...register(`phases.${index}.phaseName`)}
                      label={t('Phase Name')}
                      name="phaseName"
                      value={project.timeline[index]?.phaseName}
                      index={index}
                      onChange={e => handleInputChange(e, index)}
                      error={submitCount > 0 && !!errors?.phases?.[index]?.phaseName}
                    />
                    {submitCount > 0 && errors?.phases?.[index]?.phaseName && <S.ErrorMessage>{errors.phases[index].phaseName.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <ButtonCalendarInput
                      {...register(`phases.${index}.planStartDate`)}
                      placeholder={t('Plan Start Date') + ' *'}
                      maxDate={project.timeline[index].planEndDate}
                      minDate={project.timeline[index - 1]?.planEndDate || '01.01.1900'}
                      name="planStartDate"
                      value={project.timeline[index]?.planStartDate}
                      onChange={e => handleInputStartDateChange(e, index)}
                      index={index}
                      error={submitCount > 0 && !!errors?.phases?.[index]?.planStartDate}
                    />
                    {submitCount > 0 && errors?.phases?.[index]?.planStartDate && <S.ErrorMessage>{errors.phases[index].planStartDate.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <ButtonCalendarInput
                      {...register(`phases.${index}.planEndDate`)}
                      placeholder={t('Plan End Date') + ' *'}
                      minDate={project.timeline[index].planStartDate}
                      maxDate={project.timeline[index + 1]?.planStartDate || '31.12.2100'}
                      value={project.timeline[index]?.planEndDate}
                      name="planEndDate"
                      onChange={e => handleInputEndDateChange(e, index)}
                      index={index}
                      error={submitCount > 0 &&!!errors?.phases?.[index]?.planEndDate}
                    />
                    {submitCount > 0 && errors?.phases?.[index]?.planEndDate && <S.ErrorMessage>{errors.phases[index].planEndDate.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px' }}>
                  <S.InputContainer>
                    <Input
                      label={t('Phase Plan Days') + '*'}
                      value={String(project.timeline[index]?.phasePlanDays)}
                      index={index}
                      maxWidth
                      readOnly
                    />
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }}>
                  <S.InputContainer>
                    <Select
                      {...register(`phases.${index}.phaseOwner`)}
                      options={fieldListStatus.ownerList.map((option: Owner) => ({ label: t(option.name), value: option.ownerId.toString()}))}
                      label={t('Owner') + '*'}
                      name="phaseOwner"
                      value={project.timeline[index]?.phaseOwner}
                      index={index}
                      onChange={e => handleSelectChange(e, index)}
                      error={submitCount > 0 && !!errors?.phases?.[index]?.phaseOwner}
                    />
                    {submitCount > 0 && errors?.phases?.[index]?.phaseOwner && <S.ErrorMessage>{errors.phases[index].phaseOwner.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }}>
                  <S.InputContainer>
                    <Select
                      {...register(`phases.${index}.phaseMilestone`)}
                      options={milestone.map((milestone) => ({ label: t(milestone.label), value: milestone.id.toString()}))}
                      label={t('Milestone') + '*'}
                      name="phaseMilestone"
                      value={project.timeline[index]?.phaseMilestone}
                      onChange={e => handleSelectChange(e, index)}
                      index={index}
                      error={submitCount > 0 && !!errors?.phases?.[index]?.phaseMilestone}
                    />
                    {submitCount > 0 && errors?.phases?.[index]?.phaseMilestone && <S.ErrorMessage>{errors.phases[index].phaseMilestone.message}</S.ErrorMessage>}
                  </S.InputContainer>
                </Table.Td>
                <Table.Td style={{ padding: '8px 12px', textAlign: 'left' }} onClick={() => handleDeleteTimeline(index)}>
                  <S.IconContainer>
                    <FaTrash />
                  </S.IconContainer>
                </Table.Td>
              </Table.Tr>
            ))}
            <Table.Tr onClick={handleNewTableTimeline} style={{ background: 'white', cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Table.Td style={{ width: '30px' }}><FaPlus size={18} /></Table.Td>
              <Table.Td colSpan={10}>{t("New Phase")}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </S.ContainerList>

      <S.ButtonContainer>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </S.ButtonContainer>
    </>
  );
};

export default TimelineProject;



