import React, { useEffect, useState } from 'react';
import * as S from './styles';
import { Row, Typography } from 'antd';
import { useTheme } from 'styled-components';
import { extractFinalSegmentFromUrl } from 'utils';

export interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> {
  /**
   * **A propriedade de lista com os parametros Label e Value tem as seguintes funções**
   * - **label** é exibido em tela
   * - **value** é um URL dizendo para onde deve ser redirecionando quando clicar na tab
   */
  list: Array<{
    label: string;
    value: string;
  }>;
  onClick(value: string): void;
  upperCase?: boolean;
  selected?: string;
}

function TabBar({
  list,
  onClick,
  upperCase,
  selected,
  ...props
}: Props) {
  const theme = useTheme();

  const [mySelected, setMySelected] = useState(0);

  useEffect(() => {
    console.log('@useeffects')
    const indexSelected = list.findIndex(v => extractFinalSegmentFromUrl(v.value) === extractFinalSegmentFromUrl(selected));
  
    if(indexSelected > -1 && indexSelected != mySelected) {
      setMySelected(indexSelected);
    }
  }, [selected]);

  return (
    <S.Container {...props}>
      {
        list.map(({ label, value }, index) => (
          <Row key={index}>
            <S.TabItem
              upperCase={upperCase}
              selected={mySelected === index} 
              onClick={() => { 
                onClick(value);
                setMySelected(index);
              }}
            >
              {label}
            </S.TabItem>
            {
              index < list.length - 1 && <Typography style={{ color: theme.colors.neutral.gray[200]}}>|</Typography>
            }
          </Row>
        ))
      }
    </S.Container>
  )
}

export default TabBar;