import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  @media print {
    margin: 20px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 999px) {
    flex-direction: column;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 999px) {
    flex-direction: row;
  }

  @media (max-width: 550px) {
    flex-direction: column;
  }
`;