import { useEffect, useRef, useState } from "react";
import { IoGrid } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Button, ComponentContainer, Row, PageContainer } from "shared/components";
import PageHeader from "shared/components/PageHeader";
import NewProjectHeader from "shared/layouts/NewProjectHeader";
import HeaderActionsDashboard from "../../../../../../shared/components/HeaderActionsDashboard";
import { useReactToPrint } from "react-to-print";



const SapCost = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const menus = {
    costStructure: {
      label: t('Cost Structure'),
      url: `/dashboard/mng/digital-business-case/sap-cost-structure`,
    },
    costKpis: {
      label: t('Cost KPIs'),
      url: `/dashboard/mng/digital-business-case/sap-cost-kpis`,
    },
  }

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
        { title: t('Business Case') }
      ]} />
      <PageContainer>
        <PageHeader
          title={t("BTO.4s4: Digital Business Case")}
          menu={[
            { label: menus.costStructure.label, selected: pathname === menus.costStructure.url, onClick: () => navigate(menus.costStructure.url) },
            { label: menus.costKpis.label, selected: pathname === menus.costKpis.url, onClick: () => navigate(menus.costKpis.url) },
          ]}
        />

        <ComponentContainer>
          <Outlet />
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default SapCost;