import { useEffect, useState } from "react";
import { IoGrid } from "react-icons/io5";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { AdditionalDetails } from "./GeneralProject";
import { Breadcrumb, Button, ComponentContainer, Row, PageContainer } from "shared/components";
import PageHeader from "shared/components/PageHeader";
import NewProjectHeader from "shared/layouts/NewProjectHeader";

import { useCreateProject } from "shared/store-zustand/create-project/createProject";
import { useStatusModalStore } from "shared/store-zustand/create-project/getStatusModal";
import { ScopeData } from "./ScopeProject";
import { FormDataFinancial } from "bto_now/api/projectFinancial/saveBudgetProject";
import { FormDataTimeline } from "bto_now/api/ProjectTimeline/saveTimeline";

export interface NewCreateProjectData {
  additionalDetails: AdditionalDetails;
  timeline: FormDataTimeline[];
  scopes: ScopeData[] | undefined;
  financial: FormDataFinancial[];
}

const PMO_NewCreateProject = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { setStatusModal } = useStatusModalStore();
  const { dataProject, clearDataProject } = useCreateProject();

  const [areAvaibleButtons, setAreAvaibleButtons] = useState(false);

  const [project, setProject] = useState<NewCreateProjectData>({
    additionalDetails: {
      startDate: "",
      endDate: "",
      sponsors: [],
      businessLeader: [],
      itLeader: [],
      projectKeyUser: [],
      businessArea: [],
      goLivePlanDate: "",
      projectProviders: [],
      businessBenefits: "",
      technologyBenefits: "",
      processInvolved: [],
      platformInvolved: [],
      businessDrivers: ""
    },
    scopes: undefined,
    timeline: [
      {
        phaseName: '',
        planStartDate: '',
        planEndDate: '',
        phasePlanDays: 0,
        phaseOwner: '',
        phaseMilestone: '',
      },
    ],
    financial: [
      {
        budgetOwner: '',
        budgetDate: '',
        totalBudget: 0,
        budgetCapex: 0,
        budgetOpex: 0,
        description: '',
      }
    ]   
  });

  const navigate = useNavigate();

  const menus = {
    general: {
      label: t('General'),
      url: `/dashboard/mng/pmo/new-create-project/general`,
    },
    timeline: {
      label: t('Timeline'),
      url: `/dashboard/mng/pmo/new-create-project/timeline`,
    },
    scope: {
      label: t('Scope'),
      url: `/dashboard/mng/pmo/new-create-project/scope`,
    },
    financial: {
      label: t('Financial'),
      url: `/dashboard/mng/pmo/new-create-project/financial`,
    },
  }

  useEffect(() => {
    return () => {
      setStatusModal(true);
      clearDataProject();
    };
  }, []);

  const showComponentContainer = dataProject.name.length === 0 ? true : false;

  return (
    <>
      <Breadcrumb separator=">" items={[
        { title: <IoGrid size={20} /> },
        { title: <Link to="/dashboard/mng">{t('Home')}</Link> },
        { title: t('New Create Project Management') }
      ]} />
      <PageContainer>
        <PageHeader
          title={t("Create Project")}
          menu={[
            { label: menus.general.label, selected: pathname === menus.general.url, onClick: () => navigate(menus.general.url) },
            { label: menus.timeline.label, selected: pathname === menus.timeline.url, onClick: () => navigate(menus.timeline.url) },
            { label: menus.scope.label, selected: pathname === menus.scope.url, onClick: () => navigate(menus.scope.url) },
            { label: menus.financial.label, selected: pathname === menus.financial.url, onClick: () => navigate(menus.financial.url) },
          ]}
          topRight={
            <Row style={{ gap: '8px' }}>
              <Button disabled={!areAvaibleButtons}>{t('Draft')}</Button>
              <Button disabled={!areAvaibleButtons}>{t('Request')}</Button>
              <Button disabled={!areAvaibleButtons}>{t('Cancel')}</Button>
            </Row>
          }
        />

        {!showComponentContainer && (
          <NewProjectHeader 
            style={{ marginBottom: '1rem'}} 
          />
        )}

        <ComponentContainer noShowComponent={showComponentContainer}>
          <Outlet context={{
            project,
            setProject,
            setAreAvaibleButtons,
          }} />
        </ComponentContainer>
      </PageContainer>
    </>
  )
}

export default PMO_NewCreateProject;