import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, Input, ButtonCalendarInput } from "shared/components";

import { yupResolver } from "@hookform/resolvers/yup";
import { FormSchemaIssues, schema } from "../..";
import { useForm } from "react-hook-form";
import { useGeneralDataSupport } from "shared/store-zustand/issues-project/getGeneralDataSupport";
import { IssueData, issueList } from "../../data";

import * as S from './styles';
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { Owner } from "bto_now/api/project/getFieldList";

interface ModalEditissuesProps {
  projectId: string | undefined;
  issueId: number | null;
  editIssueModal: boolean;
  updateTrigger: boolean;
  setEditIssueModal: (state: boolean) => void;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedIssue: React.Dispatch<React.SetStateAction<Set<number>>>;
  setSelectedIssueId: React.Dispatch<React.SetStateAction<number | null>>;
}

const ModalEditissues: React.FC<ModalEditissuesProps> = (props) => {
  const { projectId, editIssueModal, setEditIssueModal, setUpdateTrigger, issueId, updateTrigger, setSelectedIssue, setSelectedIssueId } = props;
  const { generalDataSupport } = useGeneralDataSupport();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const [issueDataEdit, setIssueDataEdit] = useState<FormSchemaIssues>();
  const [creatingLoading, setCreatingLoading] = useState(false);

  const { register, handleSubmit, reset, clearErrors, formState: { errors } } = useForm<FormSchemaIssues>({
    resolver: yupResolver(schema(t)),
  });

  useEffect(() => {
    if (issueId !== null) {
      const issueData = issueList.find((issue: IssueData) => issue.issueId === issueId);

      setIssueDataEdit(issueData)
    }
  }, [issueId, updateTrigger, editIssueModal]);

  // const updateissuesDatabase = async () => {
  //   try {
  //     const newItem: UpdateissuesManagementRequest = {
  //       projectId: Number(projectId),
  //       issuesId: dataissues.issuesId,
  //       name: dataissues.name,
  //       issuesTypeId: convertTypeInTypeId(issuesTypeList, dataissues.issuesType),
  //       owner: dataissues.owner,
  //       issuesStatusId: convertStatusInStatusId(issuesStatusList, dataissues.issuesStatus),
  //       user: getUser(),
  //       description: dataissues.description,
  //     };

  //     const response = await updateissuesManagement(newItem);

  //     if (response.status === 200) {
  //       console.log('issues updated successfully:', response.data);

  //       setUpdateTrigger(prev => !prev);
  //       setSelectedissuess(prevSelected => {
  //         const newSelected = new Set(prevSelected);
  //         newSelected.delete(dataissues.issuesId);
  //         return newSelected;
  //       });
  //       setSelectedissuesId(null);
  //     } else {
  //       console.error('Error updating issues:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Error updating issues:', error);
  //   }
  // };

  useEffect(() => {
    if (editIssueModal) {
      reset(); 
      clearErrors(); 
    }
  }, [editIssueModal, reset, clearErrors]); 

  const onSubmit = () => {
    setCreatingLoading(true);
    
    reset();
    clearErrors();
    setEditIssueModal(false);
    
    setCreatingLoading(false);
  };

  return (
    <Modal
      title={t(`Edit issues Mng: issues ${issueId}`)}
      open={editIssueModal}
      onCancel={() => setEditIssueModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => setEditIssueModal(false)}
          >
            {t("Cancel")}
          </Button>
          <Button
            style={{ marginLeft: 12 }}
            onClick={handleSubmit(onSubmit)}
            loading={creatingLoading}
          >
            {t("Save")}
          </Button>
        </div>
      }
    >
      <Input
        {...register('issueName')}
        label={t('issues Name') + ' *'}
        value={issueDataEdit?.issueName}
        error={!!errors?.issueName}
      />
      {errors.issueName && <S.ErrorMessage>{errors.issueName.message}</S.ErrorMessage>}
      <Input
        {...register('issueDescription')}
        label={t('Description') + ' *'}
        value={issueDataEdit?.issueDescription}
        error={!!errors?.issueDescription}
      />
      {errors.issueDescription && <S.ErrorMessage>{errors.issueDescription.message}</S.ErrorMessage>}
      <Select
        {...register('type')}
        options={generalDataSupport.type.map((type: string) => ({ label: type, value: type }))}
        label={t('issues Type') + ' *'}
        value={issueDataEdit?.type}
        // reset={resetSelect}
        error={!!errors?.type}
      />
      {errors.type && <S.ErrorMessage>{errors.type.message}</S.ErrorMessage>}
      <Select
        {...register('issueStatus')}
        options={generalDataSupport.status.map((status: string) => ({ label: status, value: status }))}
        label={t('Status ') + ' *'}
        value={issueDataEdit?.issueStatus}
        // reset={resetSelect}
        error={!!errors?.issueStatus}
      />
      {errors.issueStatus && <S.ErrorMessage>{errors.issueStatus.message}</S.ErrorMessage>}
      <Select
        {...register('urgency')}
        options={generalDataSupport.urgency.map((probability: string) => ({ label: probability, value: probability }))}
        label={t('Probability Scale') + ' *'}
        value={issueDataEdit?.urgency}
        // reset={resetSelect}
        error={!!errors?.urgency}
      />
      {errors.urgency && <S.ErrorMessage>{errors.urgency.message}</S.ErrorMessage>}
      <Select
        {...register('impact')}
        options={generalDataSupport.impact.map((impact: string) => ({ label: impact, value: impact }))}
        label={t('Impact ') + ' *'}
        value={issueDataEdit?.impact}
        // reset={resetSelect}
        error={!!errors?.impact}
      />
      {errors.impact && <S.ErrorMessage>{errors.impact.message}</S.ErrorMessage>}
      <Input
        {...register('historical')}
        placeholder={t('Historical') + ' *'}
        value={issueDataEdit?.historical}
        error={!!errors?.historical}
      />
      {errors.historical && <S.ErrorMessage>{errors.historical.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('openDate')}
        placeholder={t('Open Date') + ' *'}
        value={issueDataEdit?.openDate}
        error={!!errors?.openDate}
      />
      {errors.openDate && <S.ErrorMessage>{errors.openDate.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('forecastDate')}
        placeholder={t('Forecast Date') + ' *'}
        value={issueDataEdit?.forecastDate}
        error={!!errors?.forecastDate}
      />
      {errors.forecastDate && <S.ErrorMessage>{errors.forecastDate.message}</S.ErrorMessage>}
      <Select
        {...register('owner')}
        options={fieldListStatus.ownerList.map((owner: Owner) => ({ label: owner.name, value: owner.name }))}
        label={t('Owner') + ' *'}
        value={issueDataEdit?.owner}
        // reset={resetSelect}
        error={!!errors?.owner}
      />
      {errors.owner && <S.ErrorMessage>{errors.owner.message}</S.ErrorMessage>}
    </Modal>
  );
}

export default ModalEditissues;