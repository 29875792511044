import { AxiosResponse } from "axios";
import { btoApi } from "../client";

export interface FinancialPayload {
  user?: string | null;
}

export interface FormDataFinancial {
  budgetOwner: string;
  budgetDate: string;
  totalBudget: number;
  budgetCapex: number;
  budgetOpex: number;
  description: string;
}

export interface CreateFinancialParams {
  portfolioId: number;
  programId: number;
  projectId: number;
  financialTypeId: number;
  financialDate: string; 
  financialId: number;
  description: string;
  capex: number;
  opex: number;
  ownerId: number;
  monitorId: number;
}

export function saveBudgetProject(query: FinancialPayload, params: CreateFinancialParams[]): Promise<AxiosResponse<CreateFinancialParams>> {
  return btoApi
    .post(`/api/ProjectFinancial/SaveBudget?User=${query}`, params );
}