import React, { useState, Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from 'antd';

import { Button, Select, Input, ButtonCalendarInput } from "shared/components";

import * as S from './styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormSchemaRisks, schema } from "../..";
import { useGeneralDataSupport } from "shared/store-zustand/risks-project/getGeneralDataSupport";
import { useFieldListStatus } from "shared/store-zustand/create-project/getFieldList";
import { Owner } from "bto_now/api/project/getFieldList";

interface ModalCreateRiskProps {
  projectId?: string | undefined;
  newRiskModal: boolean;
  setNewRiskModal: (state: boolean) => void;
  setUpdateTrigger: Dispatch<SetStateAction<boolean>>;
}

const ModalCreateRisk = (props: ModalCreateRiskProps) => {
  const { projectId, newRiskModal, setNewRiskModal, setUpdateTrigger } = props;
  const { generalDataSupport, getGeneralDataSupport } = useGeneralDataSupport();
  const { fieldListStatus } = useFieldListStatus();
  const { t } = useTranslation();

  const [resetSelect, setResetSelect] = useState(false);
  const [creatingLoading, setCreatingLoading] = useState(false);

  const { register, handleSubmit, reset, clearErrors, formState: { errors } } = useForm<FormSchemaRisks>({
    resolver: yupResolver(schema(t)),
  });

  // const createRiskDatabase = async () => {
  //   try {
  //     const newItem: CreateRiskManagementRequest = {
  //       projectId: Number(projectId),
  //       name: dataRisk.name,
  //       RiskTypeId: convertTypeInTypeId(RiskTypeList, dataRisk.RiskType),
  //       owner: dataRisk.owner,
  //       RiskStatusId: convertStatusInStatusId(RiskStatusList, dataRisk.RiskStatus),
  //       user: getUser(),
  //       description: dataRisk.description,
  //     };
  
  //     console.log(newItem);

  //     const response = await createRiskManagement(newItem);

  //     if (response.status === 200) {
  //       console.log('Risk created successfully:', response.data);
  //       setUpdateTrigger(prev => !prev);
  //     } else {
  //       console.error('Error creating Risk:', response.statusText);
  //     }
  //   } catch (error) {
  //     console.error('Request failed:', error);
  //   }
  // };

  useEffect(() => {
    if (newRiskModal) {
      reset(); 
      clearErrors(); 
      setResetSelect(true); 
    }
  }, [newRiskModal, reset, clearErrors]); 

  const onSubmit = async (data: FormSchemaRisks) => {
    setCreatingLoading(true);

    console.log(data);

    reset();
    clearErrors();
    setResetSelect(true);
  
    setNewRiskModal(false);
  
    setCreatingLoading(false);
  };

  const handleCancelSubmit = () => {
    reset(); 
    clearErrors(); 
    setResetSelect(true); 
    setNewRiskModal(false);
  }

  return (
    <Modal
      title={t("New Risk Mng")}
      open={newRiskModal}
      onCancel={() => setNewRiskModal(false)}
      centered
      destroyOnClose
      footer={
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            onClick={handleCancelSubmit} 
          >
            {t("Cancel")}
          </Button>
          <Button 
            style={{ marginLeft: 12 }} 
            onClick={handleSubmit(onSubmit)} 
            loading={creatingLoading}
          >
            {t("Create")}
          </Button>
        </div>
      }
    >
      <Input
        {...register('riskName')}
        label={t('Risk Name') + ' *'}
        error={!!errors?.riskName}
      />
      {errors.riskName && <S.ErrorMessage>{errors.riskName.message}</S.ErrorMessage>}
      <Input
        {...register('riskDescription')}
        label={t('Description') + ' *'}
        error={!!errors?.riskDescription}
      />
      {errors.riskDescription && <S.ErrorMessage>{errors.riskDescription.message}</S.ErrorMessage>}
      <Select
        {...register('type')}
        options={generalDataSupport.type.map((type: string) => ({ label: type, value: type }))}
        label={t('Risk Type') + ' *'}
        reset={resetSelect}
        error={!!errors?.type}
      />
      {errors.type && <S.ErrorMessage>{errors.type.message}</S.ErrorMessage>}
      <Select
        {...register('statusMitigation')}
        options={generalDataSupport.statusMitigation.map((status: string) => ({ label: status, value: status }))}
        label={t('Status Mitigation') + ' *'}
        reset={resetSelect}
        error={!!errors?.statusMitigation}
      />
      {errors.statusMitigation && <S.ErrorMessage>{errors.statusMitigation.message}</S.ErrorMessage>}
      <Select
        {...register('probabilityScale')}
        options={generalDataSupport.probabilityScale.map((probability: string) => ({ label: probability, value: probability }))}
        label={t('Probability Scale') + ' *'}
        reset={resetSelect}
        error={!!errors?.probabilityScale}
      />
      {errors.probabilityScale && <S.ErrorMessage>{errors.probabilityScale.message}</S.ErrorMessage>}
      <Select
        {...register('impactScale')}
        options={generalDataSupport.impactScale.map((impact: string) => ({ label: impact, value: impact }))}
        label={t('Impact Scale') + ' *'}
        reset={resetSelect}
        error={!!errors?.impactScale}
      />
      {errors.forecastDate && <S.ErrorMessage>{errors.forecastDate.message}</S.ErrorMessage>}
      <Input
        {...register('historical')}
        label={t('Historical') + ' *'}
        error={!!errors?.historical}
      />
      {errors.historical && <S.ErrorMessage>{errors.historical.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('openDate')}
        placeholder={t('Open Date') + ' *'}
        error={!!errors?.openDate}
      />
      {errors.openDate && <S.ErrorMessage>{errors.openDate.message}</S.ErrorMessage>}
      <ButtonCalendarInput
        {...register('forecastDate')}
        placeholder={t('Forecast Date') + ' *'}
        error={!!errors?.forecastDate}
      />
      {errors.forecastDate && <S.ErrorMessage>{errors.forecastDate.message}</S.ErrorMessage>}
      <Select
        {...register('owner')}
        options={fieldListStatus.ownerList.map((owner: Owner) => ({ label: owner.name, value: owner.ownerId.toString() }))}
        label={t('Owner') + ' *'}
        reset={resetSelect}
        error={!!errors?.owner}
      />
      {errors.owner && <S.ErrorMessage>{errors.owner.message}</S.ErrorMessage>}
    </Modal>
  )
}

export default ModalCreateRisk;