import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface GetListScopPayload {
  user?: string | null;
  portfolioId: number;
  programId: number;
  projectId: number;
}

export interface GetListScopeResponse {
  portfolioId: number;
  programId: number;
  projectId: number;
  scopeId: number;
  scopeTypeId: number;
  scopeType: string | null;
  scopeStatusId: number;
  scopeStatus: string | null;
  name: string;
  description: string;
  ownerId: number;
  owner: string | null;
}

export function getListProjectScope (params: GetListScopPayload): Promise<AxiosResponse<GetListScopeResponse[]>> {
  return btoApi.get(`api/ProjectScope/GetList?User=${params.user}&PortfolioId=${params.portfolioId}&ProgramId=${params.programId}&ProjectId=${params.projectId}`);
}