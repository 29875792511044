import { AxiosResponse } from 'axios';
import { create } from 'zustand';
import { calculateDateDiffInDays } from 'utils/dates/calculateDateDiffInDays';
import { CreateTimelineParams, FormDataTimeline, saveBudgetProject, TimelinePayload } from 'bto_now/api/ProjectTimeline/saveTimeline';

interface CreateTimelineStore {
  timelineData: FormDataTimeline[];
  createTimeline: (query: TimelinePayload, params: CreateTimelineParams[]) => Promise<AxiosResponse>;
  getTimelineData: (data: FormDataTimeline[]) => void;
}

export const useCreateTimeline = create<CreateTimelineStore>((set) => ({
  timelineData: [],
  createTimeline: async (query: TimelinePayload, params: CreateTimelineParams[]): Promise<AxiosResponse> => {
    try {
      const response = await saveBudgetProject(query, params);

      if (response.status === 200) {
        console.log('Timeline created successfully:', response);
        return response;
      } else {
        console.error('Error creating timeline:', response.statusText);
        return response;
      }
    } catch (error) {
      console.error('Failed to create timeline:', error);
      throw error; 
    }
  },
  getTimelineData: (data: FormDataTimeline[]) => {
    const updatedData = data.map(item => {
      const phasePlanDays = calculateDateDiffInDays(item.planStartDate, item.planEndDate);
      return { ...item, phasePlanDays };
    });
  
    set({ timelineData: updatedData });
  }
}));

