import { AxiosResponse } from 'axios';
import { btoApi } from 'bto_now/api/client';
import { createProjectScope, CreateProjectScopeRequest } from 'bto_now/api/projectScope/createScope';
import { create } from 'zustand';

interface CreateScopeStore {
  scopeStatus: boolean;
  createScope: (params: CreateProjectScopeRequest) => Promise<AxiosResponse | undefined>;
}

export const useCreateScope = create<CreateScopeStore>((set) => ({
  scopeStatus: false,
  createScope: async (params: CreateProjectScopeRequest): Promise<AxiosResponse | undefined> => {
    try {
      const response = await createProjectScope(params)

      if (response.status === 200) {
        console.log('Scope created successfully:', response);
        set({ scopeStatus: true });

        return response;
      } else {
        console.error('Error creating scope:', response.statusText);
        return response;
      }
    } catch (error) {
      console.error('Failed to create scope:', error);
    }
  },
}));